@import "meteojob-theme/lib/skins/_default/sass/misc/functions";
@import "_variables";
@import "meteojob-theme/lib/skins/_default/sass/misc/mixins";

@import 'sections/**/*';

.d-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  .left {
    order: 1;
  }
  .right {
    order: 2;
  }
  .center {
    align-items: center;
    justify-content: center;
  }
}

/** Page d'accueil */

#mj-logos {
  .top-level-container {
    overflow: hidden;
  }

  .line {
    position: relative;
  }
  .line-logos {
    width: 999999px;
    height: 100px;
    margin: 0 0 10px 0;

    & > div,
    .view,
    .view .views-row {
      float: left;
    }
  }

  .logo {
    display: inline-block;
    height: 80px;
    line-height: 80px;
    margin: 0 0 5px 25px;
    text-decoration: none;

    img {
      width: 120px;
      height: 71px;
    }
  }
}

#mj-main-categories {
  .line-categories {
    position: relative;

    #mj-categories {
      min-height: 250px;
      margin-right: 310px;
    }

    #mj-pave {
      position: absolute;
      width: 300px;
      height: 250px;
      top: 0;
      right: 0;
    }

    @media (max-width: $screen-sm) {
      #mj-categories {
        margin-right: 0;
        margin-bottom: 20px;
      }
      #mj-pave {
        position: initial;
        top: initial;
        right: initial;
        margin: 0 auto;
      }
    }
  }
}

#mj-main-news {
  .title {
    border-bottom: 1px solid $text-color;
    padding-bottom: 20px;
  }

  #mj-last-news {
    padding: 15px 20px;

    .news-title {
      font-size: 1.2em;
    }
  }

  .row {
    margin-bottom: 10px;
  }

  .more-link {
    display: block;
    float: right;
    font-weight: bold;
  }
}

/* Region content bottom homepage and landing pages */
#hp_social {
  h3, a {color:$text-color-light}
  a:hover{color:$gray-bright}
  .social-left {text-align:left;}
  .social-right {text-align:right;}
  .fa, .ft {font-size: 1.4em;}
}

/* COMMON */
.float-none {float:none; margin:0 auto;}
.float-lt {float:left; text-align:right;}
.float-rt {float:right; text-align:left;}
.no-padd-lt {padding-left:0}
.no-padd-rt {padding-right:0}
.padd-4-px {padding: 0 4px}
.btn-white {color: $brand-primary; background-color: #fff; border-color: #f2f2f2; font-weight: 600; }
.btn-white:hover {color: $brand-primary; background-color: #f2f2f2; border-color: #f8f8f8;}
.btn-white:focus {color: $brand-primary; background-color: #f8f8f8; border-color: #f2f2f2;}
.btn-white:active {color: $brand-primary; background-color: #f8f8f8; border-color: #f2f2f2;}
.btn-tertiary {color: #000000; background-color: $brand-tertiary; border-color: $brand-tertiary; font-weight: 600; }
.btn-tertiary:hover {color: #000000; background-color: $brand-tertiary; border-color: $brand-tertiary;}
.btn-tertiary:focus {color: #000000; background-color: $brand-tertiary; border-color: $brand-tertiary;}
.btn-tertiary:active {color: #000000; background-color: $brand-tertiary; border-color: $brand-tertiary;}
.btn-quaternary {color: #ffffff; background-color: $brand-quaternary; border-color: #429867; font-weight: 600; }
.btn-quaternary:hover {color: #ffffff; background-color: #429867; border-color: #307950;}
.btn-quaternary:focus {color: #ffffff; background-color: #307950; border-color: #429867;}
.btn-quaternary:active {color: #ffffff; background-color: #307950; border-color: #429867;}
a.brand-quaternary {
  color: $brand-quaternary;
  &:hover {
    color: #429867;
  }
  &:focus,
  &:active {
    color: #307950
  }
}

@media (max-width: 767px) {
  #hp_social {
    .social-left {text-align:center;}
    .social-right {text-align:center;}
  }
  .d-flex {
    display: block;
    margin: 0!important;
  }
}

.media-logo {
  display:inline-block;
  margin:10px;
}
.media-logo img {
  height: auto;
  margin: 15px;
  max-width: 200px;
  max-height: 55px;
}
#hp_medias {
  .media-logo img {
    filter: grayscale(100%);
    &:hover {
      filter: none;
    }
  }
}
