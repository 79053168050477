@import "_variables";

/** OLD MISC */
.tar {
text-align: right;
}

/* Override cms-styles.css */
.region-sidebar-first .block .item-list ul li {
margin-left:0;
}
.region-sidebar-first .views-summary li a,
.view-empty .views-summary li a {
font-weight:bold;
}
.view-empty .views-summary li {
margin-left:0;
}
.region-sidebar-first .form-text,
.region-sidebar-first .form-autocomplete  {
width:165px;
}
.region-sidebar-first .form-select  {
width:170px;
}
.region-sidebar-first .views-exposed-form label {
color:$brand-primary;
}
.section-emploi .region-sidebar-first .block,
.section-guide-emploi-recruteur .region-sidebar-first .block {
background-color:#fdf4ef;
}

/** Titles */
.page-guide-emploi-recruteur {
  h1#page-title {
    display: none;
  }
}

/* Font size */
.node-guide-emploi-recruteur,
.node-carriere,
.node-actualites,
.view-actualites-emplois #contentContainer .views-field-body,
.view-actualites-archives #contentContainer .views-field-body  {
font-size: $font-size-base !important;
text-align:justify;
}
.node-actualites,
.view-actualites-emplois #contentContainer .views-field-body,
.view-actualites-archives #contentContainer .views-field-body  {
text-align:left;
}

/* Views */
.views-exposed-form .views-exposed-widget .form-submit {
margin-top:0;
}
/*
.region-sidebar-first .views-exposed-form .views-submit-button {
width:180px;
}
*/
/* Views pager */
.view .pager {
margin-top:20px;
}
.view .pager li {
margin-left:0;
}
/* Views calendar */
.view .date-nav-wrapper {
color:#fff;
background:#00bcf2;
}
.view .date-nav-wrapper .date-nav {
margin:0;
padding:0;
height:auto;
min-height:0;
}
.view .date-nav-wrapper .date-heading h3,
.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
line-height:30px;
}
.view .date-nav-wrapper .date-heading,
.view .date-nav-wrapper .date-heading a {
color:#fff;
}
.view .date-nav-wrapper .pager,
.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
margin:0;
padding:0;
-moz-border-radius:0;
border-radius:0;
background:none;
}
.region-sidebar-first .block .item-list ul.pager li {
margin-left:0;
}
.calendar-calendar .month-view table,
.calendar-calendar table.mini td,
.calendar-calendar table.mini td.empty {
border:1px solid #ccc;
}
.calendar-calendar thead th.days {
color:#242424;
background:#EEF7FC;
border:0 none;
}
.calendar-calendar .mini td.sat,
.calendar-calendar .mini td.sun {
color:#FFBD29;
background:#FFEBDA;
}
.calendar-calendar .mini td div {
font-size:prem(11px);
line-height:24px;
text-align:center;
}
.calendar-calendar .mini td div.calendar-empty {
display:none;
}
.calendar-calendar .mini td.has-events a {
font-weight:bold;
}
.calendar-calendar tr td.today,
.calendar-calendar tr.odd td.today,
.calendar-calendar tr.even td.today {
color:#fff;
background:$brand-primary;
}

/* View "Taxonomie links" */
.view-taxonomie-links .view-header,
.view-taxonomie-links .view-content,
.view-taxonomie-links .views-row {
float:left;
}
.view-taxonomie-links .view-header,
.view-taxonomie-links.view-display-id-show_offers_block .views-row  {
font-weight:bold;
}
.view-taxonomie-links.view-display-id-actu_url_externe .views-row {
font-size:prem(11px);
}
.view-taxonomie-links .views-row {
margin-right:5px;
}
.view-taxonomie-links .views-row .field-content::after {
content:",";
}
.view-taxonomie-links .views-row:last-child .field-content::after {
content:".";
}
.view-taxonomie-links .views-row a {
color:$brand-primary;
}
/**/
#boutoninscription  {
text-align:right;
margin:0px 0px 10px 0px;
background-color:#fcfcfc;
border-top:1px solid #eaeaea;
border-bottom:1px solid #eaeaea;
padding:10px;
}
#boutoninscription h3 {
float:left;
margin:0 0 10px 0;
font-size: 1.2em;
}
#boutoninscription p  {
float:left;
margin-bottom:3px;
clear:both;
}
.section-carriere #boutoninscription {
margin:0 10px;
}
.section-carriere .bottom-content-bloc {
padding: 10px 5px 5px 5px;
border: 1px solid #ccc;
}
.section-carriere .bottom-content-bloc .linkColWrapper {
width: 25%;
}
.articleContent  h2 {
font-weight:bold;
color: $brand-primary;
}
.indexentretien {
color:$brand-primary;
background:url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px;
padding-left:15px;
}
.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title,
.node-type-actualites #contentContainer .title  {
border-bottom: 3px solid #cbcbcb;
}
.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title  {
margin:10px !important;
}
.node-type-guide-emploi-recruteur .title {
color:#de5e1e;
}
#container #contenu h2  {
padding-right:180px;
float:left;
}
#container #contenu {
margin-top:10px;
}
.articleContent .itemInfoHome {
text-align:justify;
padding-bottom:20px;
}
.articleContent .customMargin {
margin:10px 0px 10px 10px;
}
.articleContent .homePicture_customMargin {
margin:10px 0px 10px 25px
}
.horizontalPictureLeft  {
float:left;
clear:both;
margin:0 10px 10px 0;
}
.horizontalPictureRight {
float:right;
clear:both;
margin:0 0 10px 10px;
}
.picWithLegend {
float:left;
}
.picWithLegend blockquote {
margin-left:2px;
clear:both;
}
.articleContent .small,
.articleContent .medium,
.articleContent .big,
.articleContent .bigger {
color:$brand-primary;
text-decoration:none;
}
.articleContent .small {
font-size:prem(12px);
}
.articleContent .big {
font-size:prem(18px);
}
.articleContent .bigger {
font-size:prem(22px);
}
.articleContent .small:hover,
.articleContent .medium:hover,
.articleContent .big:hover,
.articleContent .bigger:hover {
text-decoration:underline;
}
.node-type-guide-emploi-recruteur .articleContent .arrow  {
font-size: inherit !important;
color: inherit !important
}
.node-actualies .arrow {
font-size: inherit !important;
color: inherit !important;
}
.node-actualites #contentContainer .content h3,
.articleContent .arrow  {
margin-top:15px;
font-size: inherit !important;
color: inherit !important;
}
.articleContent .contentcabine .arrow {
float:left;
clear:both;
}
.articleContent .arrow a  {
color:inherit !important;
}
.articleContent .arrow a:hover  {
text-decoration:none;
}
.articleContent .arrowlienpremier {
color:inherit !important;
font-size:inherit !important;
margin-top:20px;
}
.articleContent .arrowautre {
margin-top:25px;
color:inherit !important;
font-size:inherit !important;
}
.articleContent .p1 {
font-weight:bold;
}
.articleContent .p3 {
margin-top:20px;
}
.articleContent .p2 {
font-weight:bold;
}
.articleContent .paragraphe3  {
margin-top:5px;
}
.articleContent h2  {
color:rgb(57, 132, 219);
}
.view-actualites-emplois #contentContainer .views-field-path,
.view-actualites-archives #contentContainer .views-field-path {
margin-top:15px;
}
.front .view-actualites-emplois #contentContainer .views-field-path {
margin-top:0;
}
.node-actualites .content ul {
list-style:initial;
}
.articleContent .contentcabine p  {
float:left;
}
.articleContent p a {
text-decoration:none;
color:$brand-primary;
}
.articleContent p a:hover {
text-decoration:underline;
}
.articleContent .horizontalPictureLefts {
margin-top:0px;
float:left;
clear:both;
padding-right:10px;
}
.articleContent .verticalPictureLeft  {
margin-top:0px;
float:left;
clear:both;
padding-right:10px;
}
.articleContent ul  {
list-style-type:none;
width:600px;
clear:both;
float:left;
}
.articleContent ul li {
display:inline;
}
.articleContent ul li a {
color:inherit !important;
}
.articleContent #arrown {
list-style-type:none;
float:none;
clear:none;
}
.articleContent #arrown li  {
margin-top:5px;
padding-top:5px;
display:block;
}
.articleContent .paragraphe2  {
float:left;
margin-top:10px;
}
.articleContent .horizontalPicture  {
float:left;
clear:both;
margin-bottom:10px;
}
.articleContent .paragraphe1  {
float:left;
width:400px !important;
margin:5px 0px 0px 10px;
}
.articleContent .paragrapheautre  {
float:left;
width:400px !important;
margin:20px 0px 10px 0px;
}
.articleContent .paragrapheautres {
margin:20px 0px 10px 0px;
}
#bullet   {
float:left;
margin-top:5px;
display:inline-block;
margin-left:160px;
}
.articleContent .minorBtn {
}
.articleContent  .asavoir2  {
clear:both;
}
.articleContent h3  {
color:rgb(41, 124, 206);
}
.articleContent ol  {
margin:10px 0px 10px 20px;
}
.articleContent .middleImage  {
margin:10px 0px 10px 100px;
}
.articleContent .PortageSalarial,
.articleContent .portageSalarial {
list-style:disc;
}
.articleContent .PortageSalarial  {
margin-top:5px;
}
.articleContent .portageSalarial li {
display: list-item;
margin: 5px 0 5px 20px;
list-style-type: disc;
}

.page-evenements .views-field-body {
  float: left;
  width: 430px;
  text-align: justify;
  margin-bottom: 10px;
}
.views-field-field-name .field-content {
  margin-bottom: 10px;
}
.node-type-actualites .region-content .block {
  margin-bottom: .8em;
}
.page-emploi-actualites #contentContainer .views-field-path {
  text-align: right;
}
.page-emploi-actualites #contentContainer .views-field-created {
  color: #666;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-created {
  color: $text-color;
}
.page-emploi-actualites #contentContainer .views-field-body .field-content,
.page-emploi-actualites #contentContainer .views-field-body p {
  text-align: justify !important;
}
.page-emploi-actualites #contentContainer .views-field-body p,
.page-emploi-actualites #contentContainer .views-field-body em,
.page-emploi-actualites #contentContainer .views-field-body span,
.page-emploi-actualites #contentContainer .views-field-body strong {
  padding: 0 !important;
  color: inherit !important;
  font-size: inherit !important;
  font-weight: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  text-decoration: none !important;
}

.page-evenements .views-field-title,
.page-emploi-actualites #contentContainer .view-content .views-field-title a,
.page-emploi-actualites #contentContainer .view-content .views-field-title-1 a,
.views-field-field-name .field-content,
.views-field-field-name .field-content a {
  font-size: prem(18px);
  text-decoration: none;
  color: inherit !important;
  text-transform: uppercase;
  font-weight: bold;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-title a {
  color: $brand-primary;
  text-transform: none;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-title a:hover {
  text-decoration: underline;
}
.page-emploi-actualites-browse #contentContainer h2 a,
.page-emploi-actualites-archives #contentContainer h2 a,
.page-emploi-actualites-term #contentContainer h2 a {
  font-weight: normal;
}
.node-type-actualites .region-sidebar-second .view-gp-jobs .views-row {
  padding-bottom: 5px;
}
.list-icon li > *:first-child, .links-list li > *:first-child {
  padding-right: 5px;
}
li .fa-chevron-right {
  margin-right: 5px;
  color: $brand-primary;
}
.list-icon li > *, .links-list li > * {
  display: table-cell;
}
.view-display-id-page h3 {
  font-size: prem(22px);
}
.view-display-id-page h2 {
  margin-bottom: 10px;
}
.view-display-id-page h2 a {
  font-size: prem(18px);
  text-decoration: none;
  color: $brand-primary;
}
.view-evenements .view-empty {
  margin-top: 10px;
  font-size: $font-size-base;
}
.view-evenements .views-row {
  margin: 15px 0px;
}
.view-evenements .views-field-field-date-period .field-content:first-letter {
  text-transform: uppercase;
}
.view-evenements .view-empty .view-display-id-bloc_a_venir h2 {
  margin-top: 20px;
  font-size: $font-size-base;
}
/* View "Carriere" */
.recruteurHeader {
  width: 605px;
  margin-left: 5px;
  border: 1px solid #eaeaea;
}
.two-sidebars.page-emploi-actualites #content,
.two-sidebars.page-guide-emploi-recruteur #content,
.two-sidebars.page-carriere #content {
  width: 605px;
  margin-right: 10px;
  margin-left: 10px;
}
.two-sidebars.page-evenements #contentContainer {
  padding: 10px;
}
.two-sidebars.page-evenements #contentContainer,
.two-sidebars.page-evenements .region-content-bottom,
.two-sidebars.page-evenements .region-extra-content,
.two-sidebars.page-emploi-actualites #contentContainer,
.two-sidebars.page-emploi-actualites .region-content-bottom,
.two-sidebars.page-emploi-actualites .region-extra-content,
.two-sidebars.page-guide-emploi-recruteur #contentContainer,
.two-sidebars.page-guide-emploi-recruteur .region-content-bottom,
.two-sidebars.page-guide-emploi-recruteur .region-extra-content,
.two-sidebars.page-carriere #contentContainer,
.two-sidebars.page-carriere .region-content-bottom,
.two-sidebars.page-carriere .region-extra-content {
  width: auto;
  display: block;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete,
.view-display-id-page .view-display-id-block_derniere_actu .entete,
.view-carriere .entete {
  background: url("#{$mj-images-path}/_/carriere.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 210px;
}
.entete {
  height: 210px;
  margin: 0px;
}
.view-carriere .field-label-hidden h2, .view-guide-emploi-recruteur .field-label-hidden h2 {
  display: none;
}
.view-carriere .views-field.views-field-title, .view-guide-emploi-recruteur .views-field.views-field-title {
  border-bottom: 3px solid #cbcbcb;
  margin-bottom: 5px;
}
.view-carriere .views-field.views-field-title a, .view-guide-emploi-recruteur .views-field.views-field-title a {
  text-decoration: none;
}
.view-guide-emploi-recruteur .views-field.views-field-title a {
  color: #de5e1e;
}
.view-carriere .views-field.views-field-title a {
  color: $brand-primary;
}
.view-carriere .views-field.views-field-title a, .view-guide-emploi-recruteur .views-field.views-field-title a {
  font-size: $font-size-base;
  font-weight: bold;
}
.view-carriere .field-label-hidden h3, .view-guide-emploi-recruteur .field-label-hidden h3 {
  display: none;
}
.view-carriere .field-label-hidden .importantBtn {
  display: none;
}
.view-carriere .field-label-hidden .horizontalPictureLeft, .view-guide-emploi-recruteur .field-label-hidden .horizontalPictureLeft {
  display: none;
}
.view-carriere .field-label-hidden .horizontalPicture {
  display: none;
}
.view-carriere .field-label-hidden .articleContent .arrowlienpremier, .view-guide-emploi-recruteur .field-label-hidden .articleContentrecruteur .arrowlienpremier {
  margin-top: 0px;
}
.view-carriere .views-field.views-field-body, .view-guide-emploi-recruteur .views-field.views-field-body {
  width: auto;
  margin-bottom: 10px;
  text-align: justify;
}
.view-carriere .field-label-hidden .articleContent .asavoir1 {
  margin-top: 0px;
}
.view-carriere .field-label-hidden .articleContent .paragraphe1 {
  width: 280px !important;
  margin-left: 0px !important;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .paragraphe-entete,
.page-evenements .entete .paragraphe-entete,
.view-display-id-block_derniere_actu .paragraphe-entete,
.view-carriere .entete .paragraphe-entete {
  width: 290px;
  height: 210px;
}
.view-carriere .entete .paragraphe-entete .paragraphe-entete-second {
  float: left;
  width: 270px;
  color: #121212;
  font-weight: bold;
  text-align: left;
  margin: 0px 0px 0px 20px;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete .btnLarge,
.view-display-id-block_derniere_actu .btnLarge,
.view-carriere .entete .paragraphe-entete .btnLarge {
  margin-left: 10px;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete p,
.view-display-id-block_derniere_actu .entete .paragraphe-entete p,
.view-carriere .entete .paragraphe-entete p,
.view-guide-emploi-recruteur .entete .paragraphe-entete p {
  float: left;
  width: 280px;
  color: $brand-primary;
  margin: 20px 0px 40px 20px;
  font-size: prem(25px);
  font-weight: bold;
  text-align: left;
  line-height: 27px;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete ul,
.view-display-id-block_derniere_actu .entete .paragraphe-entete ul,
.view-carriere .entete .paragraphe-entete ul,
.view-guide-emploi-recruteur .entete .paragraphe-entete ul {
  margin-left: 0;
}
.page-carriere .view-bloc-de-contenu-consol {
  background-color: #fcfcfc;
}
.view-carriere .node-carriere {
  margin-left: 0px;
}
.col-1.carriererow {
  padding-right: 11px;
}
.views-view-grid {
  width: 100%;
}
.view-carriere .views-view-grid td,
.view-guide-emploi-recruteur .views-view-grid td {
  vertical-align: top;
  padding-bottom: 10px;
}
.view-carriere .view-header p, .view-guide-emploi-recruteur .view-header p {
  text-align: justify;
  margin-right: 10px;
}
.view-carriere .view-display-id-page .view-header {
  margin-bottom: 0px;
}
.field-name-field-indexcarriere, .field-name-field-indexrecruteur {
  display: none;
}
.views-view-grid.cols-1 {
  width: 98%;
}
.page-carriere #contentContainer, .page-guide-emploi-recruteur #contentContainer {
  border: none;
}
/* fin view carriere */

/* view guide-emploi-recruteur */
.two-sidebars.page-guide-emploi-recruteur .region-content-bottom .block-block {
  width: 100%;
  margin: -1px !important;
}
.blocFooterRecruteur {
  background-color: #fdf4ef;
  border: 1px solid #eaeaea;
}
.blocFooterRecruteur h5 {
  margin: 0;
  padding: 15px 15px 0px 15px;
  color: #DE5E1E;
}
.blocFooterRecruteur p {
  margin: 5px 5px 5px 15px;
  padding: 0;
}
.blocFooterRecruteur p:last-child {
  padding: 0px 0px 15px 0px;
}
.section-qui-sommes-nous .blocFooterRecruteur {
  margin: 0 5px;
}
.view-guide-emploi-recruteur .entete .paragraphe-entete p {
  color: #de5e1e;
}
.page-carriere .view-carriere .view-content,
.page-guide-emploi-recruteur .view-guide-emploi-recruteur .view-content {
  margin-top: 15px;
}
.page-guide-emploi-recruteur .entete {
  border: none;
}
.rubanlogo {
  background: #f5f5f5 !important;
  height: 39px;
  margin: 0px;
  border-top: 1px solid #eaeaea;
}
#presCompHeadLink .minorBtn {
  margin-top: 30px;
}
#presCompHeadLink {
  border: none !important;
  margin: 0px !important;
}
.rubanlogo .rubanlogo-sociaux ul,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul {
  text-align: center;
}
.rubanlogo .rubanlogo-sociaux ul {
  margin: 0px;
}
.rubanlogo .rubanlogo-sociaux ul li:first-child,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul li:first-child {
  vertical-align: middle;
}
.rubanlogo .rubanlogo-sociaux ul li,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul li {
  display: inline;
  height: 40px;
}
.view-guide-emploi-recruteur .entete {
  background: url("#{$mj-images-path}/_/guide-emploi-recruteur.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 210px;
}
.entete ul {
  padding-left: 0;
  list-style: none;
}
.contactBox {
  margin: 5px 0 0;
  padding: 5px 10px;
  background: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  border: 1px solid #ccc;
  border-radius: 4px;
}
.view-guide-emploi-recruteur .views-field.views-field-path, .view-carriere .views-field.views-field-path {
  margin-bottom: 10px;
}
.page-emploi-actualites .node-type-actualites .links.inline .node-readmore a,
.view-guide-emploi-recruteur .views-field.views-field-path a,
.view-carriere .views-field.views-field-nothing a,
.page-guide-emploi-recruteur .entete .paragraphe-entete ul li a {
  background: url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px transparent;
  padding-left: 10px;
  font-weight: bold;
  color: $brand-primary;
}
.col-1.recruteurrow {
  padding-right: 11px;
}
.view-guide-emploi-recruteur .articleContent .itemInfoHome {
  font-weight: normal;
}
.view-guide-emploi-recruteur .node-guide-emploi-recruteur {
  margin-left: 0px;
}
.page-guide-emploi-recruteur .entete .minorActionBtn span {
  color: #121212;
  font-weight: normal;
}
.page-guide-emploi-recruteur .entete .minorActionBtn span:hover {
  text-decoration: none;
}
.page-guide-emploi-recruteur .entete .minorActionBtn span a {
  color: $brand-primary;
  font-weight: bold;
  text-decoration: underline;
}
.page-guide-emploi-recruteur .entete .paragraphe-entete {
  width: 300px;
}
.page-guide-emploi-recruteur .entete .paragraphe-entete ul li {
  margin: 5px 0px 0px 20px;
}
/* fin view guide-emploi-recruteur */

.views-label-field-generalistes {
  display: none;
}

.views-field-field-logo {
  position: relative;
  width: 149px;
  height: 87px;
  border: 1px solid #ccc;
  padding: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: #ccc 0px 0px 3px;
  -moz-box-shadow: #ccc 0px 0px 3px;
  box-shadow: #ccc 0px 0px 3px;
  background: #FFF;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#fff));
  background: -webkit-linear-gradient(#fff, #fff);
  background: -moz-linear-gradient(#fff, #fff);
  background: -ms-linear-gradient(#fff, #fff);
  background: -o-linear-gradient(#fff, #fff);
  background: linear-gradient(#fff, #fff);
  -pie-background: linear-gradient(#fff, #fff);
  behavior: url(/css/PIE.htc);
  margin: 10px;
  line-height: 110px;
}
.view-evenements .view-display-id-bloc_a_venir .view-header h3 {
  margin-top: 15px;
  color: inherit !important;
  background: url("/images/v6/console/trait-sous-titre.png") no-repeat scroll 0pt 100% transparent;
  height: 40px;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: prem(18px);
}
.menu.jquerymenu.jquerymenu-processed {
  padding-left: 0;
}
.jquerymenu .parent .closed span {
  background: url("#{$mj-images-path}/_/menu-arrowblack_OFF.png") no-repeat scroll 95% 50% transparent !important;
  padding-top: 5px !important;
  width: 10px !important;
  height: 4px !important;
  margin-top: 2px !important;
  margin-left: -15px !important;
}
.jquerymenu .parent .open span {
  background: url("#{$mj-images-path}/_/menu-arrowblack_ON.png") no-repeat scroll 95% 50% transparent !important;
  padding-top: 5px !important;
  width: 10px !important;
  height: 4px !important;
  margin-top: 2px !important;
  margin-left: -15px !important;
}
.jquerymenu .parent li {
  margin-top: 7px;
  margin-bottom: 5px;
}
ul.jquerymenu ul {
  padding-left: 0;
  list-style: none;
}
ul.jquerymenu li.parent {
  padding-left: 5px;
  margin-top: 7px;
  margin-bottom: 5px;
  padding-right: 5px;
}
ul.jquerymenu li.parent span.closed {
  background: url("#{$mj-images-path}/_/menu-arrowblue_OFF.png") no-repeat scroll 95% 50% transparent;
  width: 12px;
  height: 12px;
  margin-left: -20px;
  margin-top: 3px;
}
.view-display-id-dico_guide_emploi_recruteur ul.jquerymenu li.parent span.closed,
#block-jquerymenu-2 ul.jquerymenu li.parent span.closed {
  background: url("#{$mj-images-path}/_/menu-arrowblue_OFFrecruteur.png") no-repeat scroll 95% 50% transparent;
}
.view-display-id-dico_guide_emploi_recruteur ul.jquerymenu li.parent span.open,
#block-jquerymenu-2 ul.jquerymenu li.parent span.open {
  background: url("#{$mj-images-path}/_/menu-arrowblue_ONrecruteur.png") no-repeat scroll 95% 50% transparent;
}
ul.jquerymenu li.parent span.open {
  background: url("#{$mj-images-path}/_/menu-arrowblue_ON.png") no-repeat scroll 95% 50% transparent;
  width: 12px;
  height: 12px;
  margin-left: -17px;
  margin-top: 3px;
}
.jquerymenu a,
.jquerymenu a.active { /* top parent */
  color: $brand-primary;
  font-weight: bold;
  text-transform: uppercase;
}
.view-display-id-block_date .archives h3,
.node-type-guide-emploi-recruteur .content .view-display-id-dico_guide_emploi_recruteur .view-header ul li:first-child a,
.page-guide-emploi-recruteur .content .view-display-id-dico_guide_emploi_recruteur .view-header ul li:first-child a,
#block-jquerymenu-2 .jquerymenu a,
#block-jquerymenu-2 .jquerymenu a.active {
  text-transform: uppercase !important;
}
.view-display-id-dico_guide_emploi_recruteur .jquerymenu a,
.view-display-id-dico_guide_emploi_recruteur .jquerymenu a.active,
#block-jquerymenu-2 .jquerymenu a,
#block-jquerymenu-2 .jquerymenu a.active { /* top parent */
  color: #de5e1e;
  text-transform: none;
}
#block-jquerymenu-2 .jquerymenu ul a,
#block-jquerymenu-2 .jquerymenu ul a.active {
  color: #242424;
  text-transform: none !important;
}
.jquerymenu ul a,
.jquerymenu ul a.active { /* 1st level */
  color: #242424;
  text-transform: none;
}
.jquerymenu ul ul a,
.jquerymenu ul ul a.active { /* 2nd level */
  font-weight: normal;
}
.section-carriere .region-sidebar-first ul,
.section-guide-emploi-recruteur .region-sidebar-first ul {
  margin-left: 0;
}

.page-carriere .view-display-id-block_carriere, .node-type-guide-emploi-recruteur .view-display-id-dico_guide_emploi_recruteur,
.node-type-carriere .view-display-id-block_carriere, .page-guide-emploi-recruteur .view-display-id-dico_guide_emploi_recruteur {
  margin: 0px;
}
.view-id-dictionnaire_emploi_vue,
.node-guide-emploi-recruteur, .node-carriere {
  margin: 10px;
}
.node-guide-emploi-recruteur .articleContentrecruteur a, .node-carriere .articleContent a {
  color: $brand-primary;
}
.views-field-description {
  padding-left: 10px;
}
.view-dictionnaire-emploi-vue .views-field-name {
  font-size: prem(11px);
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 5px;
}
.page-carriere-dictionnaire-emploi #contentContainer, .page-guide-emploi-recruteur-dictionnaire-emploi #contentContainer {
  border: 1px solid #eaeaea;
}
.view-dictionnaire-emploi-vue .articleContent h2 {
  font-size: prem(18px);
  text-transform: none !important;
}
.view-display-id-block_carriere .item-list ul li, .view-display-id-dico_guide_emploi_recruteur .item-list ul li {
  margin-top: 7px !important;
  padding-left: 7px;
}
.view-display-id-dico_guide_emploi_recruteur .views-row a, .view-display-id-block_carriere .views-row a {
  color: #242424;
  font-weight: bold;
  padding-top: 5px;
}
.view-display-id-block_carriere {
  background-color: #fcfcfc;
}
.view-id-dictionnaire_emploi_vue h1 {
  border-bottom: 3px solid #cbcbcb;
}
.page-emploi-actualites-actualite .node-actualites .field-items .field-item.even img,
.node-type-actualites .node-actualites .field-type-image {
  float: left;
  padding-right: 10px;
}
/* PUSH application mobile */
.section-toutes-les-offres-meteojob-sur-votre-mobile .title {
  padding-bottom: 5px;
  border-bottom: 3px solid #cbcbcb;
  font-size: prem(24px);
  margin: 10px 0px 0px 0px !important;
}
.section-toutes-les-offres-meteojob-sur-votre-mobile .region-extra-content {
  margin: 10px 5px 0px;
  display: block;
  width: auto;
}
.section-toutes-les-offres-meteojob-sur-votre-mobile #contentContainer {
  border: 1px solid #eaeaea;
  margin: 0px 5px 10px 5px;
  width: auto;
  padding: 0px 20px;
}
.pageMobileContent {
  background: url("#{$mj-images-path}/_/iphone.png") no-repeat;
  background-position: 100% 100%;
}
.pageMobileContent .paragrapheMobileContent p {
}
.pageMobileContent .paragrapheMobileContent p:first-child {
  font-size: prem(14px);
  margin: 20px 0px;
  font-weight: bold;
}
/*.pageMobileContent .paragrapheMobileContent ul li {*/
/*background:url("/images/v6/generic/sprite-list.png") no-repeat scroll -148px 0px transparent;*/
/*padding:3px 0px 0px 40px;*/
/*margin-top:30px;*/
/*}*/
.pageMobileContent .paragrapheMobileContent ul li:last-child {
  margin-bottom: 40px;
}
.pageMobileContent .paragrapheMobileContent ul li p {
  text-align: justify;
  width: 280px;
}
.pageMobileContent .btn {
  margin: 0 0 60px;
}
.pageMobileContent .btn span {
  /*font-size:12px;*/
  /*line-height:16px;*/
  /*padding-top:10px;*/
}
.pageMobileContent .btn span em {
  font-style: normal;
  text-transform: none;
}
.pageMobileContent .paragrapheMobileContent ul li h3,
.pageMobileContent .paragrapheMobileContent ul li .mj-icon-li {
  color: inherit !important;
}
.pageMobileContent .paragrapheMobileContent ul li h3 {
  line-height: 2em;
}
/* Vue actualités */
.archives h3 {
  cursor: pointer;
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  padding: 6px 0;
}
.archives ul {
  padding: 0 0 0 10px;
  list-style: none;
}
.archives ul li {
  background: none;
  line-height: 130%;
  padding: 3px 0 5px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_date,
.node-type-actualites #contentContainer .view-display-id-block_date {
}
.page-emploi-actualites #contentContainer .view-display-id-block_date h3,
.node-type-actualites #contentContainer .view-display-id-block_date h3 {
  font-weight: bold;
}
.page-emploi-actualites #contentContainer .view-display-id-block_date h3,
.node-type-actualites #contentContainer .view-display-id-block_date h3 {
  padding-left: 5px;
}
.page-emploi-actualites #contentContainer .view-derniere-emission {
  margin: 10px 0px;
  border: 1px solid #eaeaea;
  width: 280px;
  float: right;
}
.page-emploi-actualites #contentContainer .views-field {
}
.page-emploi-actualites #contentContainer .views-field-title a {
  text-decoration: none;

}
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-actualites_emplois .view-display-id-block_encart {
  float: left;
  width: 310px;
  min-height: 176px;
  margin: 10px 0px;
  border: 1px solid #eaeaea;
}
.page-emploi-actualites #contentContainer .block-derniere-actualite .view-display-id-block_encart .views-field-title {
  margin: 0;
  padding: 0 5px 5px 0;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart .view-header,
.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-header,
.page-emploi-actualites #contentContainer .block-derniere-actualite .view-display-id-block_encart .view-content {
  border: none;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-id-derniere_emission .views-row-first .views-field-title {
  border-bottom: none;
  font-weight: normal;
  color: #121212;
  text-align: left;
}
.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-footer {
  margin-top: 15px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content .views-field-title {
  border-bottom: 3px solid #eaeaea;
}
.page-emploi-actualites #contentContainer .contentTitle {
  border-bottom: 3px solid #eaeaea;
  padding-top: 10px;
  margin: 0px 10px 5px 10px !important;
}
.page-emploi-actualites #contentContainer .view-derniere-emission .bulletsCandidate,
.page-emploi-actualites #contentContainer .views-field-field-date,
.page-emploi-actualites #contentContainer .view-derniere-emission .views-field-title,
.page-emploi-actualites #contentContainer .view-derniere-emission p {
  margin: 0px 10px;
}
.view-derniere-emission .views-field-field-date span {
  font-weight: bold;
}

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eaeaea;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content {
  margin: 0;
  padding: 5px 10px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content td {
  vertical-align: top;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .more-link {
  padding: 5px 10px 10px 10px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .more-link a {
  background: url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px transparent;
  padding-left: 10px;
  color: $brand-primary;
  font-weight: bold;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-field-title {
  border: none;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-field-created {
  font-weight: bold;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .view-content .views-field-path {
  margin-bottom: 0px;
}
.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-content .views-row-1 {
  margin-top: 0px;
  border: none;
  padding: 0px;
}
.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-content .views-row-1 a {
  color: #121212;
  font-weight: normal;
}
.section-emploi-actualites #contentContainer .views-field-body {
  clear: both;
  overflow: hidden;
  margin-top: 10px;
}
.view-id-actualites_archives h2 {
  color: $brand-primary;
}
.page-emploi-actualites .flux,
.node-type-actualites .flux {
  font-weight: bold;
  margin-top: 5px;
}
.page-emploi-actualites .flux img,
.node-type-actualites .flux img {
  vertical-align: middle;
}
.page-emploi-actualites-browse #page-title.title,
.page-emploi-actualites-archives #page-title.title,
.page-emploi-actualites-term #page-title.title,
.page-emploi-actualites-meteo-de-l-emploi #page-title.title {
  display: block;
  margin: 0px;
}
.page-emploi-actualites-browse #page-title.title {
  padding-bottom: 20px;
}
/*.page-emploi-actualites-browse #contentContainer,*/
.page-emploi-actualites-archives #contentContainer,
  /*.page-emploi-actualites-term #contentContainer, */
.page-emploi-actualites-meteo-de-l-emploi #contentContainer {
  border: 1px solid #eaeaea;
  padding: 10px;
}
.page-emploi-actualites.page-emploi-actualites-browse .minorActionBtn,
.page-emploi-actualites.page-emploi-actualites-archives .minorActionBtn,
.page-emploi-actualites.page-emploi-actualites-term .minorActionBtn {
  display: block;
}
.page-emploi-actualites #contentContainer .hpContentTV {
  background: url("/images/home/hp-sprite.jpg") no-repeat scroll right -230px transparent !important;
  height: 176px;
}
.page-emploi-actualites #contentContainer .view-id-actualites_emplois .view-header {

}
.node-type-actualites .flux img {
  vertical-align: middle;
}
.node-type-actualites #contentContainer {
  padding: 10px;
  width: auto;
}
/*.page-emploi-actualites #contentContainer .view-actualites-emplois .views-row,*/
.page-emploi-actualites #contentContainer .view-actualites-archives .views-row,
.page-emploi-actualites #contentContainer .view-actualites-archives-emploi .views-row {
  margin: 20px 0px 0px 0px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
}
.page-emploi-actualites #contentContainer .view-actualites-emplois .view-empty .views-row {
  padding: 0;
  border-top: 0 none;
}
.page-emploi-actualites #contentContainer .view-actualites-emplois .view-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-header.actu-border {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-actualites_emplois,
.page-emploi-actualites #contentContainer .view-display-id-page .view-display-id-block_derniere_actu .view-id-derniere_emission .view-content,
.page-emploi-actualites #contentContainer .contentTopContainer .view-id-actualites_emplois .block-derniere-actualite,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-derniere_emission .view-header,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .block-derniere-actualite .view-header {
  border: none;
  padding: 0px;
}
.page-emploi-actualites #contentContainer .contentTopContainer .view-id-actualites_emplois .encart-actualite {
  padding: 0px;
}
.page-emploi-actualites #contentContainer h1 {
  margin: 0px;
}
.node-type-actualites .rubanlogo,
.page-emploi-actualites-browse .rubanlogo,
.page-emploi-actualites-archives .rubanlogo,
.page-emploi-actualites-term .rubanlogo {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.node-type-actualites #page-title.title {
  margin: 0px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart .view-header,
.page-emploi-actualites #contentContainer .view-id-derniere_emission {
  margin-bottom: 0px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu {
  width: 290px;
  float: left;
  margin-top: 10px;
  border: 1px solid #eaeaea !important;
  padding: 10px !important;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title .field-content a {
  color: #121212;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title .field-content a:hover {
  text-decoration: underline;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title {
  border: none;
  padding-bottom: 3px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .views-field-created {
  display: block;
  margin-bottom: 5px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .views-field-created span {
  color: $brand-primary;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header h3 {
  margin: 0px;
  color: $brand-primary;
  border-bottom: 3px solid #eaeaea;
  padding-bottom: 5px;
  line-height: 18px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu {
  clear: both;
  overflow: hidden;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header,
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .view-content {
  border: none;
  padding: 0px;
}
.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header {
  margin-bottom: 5px !important;
}
.page-evenements .views-field-field-date-period {
  font-weight: bold;
  margin-top: 5px;
}
.page-evenements .views-field-body {
  margin-top: 5px;
}
.page-evenements .views-field-field-img {
  text-align: right;
}
.node-type-actualites #contentContainer .content .field-label {
  display: none;
}
.page-emploi-actualites-actualite #contentContainer .node-actualites .field-items .field-item.even img,
.page-emploi-actualites-actualite #contentContainer .node-actualites .field-type-taxonomy-term-reference .field-items .field-item {
  float: left;
}
.page-emploi-actualites-actualite #contentContainer .node-actualites .field-type-taxonomy-term-reference .field-items .field-item a {
  padding-right: 5px;
}
.page-emploi-actualites #contentContainer .field-name-body img {
  display: none;
}
.page-emploi-actualites #contentContainer .block-derniere-actualite .views-field-title {
  font-size: prem(22px);
  color: $brand-primary;
  font-weight: bold;
}
.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .view-id-testyp .views-row {
  margin: 15px 0px 0px;
}
.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .views-row {
  margin: 0px;
}
.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .views-field-name {
  font-weight: bold;
}
.page-emploi-actualites-actualite #contentContainer .node-actualites .page-title {
  margin-bottom: 15px;
}
.page-emploi-actualites-actualite #contentContainer .node-actualites .page-title a {
  text-decoration: none;
  color: $brand-primary;
}
.page-emploi-actualites-actualite #contentContainer .view-actualites-emplois {
  border: none;
  padding: 0px;
}
/*.page-emploi-actualites-actualite {
border:1px solid #eaeaea;
padding:10px;
}*/
.page-emploi-actualites #contentContainer .view-display-id-page .views-field-title {
  font-size: prem(18px);
}
/*
.page-emploi-actualites .view-display-id-page .view-display-id-block_derniere_actu .views-field-title {
font-size:22px;
}
*/
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content .views-row {
  margin: 0;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content .views-row {
  margin: 0 0 5px 0;
}
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-row-first {
  display: none;
}
.page-emploi-actualites-archives #contentContainer .views-summary-unformatted a {
  font-weight: bold;
}

/**
 * Contenu : "Actualites"
 */
.node-actualites .node-date {
  margin-bottom: 1em;
  color: #666;
  text-align: right;
}
/**
 * Dossier : Energie
 */
.content.energie .partner {
  text-align: center;
}
.content.energie .partner a {
  display: inline-block;
}
.content.energie .partner img {
  margin: 0 10px;
  vertical-align: top;
}
.node-type-actualites .content.energie h5 a {
  cursor: default;
  text-decoration: none;
}
.content.energie #interview {
  background: #fff;
  float: right;
  width: 150px;
  margin: 10px 0 10px 20px;
  border: 1px solid $brand-primary;
  padding: 5px;
  text-align: left;
}
.content.energie #interview h4 {
  color: $brand-primary;
  background: none;
  padding: 0;
  width: auto;
}
.content.energie #interview span {
  clear: both;
  float: left;
  margin: 5px 0;
  width: 150px;
}
.content.energie #interview img {
  float: left;
}
.content.energie #interview span a {
  float: right;
  margin: 3px;
  width: 85px;
  color: #242424;
  font-size: prem(10px);
}
.content.energie #interview a.botLink {
  float: left;
  clear: both;
  color: $brand-primary;
  font-weight: bold;
  font-size: prem(10px);
  width: 150px;
}
.content.energie #moreInfo .book {
  background: #fff;
  border: 1px solid #3984DB;
  float: left;
  height: 125px;
  margin: 5px;
  padding: 5px;
  width: 250px;
}
.content.energie #moreInfo .book a {
  margin: 0 0 0 5px;
}
.content.energie #moreInfo .book a,
#moreInfo span.book p {
  float: left;
}
.content.energie #moreInfo span a {
  font-weight: bold;
}
.content.energie #moreInfo .book p {
  width: 160px;
  margin: 0 0 10px 5px;
  text-align: left;
}
.content.energie table td {
  padding-right: 10px;
}
/**
 * Type de contenu : Arborescence
 */
.node-type-arborescence #contentContainer {
  background-color: #ffffff;
  padding: 15px 20px 0 20px;
}
.node-type-arborescence .field-name-field-item-link,
.node-type-arborescence .field-name-field-item-link-2,
.node-type-arborescence .field-name-field-item-link-3,
.node-type-arborescence .field-name-field-item-link-4 {
  padding-bottom: 20px;
}
.node-type-arborescence #main .field-name-body,
.node-type-arborescence #main .field-name-field-body-2,
.node-type-arborescence #main .field-name-field-body-3,
.node-type-arborescence #main .field-name-field-body-4 {
  padding-bottom: 10px;
}
.node-type-arborescence .region-content {
  width: 100%
}
