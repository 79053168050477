#error404PageWrapper {
    h1, h2 {
    font-family: 'Poiret One', $font-family-base;
    }
    
    #main {
      text-align: center;
      padding-top:0;
    }
    
    #rain {
      position: relative;
      min-height: 300px;
      background: url("#{$assets-path}/images/mj-rain-drops.png");
      background-size: 50px;
    
      h1 {
        font-size: prem(32px);
        margin: 0;
        display: inline-block;
        color: $brand-primary;
        white-space: nowrap;
      }
    
      .cloud {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        padding-top: 80px;
        height: 170px;
        background: url("#{$assets-path}/images/mj-cloud.png") no-repeat bottom center;
        background-size: contain;
      }
    }
    
    
    // TMP
    #header, #header-push-menu {
      .account-zone, .recruiter-links {
        display: none !important;
      }
    }
    
    #main-content {
      min-height: 200px;
      padding: 50px 0 40px;
      background-color: #ffffff;
    
      h2 {
        font-size: prem(24px);
        margin-bottom: 40px;
      }
    
      ul.list-inline {
        color: $brand-primary;
        & > li {
          padding: 15px 20px;
        }
      }
      li {
        cursor: pointer;
        transition: color 250ms, background-color 250ms;
        border-radius: $border-radius-base;
    
        & + li {
          margin-left: 15px;
        }
    
        div, .fa, .ft {
          text-align: left;
          display: inline-block;
          vertical-align: middle;
        }
        .fa, .ft {
          font-size: prem(40px);
          margin-right: 15px;
        }
    
        &:hover {
          color: #ffffff;
          background-color: $brand-primary;
        }
      }
    }

}