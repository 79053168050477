.section-alerte-interim {
  padding-top: 0;

  h1#page-title {
    display: none;
  }

  .block {
    margin-bottom: 0;
  }

  #main-wrapper {
    margin-bottom: 0;
  }

  #footer .subFooter {
    margin-top: 0;
  }

   #content {
    margin-top: 0;
  }

  .btn-white {
    color: $text-color!important;
  }

  .d-flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    .flex-bottom {
      align-items: flex-end;
    }
    .flex-center
    // overflow: hidden;
    .left {
      order: 1;
    }
    .right {
      order: 2;
    }
    .center {
      align-items: center;
      justify-content: center;
    }
  }

  /* HEADER */

  #header-sticky-container {
    .header-recruiter-zone-unlogged {
      li {
        padding: 0 $spacing-lg;
      }

      .child-separator {
        padding: 0;
        color: $text-color-light;
      }

      img {
        max-width: 30px;
        margin-right: 10px;
        margin-top: -3px;
      }
    }
  }

  #main {
    padding-top: ($header-top-height + $header-bottom-height);
    @media (max-width: $screen-md) {
      padding-top: $header-top-height;
    }
  }

  #mj-main-container {
    max-width: none;

    .container-fluid {
      padding-top: 50px;
      padding-bottom: 50px;
      // text-align:center;
    }
  }

  .title,
  #ads-banner {
    display: none !important;
  }

  .region-content {
    margin: 0 auto;
  }

  #main-wrapper,
  #content,
  .region-content {
    width: 100%;
  }

  .mj-role-candidate.mj-role-registered #mj-user-navigation {
    position: absolute;
    top: 25px;
    left: 80px;
  }

  #alerte-interim {
    background: #fff;
    .row {
      margin: 0;
    }

    h2 {
      font-size: 2.2em;
      font-weight: 600;
      line-height: initial;
      text-align: center;
      text-transform: none;
      color: $text-color;
    }

    #alerte-interim-header {
      h1.white {
        font-size: 2.6em;
        font-weight: 600;
        color: #FFFFFF;
        text-transform: none;
      }
      p {
        font-size: 1.4em;
        color: #ffffff;
      }
      .btn.btn-lg {
        padding: 10px 15px;
        white-space: normal;
      }
    }
  }
  body.section-alerte-interim.mj-role-company {
    padding-top: 0;
  }
    h1#page-title {
      display: none;
    }

    #breadcrumb {
      display: none;
    }
  @media (max-width: $screen-sm-max) {
    // 991px
    .d-flex {
      display: block;
      margin: 0!important;
    }
    .center {
      text-align: center;
    }
  }
}