.section-visibilite-cvtheque {
  padding-top:0;
  h1#page-title {display: none;}
  .block {margin-bottom:0;}
  #main-wrapper {margin-bottom:0;}
  #footer .subFooter {margin-top:0;}
  #logo {left:4%;}
  #content {margin-top:0;}

  #main {
    padding-top: ($header-top-height + $header-bottom-height);
    @media (max-width: $screen-md) {
      padding-top: $header-top-height;
    }
  }

  #mj-main-container {
    max-width: none;
    .container-fluid {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align:center;
    }
  }

  #mj-popin-wrapper #mj-popin {padding:0}

  #cvtheque-mj h2 {color:$text-color; font-size: 2.2em; font-weight: 600; line-height: initial;}

  hr.separator{width:130px; border-color:$brand-primary; border-top-width:2px; margin:30px auto;}

  #cvtheque-advantages, #cvtheque-advice {
    background:#fff;
    h3 {color:$brand-primary; text-transform:uppercase; margin-bottom: 0;}
    p {color:$gray-light;}
    .timeline-container {top: -10px;}
    .timeline-container.icon-lt {float: left; left:-20px;}
    .timeline-container.icon-rt {float: right; right:-20px;}
    .timeline-icon i {height: 40px; line-height: 38px; width: 40px; font-size: prem(25px); border: 2px solid $gray-light;
      border-radius: 50%; color: $brand-primary;text-align: center; position: relative; z-index: 1;
      background:#fff;}
    .pos-lt{left:30px}
    .pos-rt{right:30px;}
    .padd-40-lt {padding-left:40px;}
    .last {border:none;}
  }
  #cvtheque-video {
    background-color:$text-color;
    h2, p {color:#fff;}
    b {font-weight: 700}
    .col-sm-1 {width: 6.5%;}
    hr.separator {border-color:$brand-primary}
  }
  #cvtheque-how-to {
   background-color:$brand-primary;
   h2, p {color:#fff;}
   b {font-weight: 700}
   .col-sm-1 {width: 6.5%;}
   hr.separator {border-color:#ffffff}
   .btn-info-light {border-radius: 0; line-height: initial}
  }
  #cvtheque-advice {
    background-color: #FFFFFF;
  }

  .punchline {
    width:50%;
    float:right;
    text-align:center;
    margin: 4% 5% 0 0;
  }

  #cvtheque-mj .punchline h1 {
    font-size: 2.6em;
    font-weight:300;
    color:$text-color;
    text-transform:uppercase;
    text-align:left;
    padding: 20px 0;
    border-top: 1px solid $text-color;
    border-bottom: 1px solid $text-color;
  }
  .punchline p {
    font-size: 1.4em;
    color: $text-color;
  }
  .punchline a {
    color:#ffffff;
  }

  #search-form {
    background-color: $text-color;
    .search-title {display:block; font-weight:bold; color:#fff; text-align:center; line-height:34px;}
    .row{margin:0}
  }


}
/*********************************************************/
/*                CV Upload                              */
/*********************************************************/
.section-visibilite-cvtheque .title,
.section-visibilite-cvtheque #ads-banner {
  display:none!important;
}
.section-visibilite-cvtheque .region-content {
  margin: 0 auto;
}

.section-visibilite-cvtheque #main-wrapper, .section-visibilite-cvtheque #content, .section-visibilite-cvtheque .region-content, #cvtheque-mj #cvtheque-header, #cvtheque-mj #logos, #cvtheque-mj #main-advantages {
  width:100%;
}
#cvtheque-mj {
  background:#fff;
}
body.section-visibilite-cvtheque.mj-role-company{
  padding-top:0;
}
.section-visibilite-cvtheque.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px;
}
#cvtheque-mj #cvtheque-header {
  .punchline {padding-top: 100px; padding-left: 100px;}
  h2 {color: $text-color;}
  hr.separator {border-color:$brand-primary-alt}
  .btn-primary {width:100%; font-weight: 600; padding: 15px; background-color:$brand-primary-alt; border-color:$brand-primary-alt; white-space: normal;}
  .btn-primary:hover {background-color:$brand-primary}
}
.section-visibilite-cvtheque #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(#{$mj-images-path}/_/cvtheque/bg-header-cvtheque.png) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color:#D7E5F4;
  min-height: 580px;
}

@media (max-width: 992px){

  /************ Landing page Offres emploi *************/
  .section-visibilite-cvtheque {
    #mj-popin-wrapper #mj-popin {
      /*background-position: 15% 0!important;
      padding-bottom:0;*/
    }
    #mj-popin-wrapper.no-popin #mj-popin {
      min-height:auto
    }
    #search-form {
      .col-md-3.form-group {padding:0;}
    }
  }
}

@media (max-width: 767px) {

  .section-visibilite-cvtheque {

    #mj-popin-wrapper #mj-popin {
      padding: 120px 0 50px 0!important;
      background-position: 0!important;}

    #mj-popin-wrapper.no-popin #mj-popin {
      padding-top:0;
      min-height: auto;
    }

    #cvtheque-mj {
      #cvtheque-header {
        .btn-primary {box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);}
        .punchline {
          text-align: center;
          padding:10px 25px;
          margin: 0 auto;
          float: none;
          width:auto;
          h1, h2 {
            color: #FFFFFF;
            font-size: 1.8em;
            text-align: center;
            text-shadow: 2px 2px 10px black;
          }
          p {
            text-shadow: 2px 2px 10px black;
            color: #FFFFFF;
          }
        }
      }
      .float-lt,
      .float-rt {
        float:none;
        text-align:center;
      }
      #cvtheque-advantages,
      #cvtheque-advice {
        .pos-lt{left:initial}
        .pos-rt{right:initial}
        .padd-40-lt {padding-left:10px; border:none}
        .padd-40-rt {padding-right:10px; border:none}
        .timeline-container {padding-top:40px;}
      }
    }
  }
}

@media (max-width: $screen-xs-max) {

  .section-visibilite-cvtheque {
    .no-padd-lt, .no-padd-rt {padding:0}
    .float-lt, .float-rt {float:none; text-align:center;}
  }

}
