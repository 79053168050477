.section-blog-emploi {
  h2 {
    text-transform: none;
  }

  ul a.active {font-weight: bold}

  #actu-img-bg {
    width: 100%;
    height: 350px;
    position: relative;
    margin-bottom: 1.2em;
  }

  #actu-img-triangle {
    position: absolute;
    bottom: 0;
    left: 5%;
  }

  .node-type-actualites .region-content .block {
    margin-bottom: .8em;
}

  .views-row .node-actualites.node-teaser  {
    padding-bottom: 20px;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 20px;
  }

  .views-row-first .node-actualites.node-teaser {
    padding-top: 20px;
    border-top: 1px dashed #ccc;
    margin-top: 20px;
  }

  .view-id-editorial_content {
    padding-bottom: 30px;
  }

  #views-exposed-form-blog-emploi-search .views-exposed-widgets {
    position: relative;

    .views-submit-button {
      position: absolute;
      right: 0;
      top: 0;
    }

    .form-submit {
      display:none;
    }
  }

  #block-menu-menu-menu-cat-gories-d-actualit- ul {
    padding: 0 0 0 10px;
  }

  .node-date-small {
    margin: .5rem 0;
  }

  @media (min-width: 992px) {
    #mj-main-container .mj-column-right .region-sidebar-second {
      position: sticky;
      top: ($header-top-height + ($grid-gutter-width / 2));
    }
  }
}

@media (max-width: 992px) {

  .section-blog-emploi {
    #header-bottom-container {
      display: block;

      .hide-search-form {
        display: none !important;
      }

      .header-search-form form {
        display: block;

        .inputs-wrapper {
          width: 100%;

          .row>div {
            padding: 0;
            margin: 5px 0;
          }
        }

        .search-form-submit {
          display: none;
        }

        .search-form-submit-responsive {
          display: block;
          width: 100%;
        }
      }
    }
    #main {
      padding-top: 140px;
    }

    .main-padding-top-search-form-open {
      padding-top: 250px !important;
    }
  }
  
}
