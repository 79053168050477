body.node-type-fiche-entreprise {
  h1#page-title {
    display: none;
  }

  .view-fiches-entreprises-index a.active {
    text-decoration: underline;
  }

  .well-header {
    margin-bottom: $spacing-lg;

    h1 {
      height: 90px;
      color: $text-color;
      text-transform: none;
      font-weight: 600;
      font-size: 2em;

      &::after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    h1,
    .thumbnail {
      margin-bottom: 0;
    }
  }

  .node-fiche-entreprise {
    & *[style] {
      font-family: $font-family-base !important;
    }

    ul {
      padding-left: 0;
      list-style: none;
    }

    .thumbnail {
      display: inline-block;
      background-color: #fff;
    }

    .company-logo {
      text-align: right;
    }

    .mj-title-separator {
      margin-bottom: 60px !important;
    }

    .companyBlock {
      margin-bottom: $spacing-lg;

        &:before,
        &:after {
          content: " "; // 1
          display: table; // 2
        }
        &:after {
          clear: both;
        }

      .btn-primary {
        float: right;
      }
    }

    .offerBlock {
      a {
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
      }

      .offerLocation {
        font-weight: 600;
      }
    }

    #companyDescFiches {
      ul {
        margin-bottom: 0;
      }

      a {
        color: inherit;
      }
    }
  }
}