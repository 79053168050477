@charset "UTF-8";
/* ----------------------------- */
/* == UI-kit variables           */
/* ----------------------------- */
/**
  * Bootstrap variables overrides
  *
  * This files imitate the structure from the bootstrap variables file (https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss)
  * To be loaded BEFORE bootstrap variables
  * Every variables here MUST have "!default" at the end of declaration
// --------------------------------------------------------------------
**/
/** Default color for bootstrap overridden by client. */
/**
 * Custom variables specific to our company
 *
 * Load this AFTER bootstrap + override variables
 * Every variables must be followed by "!default"
 * -----------------------------------------
*/
/** Overide navdivider mixin from bootstrap. */
/**
  * Ghost buttons
  */
/**
  * Add an arrow on top of a div
  * the div must not have a 'static' display
  * @param enum(left, right) left: In which top corner to put the arrow
  */
#error404PageWrapper h1, #error404PageWrapper h2 {
  font-family: "Poiret One", "DM Sans", Arial, sans-serif; }

#error404PageWrapper #main {
  text-align: center;
  padding-top: 0; }

#error404PageWrapper #rain {
  position: relative;
  min-height: 300px;
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/mj-rain-drops.png");
  background-size: 50px; }
  #error404PageWrapper #rain h1 {
    font-size: 2rem;
    margin: 0;
    display: inline-block;
    color: #16354E;
    white-space: nowrap; }
  #error404PageWrapper #rain .cloud {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    padding-top: 80px;
    height: 170px;
    background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/mj-cloud.png") no-repeat bottom center;
    background-size: contain; }

#error404PageWrapper #header .account-zone, #error404PageWrapper #header .recruiter-links, #error404PageWrapper #header-push-menu .account-zone, #error404PageWrapper #header-push-menu .recruiter-links {
  display: none !important; }

#error404PageWrapper #main-content {
  min-height: 200px;
  padding: 50px 0 40px;
  background-color: #ffffff; }
  #error404PageWrapper #main-content h2 {
    font-size: 1.5rem;
    margin-bottom: 40px; }
  #error404PageWrapper #main-content ul.list-inline {
    color: #16354E; }
    #error404PageWrapper #main-content ul.list-inline > li {
      padding: 15px 20px; }
  #error404PageWrapper #main-content li {
    cursor: pointer;
    transition: color 250ms, background-color 250ms;
    border-radius: 0.5rem; }
    #error404PageWrapper #main-content li + li {
      margin-left: 15px; }
    #error404PageWrapper #main-content li div, #error404PageWrapper #main-content li .fa, #error404PageWrapper #main-content li .ft {
      text-align: left;
      display: inline-block;
      vertical-align: middle; }
    #error404PageWrapper #main-content li .fa, #error404PageWrapper #main-content li .ft {
      font-size: 2.5rem;
      margin-right: 15px; }
    #error404PageWrapper #main-content li:hover {
      color: #ffffff;
      background-color: #16354E; }

.section-alerte-interim {
  padding-top: 0;
  /* HEADER */ }
  .section-alerte-interim h1#page-title {
    display: none; }
  .section-alerte-interim .block {
    margin-bottom: 0; }
  .section-alerte-interim #main-wrapper {
    margin-bottom: 0; }
  .section-alerte-interim #footer .subFooter {
    margin-top: 0; }
  .section-alerte-interim #content {
    margin-top: 0; }
  .section-alerte-interim .btn-white {
    color: #16354E !important; }
  .section-alerte-interim .d-flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .section-alerte-interim .d-flex .flex-bottom {
      align-items: flex-end; }
    .section-alerte-interim .d-flex .flex-center .left {
      order: 1; }
    .section-alerte-interim .d-flex .right {
      order: 2; }
    .section-alerte-interim .d-flex .center {
      align-items: center;
      justify-content: center; }
  .section-alerte-interim #header-sticky-container .header-recruiter-zone-unlogged li {
    padding: 0 20px; }
  .section-alerte-interim #header-sticky-container .header-recruiter-zone-unlogged .child-separator {
    padding: 0;
    color: #ffffff; }
  .section-alerte-interim #header-sticky-container .header-recruiter-zone-unlogged img {
    max-width: 30px;
    margin-right: 10px;
    margin-top: -3px; }
  .section-alerte-interim #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-alerte-interim #main {
        padding-top: 60px; } }
  .section-alerte-interim #mj-main-container {
    max-width: none; }
    .section-alerte-interim #mj-main-container .container-fluid, .section-alerte-interim #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-alerte-interim #mj-main-container .domains-block,
    .section-alerte-interim #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-alerte-interim #mj-main-container .regions-block,
    .section-alerte-interim #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-alerte-interim #mj-main-container .entreprises-block,
    .section-alerte-interim #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-alerte-interim #mj-main-container .domains-block,
    .section-alerte-interim #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-alerte-interim #mj-main-container .regions-block,
    .section-alerte-interim #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-alerte-interim #mj-main-container .entreprises-block, .section-alerte-interim #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-alerte-interim #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px; }
  .section-alerte-interim .title,
  .section-alerte-interim #ads-banner {
    display: none !important; }
  .section-alerte-interim .region-content {
    margin: 0 auto; }
  .section-alerte-interim #main-wrapper,
  .section-alerte-interim #content,
  .section-alerte-interim .region-content {
    width: 100%; }
  .section-alerte-interim .mj-role-candidate.mj-role-registered #mj-user-navigation {
    position: absolute;
    top: 25px;
    left: 80px; }
  .section-alerte-interim #alerte-interim {
    background: #fff; }
    .section-alerte-interim #alerte-interim .row {
      margin: 0; }
    .section-alerte-interim #alerte-interim h2 {
      font-size: 2.2em;
      font-weight: 600;
      line-height: initial;
      text-align: center;
      text-transform: none;
      color: #16354E; }
    .section-alerte-interim #alerte-interim #alerte-interim-header h1.white {
      font-size: 2.6em;
      font-weight: 600;
      color: #FFFFFF;
      text-transform: none; }
    .section-alerte-interim #alerte-interim #alerte-interim-header p {
      font-size: 1.4em;
      color: #ffffff; }
    .section-alerte-interim #alerte-interim #alerte-interim-header .btn.btn-lg {
      padding: 10px 15px;
      white-space: normal; }
  .section-alerte-interim body.section-alerte-interim.mj-role-company {
    padding-top: 0; }
  .section-alerte-interim h1#page-title {
    display: none; }
  .section-alerte-interim #breadcrumb {
    display: none; }
  @media (max-width: 991px) {
    .section-alerte-interim .d-flex {
      display: block;
      margin: 0 !important; }
    .section-alerte-interim .center {
      text-align: center; } }

.section-blog-emploi h2 {
  text-transform: none; }

.section-blog-emploi ul a.active {
  font-weight: bold; }

.section-blog-emploi #actu-img-bg {
  width: 100%;
  height: 350px;
  position: relative;
  margin-bottom: 1.2em; }

.section-blog-emploi #actu-img-triangle {
  position: absolute;
  bottom: 0;
  left: 5%; }

.section-blog-emploi .node-type-actualites .region-content .block {
  margin-bottom: .8em; }

.section-blog-emploi .views-row .node-actualites.node-teaser {
  padding-bottom: 20px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 20px; }

.section-blog-emploi .views-row-first .node-actualites.node-teaser {
  padding-top: 20px;
  border-top: 1px dashed #ccc;
  margin-top: 20px; }

.section-blog-emploi .view-id-editorial_content {
  padding-bottom: 30px; }

.section-blog-emploi #views-exposed-form-blog-emploi-search .views-exposed-widgets {
  position: relative; }
  .section-blog-emploi #views-exposed-form-blog-emploi-search .views-exposed-widgets .views-submit-button {
    position: absolute;
    right: 0;
    top: 0; }
  .section-blog-emploi #views-exposed-form-blog-emploi-search .views-exposed-widgets .form-submit {
    display: none; }

.section-blog-emploi #block-menu-menu-menu-cat-gories-d-actualit- ul {
  padding: 0 0 0 10px; }

.section-blog-emploi .node-date-small {
  margin: .5rem 0; }

@media (min-width: 992px) {
  .section-blog-emploi #mj-main-container .mj-column-right .region-sidebar-second {
    position: sticky;
    top: 70px; } }

@media (max-width: 992px) {
  .section-blog-emploi #header-bottom-container {
    display: block; }
    .section-blog-emploi #header-bottom-container .hide-search-form {
      display: none !important; }
    .section-blog-emploi #header-bottom-container .header-search-form form {
      display: block; }
      .section-blog-emploi #header-bottom-container .header-search-form form .inputs-wrapper {
        width: 100%; }
        .section-blog-emploi #header-bottom-container .header-search-form form .inputs-wrapper .row > div {
          padding: 0;
          margin: 5px 0; }
      .section-blog-emploi #header-bottom-container .header-search-form form .search-form-submit {
        display: none; }
      .section-blog-emploi #header-bottom-container .header-search-form form .search-form-submit-responsive {
        display: block;
        width: 100%; }
  .section-blog-emploi #main {
    padding-top: 140px; }
  .section-blog-emploi .main-padding-top-search-form-open {
    padding-top: 250px !important; } }

.section-deposer-son-cv {
  padding-top: 0; }
  .section-deposer-son-cv h1#page-title {
    display: none; }
  .section-deposer-son-cv .block {
    margin-bottom: 0; }
  .section-deposer-son-cv #main-wrapper {
    margin-bottom: 0; }
  .section-deposer-son-cv #footer .subFooter {
    margin-top: 0; }
  .section-deposer-son-cv #logo {
    left: 4%; }
  .section-deposer-son-cv #content {
    margin-top: 0; }
  .section-deposer-son-cv #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-deposer-son-cv #main {
        padding-top: 60px; } }
  .section-deposer-son-cv #mj-main-container {
    max-width: none; }
    .section-deposer-son-cv #mj-main-container .container-fluid, .section-deposer-son-cv #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-deposer-son-cv #mj-main-container .domains-block,
    .section-deposer-son-cv #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-deposer-son-cv #mj-main-container .regions-block,
    .section-deposer-son-cv #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-deposer-son-cv #mj-main-container .entreprises-block,
    .section-deposer-son-cv #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-deposer-son-cv #mj-main-container .domains-block,
    .section-deposer-son-cv #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-deposer-son-cv #mj-main-container .regions-block,
    .section-deposer-son-cv #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-deposer-son-cv #mj-main-container .entreprises-block, .section-deposer-son-cv #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-deposer-son-cv #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align: center; }
  .section-deposer-son-cv #mj-popin-wrapper #mj-popin {
    padding: 0; }
  .section-deposer-son-cv #cv-upload-mj h2 {
    color: #16354E;
    font-size: 2.2em;
    font-weight: 600;
    line-height: initial; }
  .section-deposer-son-cv hr.separator {
    width: 130px;
    border-color: #16354E;
    border-top-width: 2px;
    margin: 30px auto; }
  .section-deposer-son-cv #cv-upload-logos .container-fluid, .section-deposer-son-cv #cv-upload-logos .cc-page-index-seo .domains-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-logos .domains-block,
  .section-deposer-son-cv #cv-upload-logos .cc-page-index-seo .regions-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-logos .regions-block,
  .section-deposer-son-cv #cv-upload-logos .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-logos .entreprises-block,
  .section-deposer-son-cv #cv-upload-logos .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-logos .domains-block,
  .section-deposer-son-cv #cv-upload-logos .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-logos .regions-block,
  .section-deposer-son-cv #cv-upload-logos .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-logos .entreprises-block, .section-deposer-son-cv #cv-upload-logos .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-deposer-son-cv #cv-upload-logos #offres-emploi-header {
    padding: 10px; }
  .section-deposer-son-cv #cv-upload-logos p {
    text-align: left; }
  .section-deposer-son-cv .line-logos {
    height: 80px;
    margin: 0 0 10px 0;
    overflow: hidden; }
  .section-deposer-son-cv .line {
    position: relative; }
  .section-deposer-son-cv #mj-logos {
    position: absolute;
    width: 999999px; }
  .section-deposer-son-cv #mj-logos .view, .section-deposer-son-cv #mj-logos .view .views-row, .section-deposer-son-cv #mj-logos > div {
    float: left; }
  .section-deposer-son-cv #cv-upload-timeline, .section-deposer-son-cv #cv-upload-find-us {
    background: #fff; }
  .section-deposer-son-cv #cv-upload-timeline h3 {
    color: #16354E;
    text-transform: uppercase; }
  .section-deposer-son-cv #cv-upload-timeline p {
    color: #8B9AA7; }
  .section-deposer-son-cv #cv-upload-timeline .timeline-container {
    top: -10px; }
  .section-deposer-son-cv #cv-upload-timeline .timeline-container.icon-lt {
    float: left;
    left: -20px; }
  .section-deposer-son-cv #cv-upload-timeline .timeline-container.icon-rt {
    float: right;
    right: -20px; }
  .section-deposer-son-cv #cv-upload-timeline .timeline-icon i {
    height: 40px;
    line-height: 38px;
    width: 40px;
    font-size: 1.5625rem;
    border: 2px solid #8B9AA7;
    border-radius: 50%;
    color: #16354E;
    text-align: center;
    position: relative;
    z-index: 1;
    background: #fff; }
  .section-deposer-son-cv #cv-upload-timeline .pos-lt {
    left: 30px; }
  .section-deposer-son-cv #cv-upload-timeline .pos-rt {
    right: 30px; }
  .section-deposer-son-cv #cv-upload-timeline .padd-40-lt {
    padding-left: 40px;
    border-left-color: #8B9AA7;
    border-left-style: solid;
    border-left-width: 2px; }
  .section-deposer-son-cv #cv-upload-timeline .padd-40-rt {
    padding-right: 40px;
    border-right-color: #8B9AA7;
    border-right-style: solid;
    border-right-width: 2px; }
  .section-deposer-son-cv #cv-upload-timeline .last {
    border: none; }
  .section-deposer-son-cv #cv-upload-offers {
    background-color: #16354E; }
    .section-deposer-son-cv #cv-upload-offers h2 {
      color: #fff;
      margin-bottom: 0; }
      .section-deposer-son-cv #cv-upload-offers h2 span {
        font-size: 1.4em; }
    .section-deposer-son-cv #cv-upload-offers .timeline-icon i {
      height: 40px;
      line-height: 38px;
      width: 40px;
      font-size: 1.5625rem;
      border: 2px solid #fff;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 1;
      background: #16354E; }
  .section-deposer-son-cv #cv-upload-search .container-fluid, .section-deposer-son-cv #cv-upload-search .cc-page-index-seo .domains-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-search .domains-block,
  .section-deposer-son-cv #cv-upload-search .cc-page-index-seo .regions-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-search .regions-block,
  .section-deposer-son-cv #cv-upload-search .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-deposer-son-cv #cv-upload-search .entreprises-block,
  .section-deposer-son-cv #cv-upload-search .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-search .domains-block,
  .section-deposer-son-cv #cv-upload-search .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-search .regions-block,
  .section-deposer-son-cv #cv-upload-search .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-deposer-son-cv #cv-upload-search .entreprises-block, .section-deposer-son-cv #cv-upload-search .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-deposer-son-cv #cv-upload-search #offres-emploi-header {
    padding-top: 15px;
    padding-bottom: 15px; }
  .section-deposer-son-cv #cv-upload-how-to {
    background: url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/cv-upload/bottom-clouds.png) no-repeat center bottom;
    background-color: #16354E; }
    .section-deposer-son-cv #cv-upload-how-to h2, .section-deposer-son-cv #cv-upload-how-to p {
      color: #fff; }
    .section-deposer-son-cv #cv-upload-how-to b {
      font-weight: 700; }
    .section-deposer-son-cv #cv-upload-how-to .col-sm-1 {
      width: 6.5%; }
    .section-deposer-son-cv #cv-upload-how-to hr.separator {
      border-color: #16354E; }
  .section-deposer-son-cv .punchline {
    width: 50%;
    float: right;
    text-align: center;
    margin: 4% 5% 0 0; }
  .section-deposer-son-cv #cv-upload-mj .punchline h1 {
    font-size: 2.6em;
    font-weight: 300;
    color: #16354E;
    text-transform: uppercase;
    text-align: left;
    padding: 20px 0;
    border-top: 1px solid #16354E;
    border-bottom: 1px solid #16354E; }
  .section-deposer-son-cv .punchline p {
    font-size: 1.4em;
    color: #ffffff; }
  .section-deposer-son-cv .punchline a {
    color: #ffffff; }
  .section-deposer-son-cv #search-form {
    background-color: #16354E; }
    .section-deposer-son-cv #search-form .search-title {
      display: block;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 34px; }
    .section-deposer-son-cv #search-form .row {
      margin: 0; }

/*********************************************************/
/*                CV Upload                              */
/*********************************************************/
.section-deposer-son-cv .title,
.section-deposer-son-cv #ads-banner {
  display: none !important; }

.section-deposer-son-cv .region-content {
  margin: 0 auto; }

.section-deposer-son-cv #main-wrapper, .section-deposer-son-cv #content, .section-deposer-son-cv .region-content, #cv-upload-mj #cv-upload-header, #cv-upload-mj #logos, #cv-upload-mj #main-advantages, #cv-upload-mj #domains, #cv-upload-mj #media {
  width: 100%; }

#cv-upload-mj {
  background: #fff;
  /*padding: 50px 0;*/ }

#cv-upload-mj #main-advantages {
  background: #eeeeee; }

#cv-upload-mj #emplois-domaines, #cv-upload-mj #emplois-entreprises {
  background: #f9f9f9; }

#cv-upload-mj .main-advantages-block, #cv-upload-mj .domains-block, #cv-upload-mj .media-block, #cv-upload-mj .entreprises-block, #cv-upload-mj .regions-block {
  width: 70%;
  margin: 0 auto; }

body.section-deposer-son-cv.mj-role-company {
  padding-top: 0; }

.section-deposer-son-cv.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px; }

#cv-upload-mj #cv-upload-header .punchline {
  padding-top: 100px;
  padding-left: 100px; }

#cv-upload-mj #cv-upload-header h2 {
  color: #fff; }

#cv-upload-mj #cv-upload-header hr.separator {
  border-color: #00B5DD; }

#cv-upload-mj #cv-upload-header .btn-primary {
  width: 100%;
  font-weight: 600;
  padding: 15px;
  background-color: #00B5DD;
  border-color: #00B5DD;
  white-space: normal; }

#cv-upload-mj #cv-upload-header .btn-primary:hover {
  background-color: #16354E; }

.section-deposer-son-cv #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/cv-upload/bg-header-cv-upload.png) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color: #80a2b1;
  min-height: 580px; }

@media (max-width: 992px) {
  /************ Landing page Offres emploi *************/
  .section-deposer-son-cv #mj-popin-wrapper.no-popin #mj-popin {
    min-height: auto; }
  .section-deposer-son-cv #search-form .col-md-3.form-group {
    padding: 0; } }

@media (max-width: 767px) {
  .section-deposer-son-cv #cv-upload-mj #cv-upload-header .punchline {
    width: auto;
    text-align: center;
    margin: 100px auto; }
  .section-deposer-son-cv #cv-upload-mj #cv-upload-header .btn-primary {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
  .section-deposer-son-cv .punchline h1, .section-deposer-son-cv .punchline h2 {
    font-size: 1.8em;
    text-align: center;
    text-shadow: 2px 2px 10px black; }
  .section-deposer-son-cv .punchline p {
    text-shadow: 2px 2px 10px black; }
  .section-deposer-son-cv #mj-popin-wrapper #mj-popin {
    padding: 120px 0 50px 0 !important;
    background-position: 0 !important; }
  .section-deposer-son-cv #mj-popin-wrapper.no-popin #mj-popin {
    padding-top: 0;
    min-height: auto; }
  .section-deposer-son-cv #cv-upload-mj #cv-upload-header .punchline {
    text-align: center;
    padding: 10px 25px;
    margin: 0 auto;
    float: none; }
  .section-deposer-son-cv #cv-upload-mj .punchline h1 {
    text-align: center; }
  .section-deposer-son-cv #cv-upload .float-lt, .section-deposer-son-cv #cv-upload .float-rt {
    float: none;
    text-align: center;
    /*padding: 0;*/ }
  .section-deposer-son-cv #cv-upload-timeline .pos-lt {
    left: initial; }
  .section-deposer-son-cv #cv-upload-timeline .pos-rt {
    right: initial; }
  .section-deposer-son-cv #cv-upload-timeline .padd-40-lt {
    padding-left: 10px;
    border: none; }
  .section-deposer-son-cv #cv-upload-timeline .padd-40-rt {
    padding-right: 10px;
    border: none; }
  .section-deposer-son-cv #cv-upload-timeline .timeline-container {
    padding-top: 40px; } }

@media (max-width: 767px) {
  .section-deposer-son-cv .no-padd-lt, .section-deposer-son-cv .no-padd-rt {
    padding: 0; }
  .section-deposer-son-cv .float-lt, .section-deposer-son-cv .float-rt {
    float: none;
    text-align: center; } }

.section-visibilite-cvtheque {
  padding-top: 0; }
  .section-visibilite-cvtheque h1#page-title {
    display: none; }
  .section-visibilite-cvtheque .block {
    margin-bottom: 0; }
  .section-visibilite-cvtheque #main-wrapper {
    margin-bottom: 0; }
  .section-visibilite-cvtheque #footer .subFooter {
    margin-top: 0; }
  .section-visibilite-cvtheque #logo {
    left: 4%; }
  .section-visibilite-cvtheque #content {
    margin-top: 0; }
  .section-visibilite-cvtheque #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-visibilite-cvtheque #main {
        padding-top: 60px; } }
  .section-visibilite-cvtheque #mj-main-container {
    max-width: none; }
    .section-visibilite-cvtheque #mj-main-container .container-fluid, .section-visibilite-cvtheque #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-visibilite-cvtheque #mj-main-container .domains-block,
    .section-visibilite-cvtheque #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-visibilite-cvtheque #mj-main-container .regions-block,
    .section-visibilite-cvtheque #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-visibilite-cvtheque #mj-main-container .entreprises-block,
    .section-visibilite-cvtheque #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-visibilite-cvtheque #mj-main-container .domains-block,
    .section-visibilite-cvtheque #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-visibilite-cvtheque #mj-main-container .regions-block,
    .section-visibilite-cvtheque #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-visibilite-cvtheque #mj-main-container .entreprises-block, .section-visibilite-cvtheque #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-visibilite-cvtheque #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align: center; }
  .section-visibilite-cvtheque #mj-popin-wrapper #mj-popin {
    padding: 0; }
  .section-visibilite-cvtheque #cvtheque-mj h2 {
    color: #16354E;
    font-size: 2.2em;
    font-weight: 600;
    line-height: initial; }
  .section-visibilite-cvtheque hr.separator {
    width: 130px;
    border-color: #16354E;
    border-top-width: 2px;
    margin: 30px auto; }
  .section-visibilite-cvtheque #cvtheque-advantages, .section-visibilite-cvtheque #cvtheque-advice {
    background: #fff; }
    .section-visibilite-cvtheque #cvtheque-advantages h3, .section-visibilite-cvtheque #cvtheque-advice h3 {
      color: #16354E;
      text-transform: uppercase;
      margin-bottom: 0; }
    .section-visibilite-cvtheque #cvtheque-advantages p, .section-visibilite-cvtheque #cvtheque-advice p {
      color: #8B9AA7; }
    .section-visibilite-cvtheque #cvtheque-advantages .timeline-container, .section-visibilite-cvtheque #cvtheque-advice .timeline-container {
      top: -10px; }
    .section-visibilite-cvtheque #cvtheque-advantages .timeline-container.icon-lt, .section-visibilite-cvtheque #cvtheque-advice .timeline-container.icon-lt {
      float: left;
      left: -20px; }
    .section-visibilite-cvtheque #cvtheque-advantages .timeline-container.icon-rt, .section-visibilite-cvtheque #cvtheque-advice .timeline-container.icon-rt {
      float: right;
      right: -20px; }
    .section-visibilite-cvtheque #cvtheque-advantages .timeline-icon i, .section-visibilite-cvtheque #cvtheque-advice .timeline-icon i {
      height: 40px;
      line-height: 38px;
      width: 40px;
      font-size: 1.5625rem;
      border: 2px solid #8B9AA7;
      border-radius: 50%;
      color: #16354E;
      text-align: center;
      position: relative;
      z-index: 1;
      background: #fff; }
    .section-visibilite-cvtheque #cvtheque-advantages .pos-lt, .section-visibilite-cvtheque #cvtheque-advice .pos-lt {
      left: 30px; }
    .section-visibilite-cvtheque #cvtheque-advantages .pos-rt, .section-visibilite-cvtheque #cvtheque-advice .pos-rt {
      right: 30px; }
    .section-visibilite-cvtheque #cvtheque-advantages .padd-40-lt, .section-visibilite-cvtheque #cvtheque-advice .padd-40-lt {
      padding-left: 40px; }
    .section-visibilite-cvtheque #cvtheque-advantages .last, .section-visibilite-cvtheque #cvtheque-advice .last {
      border: none; }
  .section-visibilite-cvtheque #cvtheque-video {
    background-color: #16354E; }
    .section-visibilite-cvtheque #cvtheque-video h2, .section-visibilite-cvtheque #cvtheque-video p {
      color: #fff; }
    .section-visibilite-cvtheque #cvtheque-video b {
      font-weight: 700; }
    .section-visibilite-cvtheque #cvtheque-video .col-sm-1 {
      width: 6.5%; }
    .section-visibilite-cvtheque #cvtheque-video hr.separator {
      border-color: #16354E; }
  .section-visibilite-cvtheque #cvtheque-how-to {
    background-color: #16354E; }
    .section-visibilite-cvtheque #cvtheque-how-to h2, .section-visibilite-cvtheque #cvtheque-how-to p {
      color: #fff; }
    .section-visibilite-cvtheque #cvtheque-how-to b {
      font-weight: 700; }
    .section-visibilite-cvtheque #cvtheque-how-to .col-sm-1 {
      width: 6.5%; }
    .section-visibilite-cvtheque #cvtheque-how-to hr.separator {
      border-color: #ffffff; }
    .section-visibilite-cvtheque #cvtheque-how-to .btn-info-light {
      border-radius: 0;
      line-height: initial; }
  .section-visibilite-cvtheque #cvtheque-advice {
    background-color: #FFFFFF; }
  .section-visibilite-cvtheque .punchline {
    width: 50%;
    float: right;
    text-align: center;
    margin: 4% 5% 0 0; }
  .section-visibilite-cvtheque #cvtheque-mj .punchline h1 {
    font-size: 2.6em;
    font-weight: 300;
    color: #16354E;
    text-transform: uppercase;
    text-align: left;
    padding: 20px 0;
    border-top: 1px solid #16354E;
    border-bottom: 1px solid #16354E; }
  .section-visibilite-cvtheque .punchline p {
    font-size: 1.4em;
    color: #16354E; }
  .section-visibilite-cvtheque .punchline a {
    color: #ffffff; }
  .section-visibilite-cvtheque #search-form {
    background-color: #16354E; }
    .section-visibilite-cvtheque #search-form .search-title {
      display: block;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 34px; }
    .section-visibilite-cvtheque #search-form .row {
      margin: 0; }

/*********************************************************/
/*                CV Upload                              */
/*********************************************************/
.section-visibilite-cvtheque .title,
.section-visibilite-cvtheque #ads-banner {
  display: none !important; }

.section-visibilite-cvtheque .region-content {
  margin: 0 auto; }

.section-visibilite-cvtheque #main-wrapper, .section-visibilite-cvtheque #content, .section-visibilite-cvtheque .region-content, #cvtheque-mj #cvtheque-header, #cvtheque-mj #logos, #cvtheque-mj #main-advantages {
  width: 100%; }

#cvtheque-mj {
  background: #fff; }

body.section-visibilite-cvtheque.mj-role-company {
  padding-top: 0; }

.section-visibilite-cvtheque.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px; }

#cvtheque-mj #cvtheque-header .punchline {
  padding-top: 100px;
  padding-left: 100px; }

#cvtheque-mj #cvtheque-header h2 {
  color: #16354E; }

#cvtheque-mj #cvtheque-header hr.separator {
  border-color: #00B5DD; }

#cvtheque-mj #cvtheque-header .btn-primary {
  width: 100%;
  font-weight: 600;
  padding: 15px;
  background-color: #00B5DD;
  border-color: #00B5DD;
  white-space: normal; }

#cvtheque-mj #cvtheque-header .btn-primary:hover {
  background-color: #16354E; }

.section-visibilite-cvtheque #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/cvtheque/bg-header-cvtheque.png) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color: #D7E5F4;
  min-height: 580px; }

@media (max-width: 992px) {
  /************ Landing page Offres emploi *************/
  .section-visibilite-cvtheque #mj-popin-wrapper #mj-popin {
    /*background-position: 15% 0!important;
      padding-bottom:0;*/ }
  .section-visibilite-cvtheque #mj-popin-wrapper.no-popin #mj-popin {
    min-height: auto; }
  .section-visibilite-cvtheque #search-form .col-md-3.form-group {
    padding: 0; } }

@media (max-width: 767px) {
  .section-visibilite-cvtheque #mj-popin-wrapper #mj-popin {
    padding: 120px 0 50px 0 !important;
    background-position: 0 !important; }
  .section-visibilite-cvtheque #mj-popin-wrapper.no-popin #mj-popin {
    padding-top: 0;
    min-height: auto; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-header .btn-primary {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-header .punchline {
    text-align: center;
    padding: 10px 25px;
    margin: 0 auto;
    float: none;
    width: auto; }
    .section-visibilite-cvtheque #cvtheque-mj #cvtheque-header .punchline h1, .section-visibilite-cvtheque #cvtheque-mj #cvtheque-header .punchline h2 {
      color: #FFFFFF;
      font-size: 1.8em;
      text-align: center;
      text-shadow: 2px 2px 10px black; }
    .section-visibilite-cvtheque #cvtheque-mj #cvtheque-header .punchline p {
      text-shadow: 2px 2px 10px black;
      color: #FFFFFF; }
  .section-visibilite-cvtheque #cvtheque-mj .float-lt,
  .section-visibilite-cvtheque #cvtheque-mj .float-rt {
    float: none;
    text-align: center; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advantages .pos-lt,
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advice .pos-lt {
    left: initial; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advantages .pos-rt,
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advice .pos-rt {
    right: initial; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advantages .padd-40-lt,
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advice .padd-40-lt {
    padding-left: 10px;
    border: none; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advantages .padd-40-rt,
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advice .padd-40-rt {
    padding-right: 10px;
    border: none; }
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advantages .timeline-container,
  .section-visibilite-cvtheque #cvtheque-mj #cvtheque-advice .timeline-container {
    padding-top: 40px; } }

@media (max-width: 767px) {
  .section-visibilite-cvtheque .no-padd-lt, .section-visibilite-cvtheque .no-padd-rt {
    padding: 0; }
  .section-visibilite-cvtheque .float-lt, .section-visibilite-cvtheque .float-rt {
    float: none;
    text-align: center; } }

body.node-type-fiche-entreprise h1#page-title {
  display: none; }

body.node-type-fiche-entreprise .view-fiches-entreprises-index a.active {
  text-decoration: underline; }

body.node-type-fiche-entreprise .well-header {
  margin-bottom: 20px; }
  body.node-type-fiche-entreprise .well-header h1 {
    height: 90px;
    color: #16354E;
    text-transform: none;
    font-weight: 600;
    font-size: 2em; }
    body.node-type-fiche-entreprise .well-header h1::after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
    body.node-type-fiche-entreprise .well-header h1 span {
      display: inline-block;
      vertical-align: middle; }
  body.node-type-fiche-entreprise .well-header h1,
  body.node-type-fiche-entreprise .well-header .thumbnail {
    margin-bottom: 0; }

body.node-type-fiche-entreprise .node-fiche-entreprise *[style] {
  font-family: "DM Sans", Arial, sans-serif !important; }

body.node-type-fiche-entreprise .node-fiche-entreprise ul {
  padding-left: 0;
  list-style: none; }

body.node-type-fiche-entreprise .node-fiche-entreprise .thumbnail {
  display: inline-block;
  background-color: #fff; }

body.node-type-fiche-entreprise .node-fiche-entreprise .company-logo {
  text-align: right; }

body.node-type-fiche-entreprise .node-fiche-entreprise .mj-title-separator {
  margin-bottom: 60px !important; }

body.node-type-fiche-entreprise .node-fiche-entreprise .companyBlock {
  margin-bottom: 20px; }
  body.node-type-fiche-entreprise .node-fiche-entreprise .companyBlock:before, body.node-type-fiche-entreprise .node-fiche-entreprise .companyBlock:after {
    content: " ";
    display: table; }
  body.node-type-fiche-entreprise .node-fiche-entreprise .companyBlock:after {
    clear: both; }
  body.node-type-fiche-entreprise .node-fiche-entreprise .companyBlock .btn-primary {
    float: right; }

body.node-type-fiche-entreprise .node-fiche-entreprise .offerBlock a {
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase; }

body.node-type-fiche-entreprise .node-fiche-entreprise .offerBlock .offerLocation {
  font-weight: 600; }

body.node-type-fiche-entreprise .node-fiche-entreprise #companyDescFiches ul {
  margin-bottom: 0; }

body.node-type-fiche-entreprise .node-fiche-entreprise #companyDescFiches a {
  color: inherit; }

.section-fiches-metiers a.link-ninja.active {
  text-decoration: underline; }

.section-fiches-metiers .mj-column-left .region-sidebar-first .mj-block li a:active, .section-fiches-metiers .mj-column-left .region-sidebar-first .mj-block li a:hover {
  text-decoration: underline; }

.section-fiches-metiers .last-offers h2 {
  padding: 0 0 10px;
  border-bottom: 1px solid #16354E; }

.section-fiches-metiers .region-content {
  text-align: justify; }
  .section-fiches-metiers .region-content img {
    padding-bottom: 5px; }

.section-fiches-metiers .region-content-bottom .btn {
  float: right; }

.section-fiches-metiers #mj-offer-list {
  padding-bottom: 20px; }
  .section-fiches-metiers #mj-offer-list .row {
    padding: 5px 0;
    border-bottom: 2px dotted #B9C2CA;
    margin: 0; }
  .section-fiches-metiers #mj-offer-list .col-sm-5 > a {
    text-transform: uppercase;
    font-weight: bold;
    color: #16354E; }
  .section-fiches-metiers #mj-offer-list img {
    max-height: 30px; }
  .section-fiches-metiers #mj-offer-list .row [class*=col] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    padding-left: 0; }

.section-fiches-metiers a[data-toggle="collapse"] {
  color: #16354E; }

/* ----------------------------- */
/* == UI-kit variables           */
/* ----------------------------- */
/**
  * Bootstrap variables overrides
  *
  * This files imitate the structure from the bootstrap variables file (https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss)
  * To be loaded BEFORE bootstrap variables
  * Every variables here MUST have "!default" at the end of declaration
// --------------------------------------------------------------------
**/
/** Default color for bootstrap overridden by client. */
/**
 * Custom variables specific to our company
 *
 * Load this AFTER bootstrap + override variables
 * Every variables must be followed by "!default"
 * -----------------------------------------
*/
/** OLD MISC */
.tar {
  text-align: right; }

/* Override cms-styles.css */
.region-sidebar-first .block .item-list ul li {
  margin-left: 0; }

.region-sidebar-first .views-summary li a,
.view-empty .views-summary li a {
  font-weight: bold; }

.view-empty .views-summary li {
  margin-left: 0; }

.region-sidebar-first .form-text,
.region-sidebar-first .form-autocomplete {
  width: 165px; }

.region-sidebar-first .form-select {
  width: 170px; }

.region-sidebar-first .views-exposed-form label {
  color: #16354E; }

.section-emploi .region-sidebar-first .block,
.section-guide-emploi-recruteur .region-sidebar-first .block {
  background-color: #fdf4ef; }

/** Titles */
.page-guide-emploi-recruteur h1#page-title {
  display: none; }

/* Font size */
.node-guide-emploi-recruteur,
.node-carriere,
.node-actualites,
.view-actualites-emplois #contentContainer .views-field-body,
.view-actualites-archives #contentContainer .views-field-body {
  font-size: 1rem !important;
  text-align: justify; }

.node-actualites,
.view-actualites-emplois #contentContainer .views-field-body,
.view-actualites-archives #contentContainer .views-field-body {
  text-align: left; }

/* Views */
.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0; }

/*
.region-sidebar-first .views-exposed-form .views-submit-button {
width:180px;
}
*/
/* Views pager */
.view .pager {
  margin-top: 20px; }

.view .pager li {
  margin-left: 0; }

/* Views calendar */
.view .date-nav-wrapper {
  color: #fff;
  background: #00bcf2; }

.view .date-nav-wrapper .date-nav {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 0; }

.view .date-nav-wrapper .date-heading h3,
.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
  line-height: 30px; }

.view .date-nav-wrapper .date-heading,
.view .date-nav-wrapper .date-heading a {
  color: #fff; }

.view .date-nav-wrapper .pager,
.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
  margin: 0;
  padding: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: none; }

.region-sidebar-first .block .item-list ul.pager li {
  margin-left: 0; }

.calendar-calendar .month-view table,
.calendar-calendar table.mini td,
.calendar-calendar table.mini td.empty {
  border: 1px solid #ccc; }

.calendar-calendar thead th.days {
  color: #242424;
  background: #EEF7FC;
  border: 0 none; }

.calendar-calendar .mini td.sat,
.calendar-calendar .mini td.sun {
  color: #FFBD29;
  background: #FFEBDA; }

.calendar-calendar .mini td div {
  font-size: 0.6875rem;
  line-height: 24px;
  text-align: center; }

.calendar-calendar .mini td div.calendar-empty {
  display: none; }

.calendar-calendar .mini td.has-events a {
  font-weight: bold; }

.calendar-calendar tr td.today,
.calendar-calendar tr.odd td.today,
.calendar-calendar tr.even td.today {
  color: #fff;
  background: #16354E; }

/* View "Taxonomie links" */
.view-taxonomie-links .view-header,
.view-taxonomie-links .view-content,
.view-taxonomie-links .views-row {
  float: left; }

.view-taxonomie-links .view-header,
.view-taxonomie-links.view-display-id-show_offers_block .views-row {
  font-weight: bold; }

.view-taxonomie-links.view-display-id-actu_url_externe .views-row {
  font-size: 0.6875rem; }

.view-taxonomie-links .views-row {
  margin-right: 5px; }

.view-taxonomie-links .views-row .field-content::after {
  content: ","; }

.view-taxonomie-links .views-row:last-child .field-content::after {
  content: "."; }

.view-taxonomie-links .views-row a {
  color: #16354E; }

/**/
#boutoninscription {
  text-align: right;
  margin: 0px 0px 10px 0px;
  background-color: #fcfcfc;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px; }

#boutoninscription h3 {
  float: left;
  margin: 0 0 10px 0;
  font-size: 1.2em; }

#boutoninscription p {
  float: left;
  margin-bottom: 3px;
  clear: both; }

.section-carriere #boutoninscription {
  margin: 0 10px; }

.section-carriere .bottom-content-bloc {
  padding: 10px 5px 5px 5px;
  border: 1px solid #ccc; }

.section-carriere .bottom-content-bloc .linkColWrapper {
  width: 25%; }

.articleContent h2 {
  font-weight: bold;
  color: #16354E; }

.indexentretien {
  color: #16354E;
  background: url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px;
  padding-left: 15px; }

.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title,
.node-type-actualites #contentContainer .title {
  border-bottom: 3px solid #cbcbcb; }

.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title {
  margin: 10px !important; }

.node-type-guide-emploi-recruteur .title {
  color: #de5e1e; }

#container #contenu h2 {
  padding-right: 180px;
  float: left; }

#container #contenu {
  margin-top: 10px; }

.articleContent .itemInfoHome {
  text-align: justify;
  padding-bottom: 20px; }

.articleContent .customMargin {
  margin: 10px 0px 10px 10px; }

.articleContent .homePicture_customMargin {
  margin: 10px 0px 10px 25px; }

.horizontalPictureLeft {
  float: left;
  clear: both;
  margin: 0 10px 10px 0; }

.horizontalPictureRight {
  float: right;
  clear: both;
  margin: 0 0 10px 10px; }

.picWithLegend {
  float: left; }

.picWithLegend blockquote {
  margin-left: 2px;
  clear: both; }

.articleContent .small,
.articleContent .medium,
.articleContent .big,
.articleContent .bigger {
  color: #16354E;
  text-decoration: none; }

.articleContent .small {
  font-size: 0.75rem; }

.articleContent .big {
  font-size: 1.125rem; }

.articleContent .bigger {
  font-size: 1.375rem; }

.articleContent .small:hover,
.articleContent .medium:hover,
.articleContent .big:hover,
.articleContent .bigger:hover {
  text-decoration: underline; }

.node-type-guide-emploi-recruteur .articleContent .arrow {
  font-size: inherit !important;
  color: inherit !important; }

.node-actualies .arrow {
  font-size: inherit !important;
  color: inherit !important; }

.node-actualites #contentContainer .content h3,
.articleContent .arrow {
  margin-top: 15px;
  font-size: inherit !important;
  color: inherit !important; }

.articleContent .contentcabine .arrow {
  float: left;
  clear: both; }

.articleContent .arrow a {
  color: inherit !important; }

.articleContent .arrow a:hover {
  text-decoration: none; }

.articleContent .arrowlienpremier {
  color: inherit !important;
  font-size: inherit !important;
  margin-top: 20px; }

.articleContent .arrowautre {
  margin-top: 25px;
  color: inherit !important;
  font-size: inherit !important; }

.articleContent .p1 {
  font-weight: bold; }

.articleContent .p3 {
  margin-top: 20px; }

.articleContent .p2 {
  font-weight: bold; }

.articleContent .paragraphe3 {
  margin-top: 5px; }

.articleContent h2 {
  color: #3984db; }

.view-actualites-emplois #contentContainer .views-field-path,
.view-actualites-archives #contentContainer .views-field-path {
  margin-top: 15px; }

.front .view-actualites-emplois #contentContainer .views-field-path {
  margin-top: 0; }

.node-actualites .content ul {
  list-style: initial; }

.articleContent .contentcabine p {
  float: left; }

.articleContent p a {
  text-decoration: none;
  color: #16354E; }

.articleContent p a:hover {
  text-decoration: underline; }

.articleContent .horizontalPictureLefts {
  margin-top: 0px;
  float: left;
  clear: both;
  padding-right: 10px; }

.articleContent .verticalPictureLeft {
  margin-top: 0px;
  float: left;
  clear: both;
  padding-right: 10px; }

.articleContent ul {
  list-style-type: none;
  width: 600px;
  clear: both;
  float: left; }

.articleContent ul li {
  display: inline; }

.articleContent ul li a {
  color: inherit !important; }

.articleContent #arrown {
  list-style-type: none;
  float: none;
  clear: none; }

.articleContent #arrown li {
  margin-top: 5px;
  padding-top: 5px;
  display: block; }

.articleContent .paragraphe2 {
  float: left;
  margin-top: 10px; }

.articleContent .horizontalPicture {
  float: left;
  clear: both;
  margin-bottom: 10px; }

.articleContent .paragraphe1 {
  float: left;
  width: 400px !important;
  margin: 5px 0px 0px 10px; }

.articleContent .paragrapheautre {
  float: left;
  width: 400px !important;
  margin: 20px 0px 10px 0px; }

.articleContent .paragrapheautres {
  margin: 20px 0px 10px 0px; }

#bullet {
  float: left;
  margin-top: 5px;
  display: inline-block;
  margin-left: 160px; }

.articleContent .asavoir2 {
  clear: both; }

.articleContent h3 {
  color: #297cce; }

.articleContent ol {
  margin: 10px 0px 10px 20px; }

.articleContent .middleImage {
  margin: 10px 0px 10px 100px; }

.articleContent .PortageSalarial,
.articleContent .portageSalarial {
  list-style: disc; }

.articleContent .PortageSalarial {
  margin-top: 5px; }

.articleContent .portageSalarial li {
  display: list-item;
  margin: 5px 0 5px 20px;
  list-style-type: disc; }

.page-evenements .views-field-body {
  float: left;
  width: 430px;
  text-align: justify;
  margin-bottom: 10px; }

.views-field-field-name .field-content {
  margin-bottom: 10px; }

.node-type-actualites .region-content .block {
  margin-bottom: .8em; }

.page-emploi-actualites #contentContainer .views-field-path {
  text-align: right; }

.page-emploi-actualites #contentContainer .views-field-created {
  color: #666; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-created {
  color: #16354E; }

.page-emploi-actualites #contentContainer .views-field-body .field-content,
.page-emploi-actualites #contentContainer .views-field-body p {
  text-align: justify !important; }

.page-emploi-actualites #contentContainer .views-field-body p,
.page-emploi-actualites #contentContainer .views-field-body em,
.page-emploi-actualites #contentContainer .views-field-body span,
.page-emploi-actualites #contentContainer .views-field-body strong {
  padding: 0 !important;
  color: inherit !important;
  font-size: inherit !important;
  font-weight: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  text-decoration: none !important; }

.page-evenements .views-field-title,
.page-emploi-actualites #contentContainer .view-content .views-field-title a,
.page-emploi-actualites #contentContainer .view-content .views-field-title-1 a,
.views-field-field-name .field-content,
.views-field-field-name .field-content a {
  font-size: 1.125rem;
  text-decoration: none;
  color: inherit !important;
  text-transform: uppercase;
  font-weight: bold; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-title a {
  color: #16354E;
  text-transform: none; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart .views-field-title a:hover {
  text-decoration: underline; }

.page-emploi-actualites-browse #contentContainer h2 a,
.page-emploi-actualites-archives #contentContainer h2 a,
.page-emploi-actualites-term #contentContainer h2 a {
  font-weight: normal; }

.node-type-actualites .region-sidebar-second .view-gp-jobs .views-row {
  padding-bottom: 5px; }

.list-icon li > *:first-child, .links-list li > *:first-child {
  padding-right: 5px; }

li .fa-chevron-right {
  margin-right: 5px;
  color: #16354E; }

.list-icon li > *, .links-list li > * {
  display: table-cell; }

.view-display-id-page h3 {
  font-size: 1.375rem; }

.view-display-id-page h2 {
  margin-bottom: 10px; }

.view-display-id-page h2 a {
  font-size: 1.125rem;
  text-decoration: none;
  color: #16354E; }

.view-evenements .view-empty {
  margin-top: 10px;
  font-size: 1rem; }

.view-evenements .views-row {
  margin: 15px 0px; }

.view-evenements .views-field-field-date-period .field-content:first-letter {
  text-transform: uppercase; }

.view-evenements .view-empty .view-display-id-bloc_a_venir h2 {
  margin-top: 20px;
  font-size: 1rem; }

/* View "Carriere" */
.recruteurHeader {
  width: 605px;
  margin-left: 5px;
  border: 1px solid #eaeaea; }

.two-sidebars.page-emploi-actualites #content,
.two-sidebars.page-guide-emploi-recruteur #content,
.two-sidebars.page-carriere #content {
  width: 605px;
  margin-right: 10px;
  margin-left: 10px; }

.two-sidebars.page-evenements #contentContainer {
  padding: 10px; }

.two-sidebars.page-evenements #contentContainer,
.two-sidebars.page-evenements .region-content-bottom,
.two-sidebars.page-evenements .region-extra-content,
.two-sidebars.page-emploi-actualites #contentContainer,
.two-sidebars.page-emploi-actualites .region-content-bottom,
.two-sidebars.page-emploi-actualites .region-extra-content,
.two-sidebars.page-guide-emploi-recruteur #contentContainer,
.two-sidebars.page-guide-emploi-recruteur .region-content-bottom,
.two-sidebars.page-guide-emploi-recruteur .region-extra-content,
.two-sidebars.page-carriere #contentContainer,
.two-sidebars.page-carriere .region-content-bottom,
.two-sidebars.page-carriere .region-extra-content {
  width: auto;
  display: block; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete,
.view-display-id-page .view-display-id-block_derniere_actu .entete,
.view-carriere .entete {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/carriere.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 210px; }

.entete {
  height: 210px;
  margin: 0px; }

.view-carriere .field-label-hidden h2, .view-guide-emploi-recruteur .field-label-hidden h2 {
  display: none; }

.view-carriere .views-field.views-field-title, .view-guide-emploi-recruteur .views-field.views-field-title {
  border-bottom: 3px solid #cbcbcb;
  margin-bottom: 5px; }

.view-carriere .views-field.views-field-title a, .view-guide-emploi-recruteur .views-field.views-field-title a {
  text-decoration: none; }

.view-guide-emploi-recruteur .views-field.views-field-title a {
  color: #de5e1e; }

.view-carriere .views-field.views-field-title a {
  color: #16354E; }

.view-carriere .views-field.views-field-title a, .view-guide-emploi-recruteur .views-field.views-field-title a {
  font-size: 1rem;
  font-weight: bold; }

.view-carriere .field-label-hidden h3, .view-guide-emploi-recruteur .field-label-hidden h3 {
  display: none; }

.view-carriere .field-label-hidden .importantBtn {
  display: none; }

.view-carriere .field-label-hidden .horizontalPictureLeft, .view-guide-emploi-recruteur .field-label-hidden .horizontalPictureLeft {
  display: none; }

.view-carriere .field-label-hidden .horizontalPicture {
  display: none; }

.view-carriere .field-label-hidden .articleContent .arrowlienpremier, .view-guide-emploi-recruteur .field-label-hidden .articleContentrecruteur .arrowlienpremier {
  margin-top: 0px; }

.view-carriere .views-field.views-field-body, .view-guide-emploi-recruteur .views-field.views-field-body {
  width: auto;
  margin-bottom: 10px;
  text-align: justify; }

.view-carriere .field-label-hidden .articleContent .asavoir1 {
  margin-top: 0px; }

.view-carriere .field-label-hidden .articleContent .paragraphe1 {
  width: 280px !important;
  margin-left: 0px !important; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .paragraphe-entete,
.page-evenements .entete .paragraphe-entete,
.view-display-id-block_derniere_actu .paragraphe-entete,
.view-carriere .entete .paragraphe-entete {
  width: 290px;
  height: 210px; }

.view-carriere .entete .paragraphe-entete .paragraphe-entete-second {
  float: left;
  width: 270px;
  color: #121212;
  font-weight: bold;
  text-align: left;
  margin: 0px 0px 0px 20px; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete .btnLarge,
.view-display-id-block_derniere_actu .btnLarge,
.view-carriere .entete .paragraphe-entete .btnLarge {
  margin-left: 10px; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete p,
.view-display-id-block_derniere_actu .entete .paragraphe-entete p,
.view-carriere .entete .paragraphe-entete p,
.view-guide-emploi-recruteur .entete .paragraphe-entete p {
  float: left;
  width: 280px;
  color: #16354E;
  margin: 20px 0px 40px 20px;
  font-size: 1.5625rem;
  font-weight: bold;
  text-align: left;
  line-height: 27px; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .entete .paragraphe-entete ul,
.view-display-id-block_derniere_actu .entete .paragraphe-entete ul,
.view-carriere .entete .paragraphe-entete ul,
.view-guide-emploi-recruteur .entete .paragraphe-entete ul {
  margin-left: 0; }

.page-carriere .view-bloc-de-contenu-consol {
  background-color: #fcfcfc; }

.view-carriere .node-carriere {
  margin-left: 0px; }

.col-1.carriererow {
  padding-right: 11px; }

.views-view-grid {
  width: 100%; }

.view-carriere .views-view-grid td,
.view-guide-emploi-recruteur .views-view-grid td {
  vertical-align: top;
  padding-bottom: 10px; }

.view-carriere .view-header p, .view-guide-emploi-recruteur .view-header p {
  text-align: justify;
  margin-right: 10px; }

.view-carriere .view-display-id-page .view-header {
  margin-bottom: 0px; }

.field-name-field-indexcarriere, .field-name-field-indexrecruteur {
  display: none; }

.views-view-grid.cols-1 {
  width: 98%; }

.page-carriere #contentContainer, .page-guide-emploi-recruteur #contentContainer {
  border: none; }

/* fin view carriere */
/* view guide-emploi-recruteur */
.two-sidebars.page-guide-emploi-recruteur .region-content-bottom .block-block {
  width: 100%;
  margin: -1px !important; }

.blocFooterRecruteur {
  background-color: #fdf4ef;
  border: 1px solid #eaeaea; }

.blocFooterRecruteur h5 {
  margin: 0;
  padding: 15px 15px 0px 15px;
  color: #DE5E1E; }

.blocFooterRecruteur p {
  margin: 5px 5px 5px 15px;
  padding: 0; }

.blocFooterRecruteur p:last-child {
  padding: 0px 0px 15px 0px; }

.section-qui-sommes-nous .blocFooterRecruteur {
  margin: 0 5px; }

.view-guide-emploi-recruteur .entete .paragraphe-entete p {
  color: #de5e1e; }

.page-carriere .view-carriere .view-content,
.page-guide-emploi-recruteur .view-guide-emploi-recruteur .view-content {
  margin-top: 15px; }

.page-guide-emploi-recruteur .entete {
  border: none; }

.rubanlogo {
  background: #f5f5f5 !important;
  height: 39px;
  margin: 0px;
  border-top: 1px solid #eaeaea; }

#presCompHeadLink .minorBtn {
  margin-top: 30px; }

#presCompHeadLink {
  border: none !important;
  margin: 0px !important; }

.rubanlogo .rubanlogo-sociaux ul,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul {
  text-align: center; }

.rubanlogo .rubanlogo-sociaux ul {
  margin: 0px; }

.rubanlogo .rubanlogo-sociaux ul li:first-child,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul li:first-child {
  vertical-align: middle; }

.rubanlogo .rubanlogo-sociaux ul li,
.view-guide-emploi-recruteur .rubanlogo .rubanlogo-sociaux ul li {
  display: inline;
  height: 40px; }

.view-guide-emploi-recruteur .entete {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/guide-emploi-recruteur.jpg");
  background-repeat: no-repeat;
  background-position: right;
  height: 210px; }

.entete ul {
  padding-left: 0;
  list-style: none; }

.contactBox {
  margin: 5px 0 0;
  padding: 5px 10px;
  background: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #ccc;
  border-radius: 4px; }

.view-guide-emploi-recruteur .views-field.views-field-path, .view-carriere .views-field.views-field-path {
  margin-bottom: 10px; }

.page-emploi-actualites .node-type-actualites .links.inline .node-readmore a,
.view-guide-emploi-recruteur .views-field.views-field-path a,
.view-carriere .views-field.views-field-nothing a,
.page-guide-emploi-recruteur .entete .paragraphe-entete ul li a {
  background: url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px transparent;
  padding-left: 10px;
  font-weight: bold;
  color: #16354E; }

.col-1.recruteurrow {
  padding-right: 11px; }

.view-guide-emploi-recruteur .articleContent .itemInfoHome {
  font-weight: normal; }

.view-guide-emploi-recruteur .node-guide-emploi-recruteur {
  margin-left: 0px; }

.page-guide-emploi-recruteur .entete .minorActionBtn span {
  color: #121212;
  font-weight: normal; }

.page-guide-emploi-recruteur .entete .minorActionBtn span:hover {
  text-decoration: none; }

.page-guide-emploi-recruteur .entete .minorActionBtn span a {
  color: #16354E;
  font-weight: bold;
  text-decoration: underline; }

.page-guide-emploi-recruteur .entete .paragraphe-entete {
  width: 300px; }

.page-guide-emploi-recruteur .entete .paragraphe-entete ul li {
  margin: 5px 0px 0px 20px; }

/* fin view guide-emploi-recruteur */
.views-label-field-generalistes {
  display: none; }

.views-field-field-logo {
  position: relative;
  width: 149px;
  height: 87px;
  border: 1px solid #ccc;
  padding: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: #ccc 0px 0px 3px;
  -moz-box-shadow: #ccc 0px 0px 3px;
  box-shadow: #ccc 0px 0px 3px;
  background: #FFF;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#fff));
  background: -webkit-linear-gradient(#fff, #fff);
  background: -moz-linear-gradient(#fff, #fff);
  background: -ms-linear-gradient(#fff, #fff);
  background: -o-linear-gradient(#fff, #fff);
  background: linear-gradient(#fff, #fff);
  -pie-background: linear-gradient(#fff, #fff);
  behavior: url(/css/PIE.htc);
  margin: 10px;
  line-height: 110px; }

.view-evenements .view-display-id-bloc_a_venir .view-header h3 {
  margin-top: 15px;
  color: inherit !important;
  background: url("/images/v6/console/trait-sous-titre.png") no-repeat scroll 0pt 100% transparent;
  height: 40px;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 1.125rem; }

.menu.jquerymenu.jquerymenu-processed {
  padding-left: 0; }

.jquerymenu .parent .closed span {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblack_OFF.png") no-repeat scroll 95% 50% transparent !important;
  padding-top: 5px !important;
  width: 10px !important;
  height: 4px !important;
  margin-top: 2px !important;
  margin-left: -15px !important; }

.jquerymenu .parent .open span {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblack_ON.png") no-repeat scroll 95% 50% transparent !important;
  padding-top: 5px !important;
  width: 10px !important;
  height: 4px !important;
  margin-top: 2px !important;
  margin-left: -15px !important; }

.jquerymenu .parent li {
  margin-top: 7px;
  margin-bottom: 5px; }

ul.jquerymenu ul {
  padding-left: 0;
  list-style: none; }

ul.jquerymenu li.parent {
  padding-left: 5px;
  margin-top: 7px;
  margin-bottom: 5px;
  padding-right: 5px; }

ul.jquerymenu li.parent span.closed {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblue_OFF.png") no-repeat scroll 95% 50% transparent;
  width: 12px;
  height: 12px;
  margin-left: -20px;
  margin-top: 3px; }

.view-display-id-dico_guide_emploi_recruteur ul.jquerymenu li.parent span.closed,
#block-jquerymenu-2 ul.jquerymenu li.parent span.closed {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblue_OFFrecruteur.png") no-repeat scroll 95% 50% transparent; }

.view-display-id-dico_guide_emploi_recruteur ul.jquerymenu li.parent span.open,
#block-jquerymenu-2 ul.jquerymenu li.parent span.open {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblue_ONrecruteur.png") no-repeat scroll 95% 50% transparent; }

ul.jquerymenu li.parent span.open {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/menu-arrowblue_ON.png") no-repeat scroll 95% 50% transparent;
  width: 12px;
  height: 12px;
  margin-left: -17px;
  margin-top: 3px; }

.jquerymenu a,
.jquerymenu a.active {
  /* top parent */
  color: #16354E;
  font-weight: bold;
  text-transform: uppercase; }

.view-display-id-block_date .archives h3,
.node-type-guide-emploi-recruteur .content .view-display-id-dico_guide_emploi_recruteur .view-header ul li:first-child a,
.page-guide-emploi-recruteur .content .view-display-id-dico_guide_emploi_recruteur .view-header ul li:first-child a,
#block-jquerymenu-2 .jquerymenu a,
#block-jquerymenu-2 .jquerymenu a.active {
  text-transform: uppercase !important; }

.view-display-id-dico_guide_emploi_recruteur .jquerymenu a,
.view-display-id-dico_guide_emploi_recruteur .jquerymenu a.active,
#block-jquerymenu-2 .jquerymenu a,
#block-jquerymenu-2 .jquerymenu a.active {
  /* top parent */
  color: #de5e1e;
  text-transform: none; }

#block-jquerymenu-2 .jquerymenu ul a,
#block-jquerymenu-2 .jquerymenu ul a.active {
  color: #242424;
  text-transform: none !important; }

.jquerymenu ul a,
.jquerymenu ul a.active {
  /* 1st level */
  color: #242424;
  text-transform: none; }

.jquerymenu ul ul a,
.jquerymenu ul ul a.active {
  /* 2nd level */
  font-weight: normal; }

.section-carriere .region-sidebar-first ul,
.section-guide-emploi-recruteur .region-sidebar-first ul {
  margin-left: 0; }

.page-carriere .view-display-id-block_carriere, .node-type-guide-emploi-recruteur .view-display-id-dico_guide_emploi_recruteur,
.node-type-carriere .view-display-id-block_carriere, .page-guide-emploi-recruteur .view-display-id-dico_guide_emploi_recruteur {
  margin: 0px; }

.view-id-dictionnaire_emploi_vue,
.node-guide-emploi-recruteur, .node-carriere {
  margin: 10px; }

.node-guide-emploi-recruteur .articleContentrecruteur a, .node-carriere .articleContent a {
  color: #16354E; }

.views-field-description {
  padding-left: 10px; }

.view-dictionnaire-emploi-vue .views-field-name {
  font-size: 0.6875rem;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 5px; }

.page-carriere-dictionnaire-emploi #contentContainer, .page-guide-emploi-recruteur-dictionnaire-emploi #contentContainer {
  border: 1px solid #eaeaea; }

.view-dictionnaire-emploi-vue .articleContent h2 {
  font-size: 1.125rem;
  text-transform: none !important; }

.view-display-id-block_carriere .item-list ul li, .view-display-id-dico_guide_emploi_recruteur .item-list ul li {
  margin-top: 7px !important;
  padding-left: 7px; }

.view-display-id-dico_guide_emploi_recruteur .views-row a, .view-display-id-block_carriere .views-row a {
  color: #242424;
  font-weight: bold;
  padding-top: 5px; }

.view-display-id-block_carriere {
  background-color: #fcfcfc; }

.view-id-dictionnaire_emploi_vue h1 {
  border-bottom: 3px solid #cbcbcb; }

.page-emploi-actualites-actualite .node-actualites .field-items .field-item.even img,
.node-type-actualites .node-actualites .field-type-image {
  float: left;
  padding-right: 10px; }

/* PUSH application mobile */
.section-toutes-les-offres-meteojob-sur-votre-mobile .title {
  padding-bottom: 5px;
  border-bottom: 3px solid #cbcbcb;
  font-size: 1.5rem;
  margin: 10px 0px 0px 0px !important; }

.section-toutes-les-offres-meteojob-sur-votre-mobile .region-extra-content {
  margin: 10px 5px 0px;
  display: block;
  width: auto; }

.section-toutes-les-offres-meteojob-sur-votre-mobile #contentContainer {
  border: 1px solid #eaeaea;
  margin: 0px 5px 10px 5px;
  width: auto;
  padding: 0px 20px; }

.pageMobileContent {
  background: url("/sites/cms.meteojob.com/themes/meteojob/assets/images/_/iphone.png") no-repeat;
  background-position: 100% 100%; }

.pageMobileContent .paragrapheMobileContent p:first-child {
  font-size: 0.875rem;
  margin: 20px 0px;
  font-weight: bold; }

/*.pageMobileContent .paragrapheMobileContent ul li {*/
/*background:url("/images/v6/generic/sprite-list.png") no-repeat scroll -148px 0px transparent;*/
/*padding:3px 0px 0px 40px;*/
/*margin-top:30px;*/
/*}*/
.pageMobileContent .paragrapheMobileContent ul li:last-child {
  margin-bottom: 40px; }

.pageMobileContent .paragrapheMobileContent ul li p {
  text-align: justify;
  width: 280px; }

.pageMobileContent .btn {
  margin: 0 0 60px; }

.pageMobileContent .btn span {
  /*font-size:12px;*/
  /*line-height:16px;*/
  /*padding-top:10px;*/ }

.pageMobileContent .btn span em {
  font-style: normal;
  text-transform: none; }

.pageMobileContent .paragrapheMobileContent ul li h3,
.pageMobileContent .paragrapheMobileContent ul li .mj-icon-li {
  color: inherit !important; }

.pageMobileContent .paragrapheMobileContent ul li h3 {
  line-height: 2em; }

/* Vue actualités */
.archives h3 {
  cursor: pointer;
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  padding: 6px 0; }

.archives ul {
  padding: 0 0 0 10px;
  list-style: none; }

.archives ul li {
  background: none;
  line-height: 130%;
  padding: 3px 0 5px; }

.page-emploi-actualites #contentContainer .view-display-id-block_date h3,
.node-type-actualites #contentContainer .view-display-id-block_date h3 {
  font-weight: bold; }

.page-emploi-actualites #contentContainer .view-display-id-block_date h3,
.node-type-actualites #contentContainer .view-display-id-block_date h3 {
  padding-left: 5px; }

.page-emploi-actualites #contentContainer .view-derniere-emission {
  margin: 10px 0px;
  border: 1px solid #eaeaea;
  width: 280px;
  float: right; }

.page-emploi-actualites #contentContainer .views-field-title a {
  text-decoration: none; }

.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-actualites_emplois .view-display-id-block_encart {
  float: left;
  width: 310px;
  min-height: 176px;
  margin: 10px 0px;
  border: 1px solid #eaeaea; }

.page-emploi-actualites #contentContainer .block-derniere-actualite .view-display-id-block_encart .views-field-title {
  margin: 0;
  padding: 0 5px 5px 0; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart .view-header,
.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-header,
.page-emploi-actualites #contentContainer .block-derniere-actualite .view-display-id-block_encart .view-content {
  border: none; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-id-derniere_emission .views-row-first .views-field-title {
  border-bottom: none;
  font-weight: normal;
  color: #121212;
  text-align: left; }

.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-footer {
  margin-top: 15px; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content .views-field-title {
  border-bottom: 3px solid #eaeaea; }

.page-emploi-actualites #contentContainer .contentTitle {
  border-bottom: 3px solid #eaeaea;
  padding-top: 10px;
  margin: 0px 10px 5px 10px !important; }

.page-emploi-actualites #contentContainer .view-derniere-emission .bulletsCandidate,
.page-emploi-actualites #contentContainer .views-field-field-date,
.page-emploi-actualites #contentContainer .view-derniere-emission .views-field-title,
.page-emploi-actualites #contentContainer .view-derniere-emission p {
  margin: 0px 10px; }

.view-derniere-emission .views-field-field-date span {
  font-weight: bold; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eaeaea; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content {
  margin: 0;
  padding: 5px 10px; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content td {
  vertical-align: top; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .more-link {
  padding: 5px 10px 10px 10px; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .more-link a {
  background: url("/images/v6/generic/sprite-list.png") no-repeat scroll -77px -103px transparent;
  padding-left: 10px;
  color: #16354E;
  font-weight: bold; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-field-title {
  border: none; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-field-created {
  font-weight: bold; }

.page-emploi-actualites #contentContainer .view-id-actualites_emplois .view-content .views-field-path {
  margin-bottom: 0px; }

.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-content .views-row-1 {
  margin-top: 0px;
  border: none;
  padding: 0px; }

.page-emploi-actualites #contentContainer .view-id-derniere_emission .view-content .views-row-1 a {
  color: #121212;
  font-weight: normal; }

.section-emploi-actualites #contentContainer .views-field-body {
  clear: both;
  overflow: hidden;
  margin-top: 10px; }

.view-id-actualites_archives h2 {
  color: #16354E; }

.page-emploi-actualites .flux,
.node-type-actualites .flux {
  font-weight: bold;
  margin-top: 5px; }

.page-emploi-actualites .flux img,
.node-type-actualites .flux img {
  vertical-align: middle; }

.page-emploi-actualites-browse #page-title.title,
.page-emploi-actualites-archives #page-title.title,
.page-emploi-actualites-term #page-title.title,
.page-emploi-actualites-meteo-de-l-emploi #page-title.title {
  display: block;
  margin: 0px; }

.page-emploi-actualites-browse #page-title.title {
  padding-bottom: 20px; }

/*.page-emploi-actualites-browse #contentContainer,*/
.page-emploi-actualites-archives #contentContainer,
.page-emploi-actualites-meteo-de-l-emploi #contentContainer {
  border: 1px solid #eaeaea;
  padding: 10px; }

.page-emploi-actualites.page-emploi-actualites-browse .minorActionBtn,
.page-emploi-actualites.page-emploi-actualites-archives .minorActionBtn,
.page-emploi-actualites.page-emploi-actualites-term .minorActionBtn {
  display: block; }

.page-emploi-actualites #contentContainer .hpContentTV {
  background: url("/images/home/hp-sprite.jpg") no-repeat scroll right -230px transparent !important;
  height: 176px; }

.node-type-actualites .flux img {
  vertical-align: middle; }

.node-type-actualites #contentContainer {
  padding: 10px;
  width: auto; }

/*.page-emploi-actualites #contentContainer .view-actualites-emplois .views-row,*/
.page-emploi-actualites #contentContainer .view-actualites-archives .views-row,
.page-emploi-actualites #contentContainer .view-actualites-archives-emploi .views-row {
  margin: 20px 0px 0px 0px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px; }

.page-emploi-actualites #contentContainer .view-actualites-emplois .view-empty .views-row {
  padding: 0;
  border-top: 0 none; }

.page-emploi-actualites #contentContainer .view-actualites-emplois .view-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 10px; }

.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-header.actu-border {
  border-bottom: none;
  margin: 0;
  padding: 0; }

.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-actualites_emplois,
.page-emploi-actualites #contentContainer .view-display-id-page .view-display-id-block_derniere_actu .view-id-derniere_emission .view-content,
.page-emploi-actualites #contentContainer .contentTopContainer .view-id-actualites_emplois .block-derniere-actualite,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .view-id-derniere_emission .view-header,
.page-emploi-actualites #contentContainer .view-actualites-emplois.no-result .block-derniere-actualite .view-header {
  border: none;
  padding: 0px; }

.page-emploi-actualites #contentContainer .contentTopContainer .view-id-actualites_emplois .encart-actualite {
  padding: 0px; }

.page-emploi-actualites #contentContainer h1 {
  margin: 0px; }

.node-type-actualites .rubanlogo,
.page-emploi-actualites-browse .rubanlogo,
.page-emploi-actualites-archives .rubanlogo,
.page-emploi-actualites-term .rubanlogo {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea; }

.node-type-actualites #page-title.title {
  margin: 0px; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart .view-header,
.page-emploi-actualites #contentContainer .view-id-derniere_emission {
  margin-bottom: 0px; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu {
  width: 290px;
  float: left;
  margin-top: 10px;
  border: 1px solid #eaeaea !important;
  padding: 10px !important; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title .field-content a {
  color: #121212; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title .field-content a:hover {
  text-decoration: underline; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .views-field-title {
  border: none;
  padding-bottom: 3px; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .views-field-created {
  display: block;
  margin-bottom: 5px; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .views-field-created span {
  color: #16354E; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header h3 {
  margin: 0px;
  color: #16354E;
  border-bottom: 3px solid #eaeaea;
  padding-bottom: 5px;
  line-height: 18px; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu {
  clear: both;
  overflow: hidden; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header,
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart_derniere_actu .view-content {
  border: none;
  padding: 0px; }

.page-emploi-actualites #contentContainer .view-display-id-block_encart_derniere_actu .view-header {
  margin-bottom: 5px !important; }

.page-evenements .views-field-field-date-period {
  font-weight: bold;
  margin-top: 5px; }

.page-evenements .views-field-body {
  margin-top: 5px; }

.page-evenements .views-field-field-img {
  text-align: right; }

.node-type-actualites #contentContainer .content .field-label {
  display: none; }

.page-emploi-actualites-actualite #contentContainer .node-actualites .field-items .field-item.even img,
.page-emploi-actualites-actualite #contentContainer .node-actualites .field-type-taxonomy-term-reference .field-items .field-item {
  float: left; }

.page-emploi-actualites-actualite #contentContainer .node-actualites .field-type-taxonomy-term-reference .field-items .field-item a {
  padding-right: 5px; }

.page-emploi-actualites #contentContainer .field-name-body img {
  display: none; }

.page-emploi-actualites #contentContainer .block-derniere-actualite .views-field-title {
  font-size: 1.375rem;
  color: #16354E;
  font-weight: bold; }

.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .view-id-testyp .views-row {
  margin: 15px 0px 0px; }

.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .views-row {
  margin: 0px; }

.page-emploi-actualites-actualite #contentContainer .contentTopContainer .view-id-actualites_emplois .views-field-name {
  font-weight: bold; }

.page-emploi-actualites-actualite #contentContainer .node-actualites .page-title {
  margin-bottom: 15px; }

.page-emploi-actualites-actualite #contentContainer .node-actualites .page-title a {
  text-decoration: none;
  color: #16354E; }

.page-emploi-actualites-actualite #contentContainer .view-actualites-emplois {
  border: none;
  padding: 0px; }

/*.page-emploi-actualites-actualite {
border:1px solid #eaeaea;
padding:10px;
}*/
.page-emploi-actualites #contentContainer .view-display-id-page .views-field-title {
  font-size: 1.125rem; }

/*
.page-emploi-actualites .view-display-id-page .view-display-id-block_derniere_actu .views-field-title {
font-size:22px;
}
*/
.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-content .views-row {
  margin: 0; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .view-content .views-row {
  margin: 0 0 5px 0; }

.page-emploi-actualites #contentContainer .view-display-id-block_derniere_actu .view-display-id-block_encart .views-row-first {
  display: none; }

.page-emploi-actualites-archives #contentContainer .views-summary-unformatted a {
  font-weight: bold; }

/**
 * Contenu : "Actualites"
 */
.node-actualites .node-date {
  margin-bottom: 1em;
  color: #666;
  text-align: right; }

/**
 * Dossier : Energie
 */
.content.energie .partner {
  text-align: center; }

.content.energie .partner a {
  display: inline-block; }

.content.energie .partner img {
  margin: 0 10px;
  vertical-align: top; }

.node-type-actualites .content.energie h5 a {
  cursor: default;
  text-decoration: none; }

.content.energie #interview {
  background: #fff;
  float: right;
  width: 150px;
  margin: 10px 0 10px 20px;
  border: 1px solid #16354E;
  padding: 5px;
  text-align: left; }

.content.energie #interview h4 {
  color: #16354E;
  background: none;
  padding: 0;
  width: auto; }

.content.energie #interview span {
  clear: both;
  float: left;
  margin: 5px 0;
  width: 150px; }

.content.energie #interview img {
  float: left; }

.content.energie #interview span a {
  float: right;
  margin: 3px;
  width: 85px;
  color: #242424;
  font-size: 0.625rem; }

.content.energie #interview a.botLink {
  float: left;
  clear: both;
  color: #16354E;
  font-weight: bold;
  font-size: 0.625rem;
  width: 150px; }

.content.energie #moreInfo .book {
  background: #fff;
  border: 1px solid #3984DB;
  float: left;
  height: 125px;
  margin: 5px;
  padding: 5px;
  width: 250px; }

.content.energie #moreInfo .book a {
  margin: 0 0 0 5px; }

.content.energie #moreInfo .book a,
#moreInfo span.book p {
  float: left; }

.content.energie #moreInfo span a {
  font-weight: bold; }

.content.energie #moreInfo .book p {
  width: 160px;
  margin: 0 0 10px 5px;
  text-align: left; }

.content.energie table td {
  padding-right: 10px; }

/**
 * Type de contenu : Arborescence
 */
.node-type-arborescence #contentContainer {
  background-color: #ffffff;
  padding: 15px 20px 0 20px; }

.node-type-arborescence .field-name-field-item-link,
.node-type-arborescence .field-name-field-item-link-2,
.node-type-arborescence .field-name-field-item-link-3,
.node-type-arborescence .field-name-field-item-link-4 {
  padding-bottom: 20px; }

.node-type-arborescence #main .field-name-body,
.node-type-arborescence #main .field-name-field-body-2,
.node-type-arborescence #main .field-name-field-body-3,
.node-type-arborescence #main .field-name-field-body-4 {
  padding-bottom: 10px; }

.node-type-arborescence .region-content {
  width: 100%; }

._block-title, .section-trouver-un-emploi .view-header h1, .section-emplois-par-region .view-header h1, .section-emplois-par-entreprise h1#page-title, .section-conseils-emploi h1.title {
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  color: #16354E;
  border-bottom: 2px solid #16354E; }

.section-trouver-un-emploi #block-system-main .row,
.section-trouver-un-emploi #block-system-main .row-fluid,
.section-emplois-par-region #block-system-main .row,
.section-emplois-par-region #block-system-main .row-fluid,
.section-emplois-par-entreprise #block-system-main .row,
.section-emplois-par-entreprise #block-system-main .row-fluid,
.section-conseils-emploi #block-system-main .row,
.section-conseils-emploi #block-system-main .row-fluid {
  margin-left: 0;
  margin-right: 0; }

.section-trouver-un-emploi #page-title {
  display: none; }

.section-trouver-un-emploi .view-header hr {
  display: none; }

.section-emplois-par-region #page-title {
  display: none; }

.section-emplois-par-region #block-system-main .view-browsecities-cities > .view-footer {
  margin-top: 40px; }

.section-emplois-par-region .view-header hr {
  display: none; }

.section-emplois-par-entreprise .view-browsecompanies a {
  color: inherit; }

.section-conseils-emploi #page-title,
.section-conseils-emploi hr {
  display: none; }

.section-conseils-emploi .field-name-field-item-link a {
  color: inherit; }

.view-browsejobs .views-field-body .container-fluid, .view-browsejobs .views-field-body .cc-page-index-seo .domains-block, .cc-page-index-seo .view-browsejobs .views-field-body .domains-block,
.view-browsejobs .views-field-body .cc-page-index-seo .regions-block, .cc-page-index-seo .view-browsejobs .views-field-body .regions-block,
.view-browsejobs .views-field-body .cc-page-index-seo .entreprises-block, .cc-page-index-seo .view-browsejobs .views-field-body .entreprises-block,
.view-browsejobs .views-field-body .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .view-browsejobs .views-field-body .domains-block,
.view-browsejobs .views-field-body .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .view-browsejobs .views-field-body .regions-block,
.view-browsejobs .views-field-body .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .view-browsejobs .views-field-body .entreprises-block, .view-browsejobs .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsejobs .views-field-body #offres-emploi-header,
.view-browsejobs-region .views-field-body .container-fluid,
.view-browsejobs-region .views-field-body .cc-page-index-seo .domains-block,
.cc-page-index-seo .view-browsejobs-region .views-field-body .domains-block,
.view-browsejobs-region .views-field-body .cc-page-index-seo .regions-block,
.cc-page-index-seo .view-browsejobs-region .views-field-body .regions-block,
.view-browsejobs-region .views-field-body .cc-page-index-seo .entreprises-block,
.cc-page-index-seo .view-browsejobs-region .views-field-body .entreprises-block,
.view-browsejobs-region .views-field-body .section-offres-emplois-cadres .domains-block,
.section-offres-emplois-cadres .view-browsejobs-region .views-field-body .domains-block,
.view-browsejobs-region .views-field-body .section-offres-emplois-cadres .regions-block,
.section-offres-emplois-cadres .view-browsejobs-region .views-field-body .regions-block,
.view-browsejobs-region .views-field-body .section-offres-emplois-cadres .entreprises-block,
.section-offres-emplois-cadres .view-browsejobs-region .views-field-body .entreprises-block,
.view-browsejobs-region .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header,
.cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsejobs-region .views-field-body #offres-emploi-header,
.view-browsecities-cities .views-field-body .container-fluid,
.view-browsecities-cities .views-field-body .cc-page-index-seo .domains-block,
.cc-page-index-seo .view-browsecities-cities .views-field-body .domains-block,
.view-browsecities-cities .views-field-body .cc-page-index-seo .regions-block,
.cc-page-index-seo .view-browsecities-cities .views-field-body .regions-block,
.view-browsecities-cities .views-field-body .cc-page-index-seo .entreprises-block,
.cc-page-index-seo .view-browsecities-cities .views-field-body .entreprises-block,
.view-browsecities-cities .views-field-body .section-offres-emplois-cadres .domains-block,
.section-offres-emplois-cadres .view-browsecities-cities .views-field-body .domains-block,
.view-browsecities-cities .views-field-body .section-offres-emplois-cadres .regions-block,
.section-offres-emplois-cadres .view-browsecities-cities .views-field-body .regions-block,
.view-browsecities-cities .views-field-body .section-offres-emplois-cadres .entreprises-block,
.section-offres-emplois-cadres .view-browsecities-cities .views-field-body .entreprises-block,
.view-browsecities-cities .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header,
.cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsecities-cities .views-field-body #offres-emploi-header,
.view-browsecities-departements .views-field-body .container-fluid,
.view-browsecities-departements .views-field-body .cc-page-index-seo .domains-block,
.cc-page-index-seo .view-browsecities-departements .views-field-body .domains-block,
.view-browsecities-departements .views-field-body .cc-page-index-seo .regions-block,
.cc-page-index-seo .view-browsecities-departements .views-field-body .regions-block,
.view-browsecities-departements .views-field-body .cc-page-index-seo .entreprises-block,
.cc-page-index-seo .view-browsecities-departements .views-field-body .entreprises-block,
.view-browsecities-departements .views-field-body .section-offres-emplois-cadres .domains-block,
.section-offres-emplois-cadres .view-browsecities-departements .views-field-body .domains-block,
.view-browsecities-departements .views-field-body .section-offres-emplois-cadres .regions-block,
.section-offres-emplois-cadres .view-browsecities-departements .views-field-body .regions-block,
.view-browsecities-departements .views-field-body .section-offres-emplois-cadres .entreprises-block,
.section-offres-emplois-cadres .view-browsecities-departements .views-field-body .entreprises-block,
.view-browsecities-departements .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header,
.cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsecities-departements .views-field-body #offres-emploi-header {
  padding-left: 0;
  padding-right: 0; }
  .view-browsejobs .views-field-body .container-fluid > .row > *, .view-browsejobs .views-field-body .cc-page-index-seo .domains-block > .row > *, .cc-page-index-seo .view-browsejobs .views-field-body .domains-block > .row > *, .view-browsejobs .views-field-body .cc-page-index-seo .regions-block > .row > *, .cc-page-index-seo .view-browsejobs .views-field-body .regions-block > .row > *, .view-browsejobs .views-field-body .cc-page-index-seo .entreprises-block > .row > *, .cc-page-index-seo .view-browsejobs .views-field-body .entreprises-block > .row > *, .view-browsejobs .views-field-body .section-offres-emplois-cadres .domains-block > .row > *, .section-offres-emplois-cadres .view-browsejobs .views-field-body .domains-block > .row > *, .view-browsejobs .views-field-body .section-offres-emplois-cadres .regions-block > .row > *, .section-offres-emplois-cadres .view-browsejobs .views-field-body .regions-block > .row > *, .view-browsejobs .views-field-body .section-offres-emplois-cadres .entreprises-block > .row > *, .section-offres-emplois-cadres .view-browsejobs .views-field-body .entreprises-block > .row > *, .view-browsejobs .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header > .row > *, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsejobs .views-field-body #offres-emploi-header > .row > *,
  .view-browsejobs-region .views-field-body .container-fluid > .row > *,
  .view-browsejobs-region .views-field-body .cc-page-index-seo .domains-block > .row > *,
  .cc-page-index-seo .view-browsejobs-region .views-field-body .domains-block > .row > *,
  .view-browsejobs-region .views-field-body .cc-page-index-seo .regions-block > .row > *,
  .cc-page-index-seo .view-browsejobs-region .views-field-body .regions-block > .row > *,
  .view-browsejobs-region .views-field-body .cc-page-index-seo .entreprises-block > .row > *,
  .cc-page-index-seo .view-browsejobs-region .views-field-body .entreprises-block > .row > *,
  .view-browsejobs-region .views-field-body .section-offres-emplois-cadres .domains-block > .row > *,
  .section-offres-emplois-cadres .view-browsejobs-region .views-field-body .domains-block > .row > *,
  .view-browsejobs-region .views-field-body .section-offres-emplois-cadres .regions-block > .row > *,
  .section-offres-emplois-cadres .view-browsejobs-region .views-field-body .regions-block > .row > *,
  .view-browsejobs-region .views-field-body .section-offres-emplois-cadres .entreprises-block > .row > *,
  .section-offres-emplois-cadres .view-browsejobs-region .views-field-body .entreprises-block > .row > *,
  .view-browsejobs-region .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header > .row > *,
  .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsejobs-region .views-field-body #offres-emploi-header > .row > *,
  .view-browsecities-cities .views-field-body .container-fluid > .row > *,
  .view-browsecities-cities .views-field-body .cc-page-index-seo .domains-block > .row > *,
  .cc-page-index-seo .view-browsecities-cities .views-field-body .domains-block > .row > *,
  .view-browsecities-cities .views-field-body .cc-page-index-seo .regions-block > .row > *,
  .cc-page-index-seo .view-browsecities-cities .views-field-body .regions-block > .row > *,
  .view-browsecities-cities .views-field-body .cc-page-index-seo .entreprises-block > .row > *,
  .cc-page-index-seo .view-browsecities-cities .views-field-body .entreprises-block > .row > *,
  .view-browsecities-cities .views-field-body .section-offres-emplois-cadres .domains-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-cities .views-field-body .domains-block > .row > *,
  .view-browsecities-cities .views-field-body .section-offres-emplois-cadres .regions-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-cities .views-field-body .regions-block > .row > *,
  .view-browsecities-cities .views-field-body .section-offres-emplois-cadres .entreprises-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-cities .views-field-body .entreprises-block > .row > *,
  .view-browsecities-cities .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header > .row > *,
  .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsecities-cities .views-field-body #offres-emploi-header > .row > *,
  .view-browsecities-departements .views-field-body .container-fluid > .row > *,
  .view-browsecities-departements .views-field-body .cc-page-index-seo .domains-block > .row > *,
  .cc-page-index-seo .view-browsecities-departements .views-field-body .domains-block > .row > *,
  .view-browsecities-departements .views-field-body .cc-page-index-seo .regions-block > .row > *,
  .cc-page-index-seo .view-browsecities-departements .views-field-body .regions-block > .row > *,
  .view-browsecities-departements .views-field-body .cc-page-index-seo .entreprises-block > .row > *,
  .cc-page-index-seo .view-browsecities-departements .views-field-body .entreprises-block > .row > *,
  .view-browsecities-departements .views-field-body .section-offres-emplois-cadres .domains-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-departements .views-field-body .domains-block > .row > *,
  .view-browsecities-departements .views-field-body .section-offres-emplois-cadres .regions-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-departements .views-field-body .regions-block > .row > *,
  .view-browsecities-departements .views-field-body .section-offres-emplois-cadres .entreprises-block > .row > *,
  .section-offres-emplois-cadres .view-browsecities-departements .views-field-body .entreprises-block > .row > *,
  .view-browsecities-departements .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header > .row > *,
  .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsecities-departements .views-field-body #offres-emploi-header > .row > * {
    padding-left: 0;
    padding-right: 0; }

.view-browsecities-regions .views-field-body .container-fluid > .row > *, .view-browsecities-regions .views-field-body .cc-page-index-seo .domains-block > .row > *, .cc-page-index-seo .view-browsecities-regions .views-field-body .domains-block > .row > *, .view-browsecities-regions .views-field-body .cc-page-index-seo .regions-block > .row > *, .cc-page-index-seo .view-browsecities-regions .views-field-body .regions-block > .row > *, .view-browsecities-regions .views-field-body .cc-page-index-seo .entreprises-block > .row > *, .cc-page-index-seo .view-browsecities-regions .views-field-body .entreprises-block > .row > *, .view-browsecities-regions .views-field-body .section-offres-emplois-cadres .domains-block > .row > *, .section-offres-emplois-cadres .view-browsecities-regions .views-field-body .domains-block > .row > *, .view-browsecities-regions .views-field-body .section-offres-emplois-cadres .regions-block > .row > *, .section-offres-emplois-cadres .view-browsecities-regions .views-field-body .regions-block > .row > *, .view-browsecities-regions .views-field-body .section-offres-emplois-cadres .entreprises-block > .row > *, .section-offres-emplois-cadres .view-browsecities-regions .views-field-body .entreprises-block > .row > *, .view-browsecities-regions .views-field-body .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header > .row > *, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .view-browsecities-regions .views-field-body #offres-emploi-header > .row > * {
  padding-left: 0;
  padding-right: 0; }

.cc-page-index-seo,
.section-offres-emplois-cadres {
  padding-top: 0; }
  .cc-page-index-seo h1#page-title,
  .section-offres-emplois-cadres h1#page-title {
    display: none; }
  .cc-page-index-seo .domains-block a,
  .cc-page-index-seo .regions-block a,
  .cc-page-index-seo .entreprises-block a,
  .section-offres-emplois-cadres .domains-block a,
  .section-offres-emplois-cadres .regions-block a,
  .section-offres-emplois-cadres .entreprises-block a {
    color: inherit; }
  .cc-page-index-seo .domains-block .row-fluid,
  .cc-page-index-seo .regions-block .row-fluid,
  .cc-page-index-seo .entreprises-block .row-fluid,
  .section-offres-emplois-cadres .domains-block .row-fluid,
  .section-offres-emplois-cadres .regions-block .row-fluid,
  .section-offres-emplois-cadres .entreprises-block .row-fluid {
    margin-left: 0;
    margin-right: 0; }
  .cc-page-index-seo .browsecompanies-index,
  .section-offres-emplois-cadres .browsecompanies-index {
    text-transform: uppercase; }
  .cc-page-index-seo .block,
  .cc-page-index-seo #main-wrapper,
  .section-offres-emplois-cadres .block,
  .section-offres-emplois-cadres #main-wrapper {
    margin-bottom: 0; }
  .cc-page-index-seo #footer .subFooter,
  .section-offres-emplois-cadres #footer .subFooter {
    margin-top: 0; }
  .cc-page-index-seo #logo,
  .section-offres-emplois-cadres #logo {
    left: 4%; }
  .cc-page-index-seo #content,
  .section-offres-emplois-cadres #content {
    margin-top: 0; }
  .cc-page-index-seo .punchline,
  .section-offres-emplois-cadres .punchline {
    float: right;
    text-align: left;
    margin: 4% 5% 0 0; }
  .cc-page-index-seo .media-block,
  .section-offres-emplois-cadres .media-block {
    text-align: center; }
    .cc-page-index-seo .media-block h2,
    .section-offres-emplois-cadres .media-block h2 {
      text-align: left; }
    .cc-page-index-seo .media-block a,
    .section-offres-emplois-cadres .media-block a {
      display: block; }
    .cc-page-index-seo .media-block a:hover,
    .section-offres-emplois-cadres .media-block a:hover {
      text-decoration: none; }
  .cc-page-index-seo #offres-emploi-mj,
  .section-offres-emplois-cadres #offres-emploi-mj {
    text-decoration: none;
    background: #fff; }
    .cc-page-index-seo #offres-emploi-mj #emplois-entreprises,
    .section-offres-emplois-cadres #offres-emploi-mj #emplois-entreprises {
      background: #f9f9f9; }
    .cc-page-index-seo #offres-emploi-mj .domains-block,
    .cc-page-index-seo #offres-emploi-mj .media-block,
    .cc-page-index-seo #offres-emploi-mj .entreprises-block,
    .cc-page-index-seo #offres-emploi-mj .regions-block,
    .section-offres-emplois-cadres #offres-emploi-mj .domains-block,
    .section-offres-emplois-cadres #offres-emploi-mj .media-block,
    .section-offres-emplois-cadres #offres-emploi-mj .entreprises-block,
    .section-offres-emplois-cadres #offres-emploi-mj .regions-block {
      width: 70%;
      margin: 0 auto; }
    .cc-page-index-seo #offres-emploi-mj .punchline h1,
    .cc-page-index-seo #offres-emploi-mj .punchline .h1,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline h1,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .h1 {
      font-size: 3.4em;
      font-weight: 600;
      color: #ffffff;
      text-transform: uppercase;
      text-align: left; }
    .cc-page-index-seo #offres-emploi-mj .punchline p,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline p {
      font-size: 1.4em;
      color: #ffffff; }
    .cc-page-index-seo #offres-emploi-mj .punchline a,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline a {
      color: #ffffff; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent a,
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent a,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a {
      padding: 15px 25px;
      font-weight: 600;
      font-size: 1.6em;
      border-radius: 30px;
      text-transform: uppercase;
      color: #ffffff;
      margin: 23px 0px;
      display: inline-block;
      border: none; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent a:hover,
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a:hover,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent a:hover,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a:hover {
      border: none;
      color: #ffffff;
      text-decoration: none; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent a:active,
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a:active,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent a:active,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a:active {
      border: none;
      color: #ffffff; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a {
      color: #2d2c33;
      background-color: #ffffff;
      border: 2px solid #ffffff; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a:hover,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a:hover {
      border: 2px solid #ffffff;
      background: none; }
    .cc-page-index-seo #offres-emploi-mj .punchline .big-btn-transparent-white a:active,
    .section-offres-emplois-cadres #offres-emploi-mj .punchline .big-btn-transparent-white a:active {
      border: 2px solid #ffffff;
      background-color: rgba(255, 255, 255, 0.2); }
    .cc-page-index-seo #offres-emploi-mj #mj-search-bar,
    .section-offres-emplois-cadres #offres-emploi-mj #mj-search-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.2);
      filter: progid:DXImageTransform.Microsoft.gradient; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline,
    .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline {
      width: 50%;
      padding: 80px 25px; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a,
    .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a {
      padding: 15px 25px;
      font-weight: 600;
      font-size: 1.6em;
      border-radius: 30px;
      text-transform: uppercase;
      color: #ffffff;
      margin: 23px 0px;
      display: inline-block;
      border: none; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a:hover,
    .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a:hover {
      border: none;
      color: #ffffff;
      text-decoration: none; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a:active,
    .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a:active {
      border: none;
      color: #ffffff;
      text-decoration: none;
      background-color: #287CB4; }
    .cc-page-index-seo #offres-emploi-mj #mj-search-bar,
    .section-offres-emplois-cadres #offres-emploi-mj #mj-search-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.2);
      filter: progid:DXImageTransform.Microsoft.gradient; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline p,
    .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline p {
      font-size: 1.6em;
      color: #2d2c33; }
    .cc-page-index-seo #offres-emploi-mj h2,
    .section-offres-emplois-cadres #offres-emploi-mj h2 {
      padding-bottom: 10px;
      font-weight: normal; }
    .cc-page-index-seo #offres-emploi-mj #emplois-regions,
    .cc-page-index-seo #offres-emploi-mj #emplois-entreprises,
    .cc-page-index-seo #offres-emploi-mj .cc-seo-section-container,
    .section-offres-emplois-cadres #offres-emploi-mj #emplois-regions,
    .section-offres-emplois-cadres #offres-emploi-mj #emplois-entreprises,
    .section-offres-emplois-cadres #offres-emploi-mj .cc-seo-section-container {
      padding: 70px 0; }
    .cc-page-index-seo #offres-emploi-mj #media,
    .section-offres-emplois-cadres #offres-emploi-mj #media {
      padding: 30px 0; }
  .cc-page-index-seo #main-wrapper,
  .cc-page-index-seo #content,
  .cc-page-index-seo .region-content,
  .cc-page-index-seo #offres-emploi-mj #offres-emploi-header,
  .cc-page-index-seo #offres-emploi-mj #logos,
  .cc-page-index-seo #offres-emploi-mj #media,
  .section-offres-emplois-cadres #main-wrapper,
  .section-offres-emplois-cadres #content,
  .section-offres-emplois-cadres .region-content,
  .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header,
  .section-offres-emplois-cadres #offres-emploi-mj #logos,
  .section-offres-emplois-cadres #offres-emploi-mj #media {
    width: 100%; }
  .cc-page-index-seo #search-form .search-title,
  .section-offres-emplois-cadres #search-form .search-title {
    display: block;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 34px; }

body.mj-header-fixed.mj-role-candidate.mj-role-registered.cc-page-index-seo #offres-emploi-mj #mj-search-bar {
  position: relative;
  max-width: 100%; }

body.cc-page-index-seo.mj-role-company {
  padding-top: 0; }

body.mj-header-fixed.mj-role-candidate.mj-role-registered.cc-page-index-seo #mj-search-bar .mj-container {
  max-width: 800px; }

.cc-page-index-seo.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px; }

.cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin {
  background: #2BADF4 url(/sites/cms.meteojob.com/themes/meteojob/assets/images/bg-hero-2021-09.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header {
    max-width: 90%;
    margin: 0 auto; }
    .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header .punchline {
      float: left; }
  @media (min-width: 1440px) {
    .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin {
      background-size: contain !important;
      background-position: right 0px !important; } }

.section-offres-emplois-cadres #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/header-meteojob-recrutement.jpg) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color: #2d2c33;
  min-height: 370px;
  padding: 0 0 1% 0; }

.section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline {
  width: 50%;
  padding: 10% 25px; }
  .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline p {
    font-size: 1.4em;
    color: #fff; }

@media (max-width: 992px) {
  /************ Landing page Offres emploi *************/
  #offres-emploi-mj .domains-block, #offres-emploi-mj .entreprises-block, #offres-emploi-mj .regions-block {
    width: 90%; }
  /************ Landing page Offres emplois Cadres *************/
  .section-offres-emplois-cadres #mj-popin-wrapper #mj-popin {
    background-position: 30% 0 !important; } }

@media (max-width: 767px) {
  .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline,
  .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline {
    width: 100%;
    text-align: center;
    margin: 15% 0 0 2%; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline h1, .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline h2,
    .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline h1,
    .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline h2 {
      font-size: 1.8em;
      text-align: center; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline p,
    .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline p {
      font-size: 1.4em; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a,
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent-white a,
    .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent a,
    .cc-page-index-seo-cadres #offres-emploi-mj #offres-emploi-header .punchline .big-btn-transparent-white a {
      padding: 15px 20px;
      font-size: 1.5em;
      text-align: center; }
  /************ Landing Page Offres emploi *************/
  .cc-page-index-seo #mj-popin-wrapper #mj-popin {
    padding-bottom: 0; }
  .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin {
    background: none;
    background-color: #2BADF4;
    padding-top: 80px; }
  .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline {
    margin: 5% 0 0 2%;
    text-align: center;
    padding: 10px 25px; }
    .cc-page-index-seo #offres-emploi-mj #offres-emploi-header .punchline h1 {
      font-size: 2.6em; }
  .cc-page-index-seo #offres-emploi-mj #emplois-regions,
  .cc-page-index-seo #offres-emploi-mj #emplois-entreprises,
  .cc-page-index-seo #offres-emploi-mj .cc-seo-section-container {
    padding: 30px 0; }
  /************ Landing Page Offres emplois cadres *************/
  .section-offres-emplois-cadres #mj-popin-wrapper.no-popin #mj-popin {
    background: none;
    background-color: #2d2c33;
    padding-top: 10%; }
  .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline {
    width: 100%;
    text-align: center;
    margin: 5% 0 0 2%; }
  .section-offres-emplois-cadres #offres-emploi-mj #offres-emploi-header .punchline h1 {
    text-align: center; }
  .section-offres-emplois-cadres #offres-emploi-mj #emplois-regions,
  .section-offres-emplois-cadres #offres-emploi-mj #emplois-entreprises {
    padding: 30px 0; } }

.mj-offers-list {
  padding-left: 0;
  margin: 0;
  list-style: none; }
  .mj-offers-list li:not(.offer-active):hover .mj-offer {
    background-color: #F0F4F7; }
    .mj-offers-list li:not(.offer-active):hover .mj-offer .title {
      color: #16354E; }
  .mj-offers-list li:not(.offer-active):hover + li .mj-offer {
    box-shadow: none; }
  .mj-offers-list .offer-active .block-link {
    display: none; }
  .mj-offers-list .h3 {
    font-size: 1rem;
    display: inline-block;
    font-weight: 400; }
  .mj-offers-list .preview {
    word-break: break-all; }

.mj-offer {
  background-color: #fff;
  padding: 20px;
  margin: 0;
  border-radius: 0;
  transition: background-color .15s ease;
  position: relative;
  overflow: visible;
  border-bottom: 1px solid #B9C2CA; }
  .mj-offer:before, .mj-offer:after {
    content: " ";
    display: table; }
  .mj-offer:after {
    clear: both; }
  .mj-offers-list .mj-offer:not(:last-child) {
    border: 0 none; }
  .mj-offer .mj-title {
    margin-bottom: 5px; }
  .mj-offer .mj-title-small {
    margin: -10px 0 10px; }
  .mj-offer.logged {
    overflow: visible; }
  .mj-offer.on-top .title {
    color: #16354E; }
  .mj-offer.viewed header, .mj-offer.viewed .preview-wrapper, .mj-offer.viewed .details {
    position: relative;
    transition: opacity 150ms ease;
    opacity: 0.6; }
  .mj-offer.viewed:hover header, .mj-offer.viewed:hover .preview-wrapper, .mj-offer.viewed:hover .details {
    opacity: 1; }
  .mj-offer.viewed .new-tag {
    display: none; }
  .mj-offer a {
    position: relative;
    z-index: 1; }
    .mj-offer a.block-link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
  .mj-offer .header-data {
    margin-right: 150px; }
    .mj-offer .header-data .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.5;
      transition: color .15s ease;
      text-transform: uppercase;
      margin-top: -5px;
      margin-bottom: 5px;
      padding: 0;
      font-size: 1.375rem; }
    .mj-offer .header-data .info {
      margin-bottom: 5px; }
      .mj-offer .header-data .info li {
        display: inline;
        margin-right: 2px; }
        .mj-offer .header-data .info li span {
          margin: 0 5px; }
      .mj-offer .header-data .info .dropdown-menu li {
        display: list-item; }
      .mj-offer .header-data .info .collapse.in {
        display: inline; }
    .mj-offer .header-data .tags .published-date {
      font-size: 0.875rem;
      background-color: #F0F4F7;
      display: inline-block;
      padding: 2px 4px; }
    .mj-offer .header-data .tags .label-primary {
      padding: 2px 4px;
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
      line-height: inherit;
      border-radius: 0; }
  .mj-offer .logo {
    float: right;
    width: 140px;
    height: 70px;
    text-align: center; }
    .mj-offer .logo p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      line-height: 70px;
      padding: 0 10px;
      margin: 0;
      font-weight: bold;
      background-color: #B9C2CA;
      color: #000000; }
    .mj-offer .logo img {
      max-width: 100%;
      max-height: 100%; }
    .mj-offer .logo a {
      display: inline-block;
      width: 100%;
      font-weight: 600; }
      .mj-offer .logo a:hover, .mj-offer .logo afocus {
        text-decoration: none;
        font-weight: 600; }
  .mj-offer .new-tag {
    border-bottom: 55px solid transparent;
    border-right: 55px solid #16354E;
    width: 0;
    height: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1; }
    .mj-offer .new-tag span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.9);
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 8px;
      left: 23px; }
  @media (max-width: 768px) {
    .mj-offer {
      padding: 10px; }
      .mj-offer .logo {
        width: 90px;
        float: left; }
      .mj-offer .header-data {
        margin-left: 100px;
        margin-right: 0; }
        .mj-offer .header-data .title {
          white-space: normal;
          font-size: 1.125rem; } }
  .mj-offer .footer-right {
    z-index: 1;
    position: absolute;
    right: 15px;
    bottom: 15px;
    line-height: 36.85714286px; }
  .mj-offer .preview-wrapper {
    position: relative;
    margin: 15px 0 8px; }
  .mj-offer .loader {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center; }
  .mj-offer .details:before, .mj-offer .details:after {
    content: " ";
    display: table; }
  .mj-offer .details:after {
    clear: both; }
  .mj-offer .details-content {
    margin-bottom: 10px; }
  .mj-offer .details-footer {
    font-size: 0.875rem; }
    .mj-offer .details-footer .fa, .mj-offer .details-footer .ft {
      margin-left: 3px; }
    .mj-offer .details-footer .fa-caret-up {
      display: none; }
    .mj-offer .details-footer a:focus:not(:hover) {
      text-decoration: none; }

.pagination-more-results {
  display: block;
  color: #fff !important;
  background: #16354E;
  line-height: 20.85714286px;
  font-weight: 600;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid #E8EBED;
  padding: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2); }
  .pagination-more-results:before, .pagination-more-results:after {
    content: " ";
    display: table; }
  .pagination-more-results:after {
    clear: both; }
  .pagination-more-results .fa, .pagination-more-results .ft {
    margin-left: 8px;
    font-size: 1.125rem;
    vertical-align: middle; }
  .pagination-more-results:hover {
    color: #FFFFFF;
    background-color: #0b1a26;
    border-color: #09151e;
    text-decoration: none; }

.section-emploi {
  padding-top: 0;
  /* HEADER */
  /* LOGOS BLOCK */
  /* CONTACT FORM */
  /**
 * CAPTCHA
*/ }
  .section-emploi h1#page-title {
    display: none; }
  .section-emploi .block {
    margin-bottom: 0; }
  .section-emploi #main-wrapper {
    margin-bottom: 0; }
  .section-emploi #footer .subFooter {
    margin-top: 0; }
  .section-emploi #logo {
    left: 4%; }
  .section-emploi #content {
    margin-top: 0; }
  .section-emploi #header.navbar-fixed-bottom, .section-emploi #header.navbar-fixed-top {
    position: absolute; }
  .section-emploi.company #header-top-container .header-links {
    font-size: inherit;
    text-transform: inherit; }
  .section-emploi #header-sticky-container .header-recruiter-zone-unlogged li {
    padding: 0 20px; }
  .section-emploi #header-sticky-container .header-recruiter-zone-unlogged .child-separator {
    padding: 0;
    color: #ffffff; }
  .section-emploi #header-sticky-container .header-recruiter-zone-unlogged img {
    max-width: 30px;
    margin-right: 10px;
    margin-top: -3px; }
  .section-emploi #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-emploi #main {
        padding-top: 60px; } }
  .section-emploi #mj-main-container {
    max-width: none; }
    .section-emploi #mj-main-container .container-fluid, .section-emploi #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-emploi #mj-main-container .domains-block,
    .section-emploi #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-emploi #mj-main-container .regions-block,
    .section-emploi #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-emploi #mj-main-container .entreprises-block,
    .section-emploi #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-emploi #mj-main-container .domains-block,
    .section-emploi #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-emploi #mj-main-container .regions-block,
    .section-emploi #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-emploi #mj-main-container .entreprises-block, .section-emploi #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-emploi #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px; }
  .section-emploi #mj-popin-wrapper #mj-popin {
    padding: 0; }
  .section-emploi hr.separator {
    width: 130px;
    border-color: #16354E;
    border-top-width: 2px;
    margin: 30px auto; }
  .section-emploi #recruiters-testimonials {
    background: #5b7182; }
    .section-emploi #recruiters-testimonials h2 {
      color: #fff; }
    .section-emploi #recruiters-testimonials hr.separator {
      border-color: #16354E; }
    .section-emploi #recruiters-testimonials p {
      color: #fff; }
    .section-emploi #recruiters-testimonials .bubble {
      margin: 5px 5px 30px 5px;
      background: #8B9AA7;
      min-height: 170px;
      border-radius: 15px !important;
      position: relative;
      text-align: center; }
      .section-emploi #recruiters-testimonials .bubble::after {
        left: 50%;
        top: 100%;
        border: solid transparent;
        border-top-color: #8B9AA7;
        border-width: 7px;
        margin-left: -7px;
        content: '';
        height: 0;
        width: 0;
        position: absolute; }
      .section-emploi #recruiters-testimonials .bubble p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 30px; }
    .section-emploi #recruiters-testimonials .picture {
      text-align: center; }
      .section-emploi #recruiters-testimonials .picture img {
        border-radius: 50%; }
    .section-emploi #recruiters-testimonials .btn-primary {
      width: 100%;
      font-weight: 600;
      padding: 15px;
      background-color: #16354E;
      border-color: #16354E;
      white-space: normal; }
    .section-emploi #recruiters-testimonials .btn-primary:hover {
      background-color: #16354E; }
  .section-emploi #recruiters-products {
    background: #F0F4F7; }
    .section-emploi #recruiters-products h2 {
      color: #16354E; }
    .section-emploi #recruiters-products p {
      text-align: center;
      font-weight: bold;
      color: #5B7182; }
    .section-emploi #recruiters-products .pricing {
      background-color: #ffffff;
      margin-bottom: 20px;
      padding: 20px;
      transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s; }
      .section-emploi #recruiters-products .pricing:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
      .section-emploi #recruiters-products .pricing .figure {
        font-size: 2rem; }
      .section-emploi #recruiters-products .pricing h3 {
        text-transform: uppercase;
        color: #16354E;
        font-size: 1.5rem;
        text-align: center; }
        .section-emploi #recruiters-products .pricing h3:after {
          background: none repeat scroll 0 0 #16354E;
          bottom: -15px;
          content: "";
          display: block;
          height: 3px;
          position: relative;
          width: 40px;
          margin: 0 auto 35px auto; }
      .section-emploi #recruiters-products .pricing p {
        color: inherit; }
      .section-emploi #recruiters-products .pricing .btn {
        width: 100%; }
  .section-emploi .title,
  .section-emploi #ads-banner {
    display: none !important; }
  .section-emploi .region-content {
    margin: 0 auto; }
  .section-emploi #main-wrapper,
  .section-emploi #content,
  .section-emploi .region-content,
  .section-emploi #recruiter-mj #logos {
    width: 100%; }
  .section-emploi .mj-role-candidate.mj-role-registered #mj-user-navigation {
    position: absolute;
    top: 25px;
    left: 80px; }
  .section-emploi #recruiter-mj {
    background: #fff; }
    .section-emploi #recruiter-mj h2 {
      font-size: 2.2em;
      font-weight: 600;
      line-height: initial;
      text-align: center; }
    .section-emploi #recruiter-mj .punchline {
      width: 75%;
      text-align: center;
      padding: 10% 25px;
      margin: 5% auto 0 auto;
      float: none; }
      .section-emploi #recruiter-mj .punchline h1 {
        font-size: 2.6em;
        font-weight: 600;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center; }
      .section-emploi #recruiter-mj .punchline h2 {
        font-size: 2.6em;
        color: #ffffff;
        text-transform: uppercase;
        padding: 20px 0; }
      .section-emploi #recruiter-mj .punchline p {
        font-size: 1.4em;
        color: #ffffff; }
      .section-emploi #recruiter-mj .punchline a {
        color: #ffffff; }
      .section-emploi #recruiter-mj .punchline i {
        height: 40px;
        line-height: 38px;
        width: 40px;
        font-size: 1.5625rem;
        border: 2px solid #16354E;
        border-radius: 50%;
        color: #FFFFFF;
        text-align: center;
        position: relative;
        z-index: 1;
        background: none; }
    .section-emploi #recruiter-mj #logos {
      background-color: #ffffff;
      height: 100px; }
      .section-emploi #recruiter-mj #logos .logo {
        margin: 0px 0px 5px 25px;
        background: none; }
  .section-emploi #recruiter-mj #logos {
    background-color: #ffffff;
    height: 100px; }
  .section-emploi #recruiter-mj #hpSpe #hpLogos {
    position: absolute;
    width: 999999px; }
    .section-emploi #recruiter-mj #hpSpe #hpLogos .view, .section-emploi #recruiter-mj #hpSpe #hpLogos .view .views-row {
      float: left; }
  .section-emploi #recruiter-mj #hpSpe #hpLogos > div {
    float: left; }
  .section-emploi #recruiter-mj #hpSpe .line-logos {
    height: 90px;
    margin: 10px 0;
    overflow: hidden; }
  .section-emploi #recruiter-mj #hpSpe .line {
    position: relative; }
  .section-emploi #recruiter-mj #randomLogoBlock {
    float: left; }
  .section-emploi #recruiter-mj #randomLogoBlock, .section-emploi #recruiter-mj #block-views-logos-all-small-block {
    height: 80px;
    overflow: hidden; }
  .section-emploi #recruiter-mj .logo {
    margin: 0 0 5px 25px;
    background: none; }
    .section-emploi #recruiter-mj .logo img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%); }
      .section-emploi #recruiter-mj .logo img:hover {
        -webkit-filter: none;
        filter: none; }
  .section-emploi .region-content-bottom {
    background: #F0F4F7; }
    .section-emploi .region-content-bottom .mj-block {
      background-color: transparent; }
  .section-emploi #contact-form h2 {
    color: #16354E;
    font-size: 2.2em;
    font-weight: 600;
    line-height: initial;
    text-align: center; }
  .section-emploi #contact-form .fa, .section-emploi #contact-form .ft {
    color: #16354E; }
  .section-emploi #contact-form p a {
    color: #16354E; }
  .section-emploi #recruter-en-ligne-form h2,
  .section-emploi #recruter-en-ligne-form .submitted {
    display: none; }
  .section-emploi .hbspt-form .hs-error-msg,
  .section-emploi .hbspt-form .hs-error-msgs {
    color: #00B5DD;
    padding: 0;
    list-style-type: none; }
  .section-emploi .hbspt-form .hs-form-field {
    margin-bottom: 15px; }
  .section-emploi .hbspt-form .hs-input {
    display: block;
    width: 100%;
    height: 56px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #8B9AA7;
    background-image: none;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #ccc; }
  .section-emploi .hbspt-form textarea.hs-input {
    height: 115px; }
  .section-emploi .hbspt-form ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8B9AA7;
    opacity: 1;
    /* Firefox */ }
  .section-emploi .hbspt-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8B9AA7; }
  .section-emploi .hbspt-form ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8B9AA7; }
  .section-emploi .hbspt-form input[type=submit] {
    float: right;
    padding: 15px 50px;
    min-width: 160px;
    min-height: 56px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 1.4em;
    width: 100%;
    color: #ffffff;
    border-radius: 30px;
    background-color: #00B5DD;
    border-color: #00a0c4; }
    .section-emploi .hbspt-form input[type=submit]:hover {
      background-color: #00a0c4;
      border-color: #007691; }
    .section-emploi .hbspt-form input[type=submit]:active:focus {
      background-color: #007691;
      border-color: #006177; }
  .section-emploi .hbspt-form .submitted-message {
    padding-bottom: 20px; }
  .section-emploi .webform-client-form .submitted {
    display: none; }
  .section-emploi .webform-client-form .form-text {
    height: 40px; }
  .section-emploi .webform-client-form .form-text, .section-emploi .webform-client-form .form-select, .section-emploi .webform-client-form .form-textarea {
    display: block;
    width: 100%;
    height: 56px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #8B9AA7;
    background-image: none;
    border-radius: 2px;
    box-shadow: none; }
  .section-emploi .webform-client-form .form-textarea {
    height: auto; }
  .section-emploi .webform-client-form .webform-component-markup p {
    font-size: 14px;
    color: #8B9AA7; }
  .section-emploi .webform-client-form .form-checkbox {
    float: left;
    width: 5%;
    margin-bottom: 5px; }
  .section-emploi .webform-client-form .option {
    width: 95%;
    float: right;
    font-size: 14px;
    color: #8B9AA7;
    text-align: left; }
  .section-emploi .webform-client-form .btn {
    float: right;
    padding: 15px 50px;
    min-width: 160px;
    min-height: 56px;
    margin-top: 60px;
    font-weight: bold;
    font-size: 1.4em; }
  .section-emploi .webform-client-form ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8B9AA7;
    opacity: 1;
    /* Firefox */ }
  .section-emploi .webform-client-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8B9AA7; }
  .section-emploi .webform-client-form ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8B9AA7; }
  .section-emploi .captcha .fieldset-description,
  .section-emploi .captcha .form-item-captcha-response .description,
  .section-emploi .captcha legend {
    display: none; }
  .section-emploi .captcha .fieldset-wrapper img, .section-emploi .captcha #edit-captcha-response {
    float: left; }
  .section-emploi .form-item-captcha-response label {
    float: right;
    margin-top: 5px; }
  .section-emploi .form-wrapper {
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 10px; }
  .section-emploi .reload-captcha-wrapper {
    text-align: left; }
  .section-emploi .reload-captcha {
    float: left;
    padding-top: 10px; }

body.section-emploi.mj-role-company {
  padding-top: 0; }

@media (max-width: 992px) {
  .section-emploi #mj-popin-wrapper.no-popin #mj-popin {
    min-height: auto; }
  .section-emploi #search-form .col-md-3.form-group {
    padding: 0; } }

@media (max-width: 767px) {
  .section-emploi #recruiter-mj .btn-primary {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
  .section-emploi #recruiter-mj .punchline {
    width: 90%;
    text-align: center;
    margin: 100px auto;
    padding: 10px 25px;
    float: none; }
    .section-emploi #recruiter-mj .punchline h1,
    .section-emploi #recruiter-mj .punchline h2 {
      font-size: 1.8em;
      text-align: center;
      text-shadow: 2px 2px 10px black; }
    .section-emploi #recruiter-mj .punchline p {
      text-shadow: 2px 2px 10px black; }
  .section-emploi #recruiter-mj .float-lt,
  .section-emploi #recruiter-mj .float-rt {
    float: none;
    text-align: center; }
  .section-emploi #mj-popin-wrapper #mj-popin {
    padding: 120px 0 50px 0 !important;
    background-position: 0 !important; }
  .section-emploi #mj-popin-wrapper.no-popin #mj-popin {
    padding-top: 0;
    min-height: auto; }
  .section-emploi #recruiters-testimonials .bubble {
    min-height: auto; }
    .section-emploi #recruiters-testimonials .bubble p {
      position: initial;
      transform: none; }
  .section-emploi .webform-client-form .btn {
    float: none;
    width: 100%; } }

@media (max-width: 767px) {
  .section-emploi .no-padd-lt, .section-emploi .no-padd-rt {
    padding: 0; }
  .section-emploi .float-lt, .section-emploi .float-rt {
    float: none;
    text-align: center; } }

.section-matching-emploi {
  padding-top: 0; }
  .section-matching-emploi h1#page-title {
    display: none; }
  .section-matching-emploi .block {
    margin-bottom: 0; }
  .section-matching-emploi #main-wrapper {
    margin-bottom: 0; }
  .section-matching-emploi #footer .subFooter {
    margin-top: 0; }
  .section-matching-emploi #logo {
    left: 4%; }
  .section-matching-emploi #content {
    margin-top: 0; }
  .section-matching-emploi #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-matching-emploi #main {
        padding-top: 60px; } }
  .section-matching-emploi #mj-main-container {
    max-width: none; }
    .section-matching-emploi #mj-main-container .container-fluid, .section-matching-emploi #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-matching-emploi #mj-main-container .domains-block,
    .section-matching-emploi #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-matching-emploi #mj-main-container .regions-block,
    .section-matching-emploi #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-matching-emploi #mj-main-container .entreprises-block,
    .section-matching-emploi #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-matching-emploi #mj-main-container .domains-block,
    .section-matching-emploi #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-matching-emploi #mj-main-container .regions-block,
    .section-matching-emploi #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-matching-emploi #mj-main-container .entreprises-block, .section-matching-emploi #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-matching-emploi #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align: center; }
  .section-matching-emploi #mj-popin-wrapper #mj-popin {
    padding: 0; }
  .section-matching-emploi #matching-mj h2 {
    color: #16354E;
    font-size: 2.2em;
    font-weight: 600;
    line-height: initial; }
  .section-matching-emploi hr.separator {
    width: 130px;
    border-color: #16354E;
    border-top-width: 2px;
    margin: 30px auto; }
  .section-matching-emploi #matching-advantages {
    background: #fff; }
    .section-matching-emploi #matching-advantages h3 {
      color: #16354E;
      text-transform: uppercase;
      margin-bottom: 0; }
    .section-matching-emploi #matching-advantages p {
      color: #8B9AA7; }
    .section-matching-emploi #matching-advantages .timeline-container {
      top: -10px; }
    .section-matching-emploi #matching-advantages .timeline-container.icon-lt {
      float: left;
      left: -20px; }
    .section-matching-emploi #matching-advantages .timeline-container.icon-rt {
      float: right;
      right: -20px; }
    .section-matching-emploi #matching-advantages .timeline-icon i {
      height: 40px;
      line-height: 38px;
      width: 40px;
      font-size: 1.5625rem;
      border: 2px solid #8B9AA7;
      border-radius: 50%;
      color: #16354E;
      text-align: center;
      position: relative;
      z-index: 1;
      background: #fff; }
    .section-matching-emploi #matching-advantages .pos-lt {
      left: 30px; }
    .section-matching-emploi #matching-advantages .pos-rt {
      right: 30px; }
    .section-matching-emploi #matching-advantages .padd-40-lt {
      padding-left: 40px; }
    .section-matching-emploi #matching-advantages .last {
      border: none; }
  .section-matching-emploi #matching-how-to {
    background-color: #16354E; }
    .section-matching-emploi #matching-how-to h2, .section-matching-emploi #matching-how-to p {
      color: #fff; }
    .section-matching-emploi #matching-how-to b {
      font-weight: 700; }
    .section-matching-emploi #matching-how-to .col-sm-1 {
      width: 6.5%; }
    .section-matching-emploi #matching-how-to hr.separator {
      border-color: #16354E; }
  .section-matching-emploi #matching-technically {
    background-color: #16354E; }
    .section-matching-emploi #matching-technically h2, .section-matching-emploi #matching-technically p {
      color: #fff; }
    .section-matching-emploi #matching-technically p {
      text-align: justify; }
    .section-matching-emploi #matching-technically b {
      font-weight: 700; }
    .section-matching-emploi #matching-technically .col-sm-1 {
      width: 6.5%; }
    .section-matching-emploi #matching-technically hr.separator {
      border-color: #ffffff; }
    .section-matching-emploi #matching-technically .btn-info-light {
      border-radius: 0;
      line-height: initial; }
  .section-matching-emploi .punchline {
    width: 50%;
    float: right;
    text-align: center;
    margin: 4% 5% 0 0; }
  .section-matching-emploi #matching-mj .punchline h1 {
    font-size: 2.6em;
    font-weight: 300;
    color: #16354E;
    text-transform: uppercase;
    text-align: left;
    padding: 20px 0;
    border-top: 1px solid #16354E;
    border-bottom: 1px solid #16354E; }
  .section-matching-emploi .punchline p {
    font-size: 1.4em;
    color: #ffffff; }
  .section-matching-emploi .punchline a {
    color: #ffffff; }
  .section-matching-emploi #search-form {
    background-color: #16354E; }
    .section-matching-emploi #search-form .search-title {
      display: block;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 34px; }
    .section-matching-emploi #search-form .row {
      margin: 0; }

/*********************************************************/
/*                CV Upload                              */
/*********************************************************/
.section-matching-emploi .title,
.section-matching-emploi #ads-banner {
  display: none !important; }

.section-matching-emploi .region-content {
  margin: 0 auto; }

.section-matching-emploi #main-wrapper, .section-matching-emploi #content, .section-matching-emploi .region-content, #matching-mj #matching-header, #matching-mj #logos, #matching-mj #main-advantages {
  width: 100%; }

#matching-mj {
  background: #fff; }

body.section-matching-emploi.mj-role-company {
  padding-top: 0; }

.section-matching-emploi.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px; }

#matching-mj #matching-header .punchline {
  padding-top: 100px;
  padding-left: 100px; }

#matching-mj #matching-header h2 {
  color: #fff; }

#matching-mj #matching-header hr.separator {
  border-color: #00B5DD; }

#matching-mj #matching-header .btn-primary {
  width: 100%;
  font-weight: 600;
  padding: 15px;
  background-color: #00B5DD;
  border-color: #00B5DD;
  white-space: normal; }

#matching-mj #matching-header .btn-primary:hover {
  background-color: #16354E; }

.section-matching-emploi #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/matching/bg-header-matching-emploi.png) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color: #bc9278;
  min-height: 580px; }

@media (max-width: 992px) {
  /************ Landing page Offres emploi *************/
  .section-matching-emploi #mj-popin-wrapper #mj-popin {
    /*background-position: 15% 0!important;
      padding-bottom:0;*/ }
  .section-matching-emploi #mj-popin-wrapper.no-popin #mj-popin {
    min-height: auto; }
  .section-matching-emploi #search-form .col-md-3.form-group {
    padding: 0; } }

@media (max-width: 767px) {
  .section-matching-emploi #matching-mj #matching-header .punchline {
    width: auto;
    text-align: center;
    margin: 100px auto; }
  .section-matching-emploi #matching-mj #matching-header .btn-primary {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
  .section-matching-emploi .punchline h1, .section-matching-emploi .punchline h2 {
    font-size: 1.8em;
    text-align: center;
    text-shadow: 2px 2px 10px black; }
  .section-matching-emploi .punchline p {
    text-shadow: 2px 2px 10px black; }
  .section-matching-emploi #mj-popin-wrapper #mj-popin {
    padding: 120px 0 50px 0 !important;
    background-position: 0 !important; }
  .section-matching-emploi #mj-popin-wrapper.no-popin #mj-popin {
    padding-top: 0;
    min-height: auto; }
  .section-matching-emploi #matching-mj #matching-header .punchline {
    text-align: center;
    padding: 10px 25px;
    margin: 0 auto;
    float: none; }
  .section-matching-emploi #matching-mj .punchline h1 {
    text-align: center; }
  .section-matching-emploi #matching .float-lt, .section-matching-emploi #matching .float-rt {
    float: none;
    text-align: center;
    /*padding: 0;*/ }
  .section-matching-emploi #matching-advantages .pos-lt {
    left: initial; }
  .section-matching-emploi #matching-advantages .pos-rt {
    right: initial; }
  .section-matching-emploi #matching-advantages .padd-40-lt {
    padding-left: 10px;
    border: none; }
  .section-matching-emploi #matching-advantages .padd-40-rt {
    padding-right: 10px;
    border: none; }
  .section-matching-emploi #matching-advantages .timeline-container {
    padding-top: 40px; } }

@media (max-width: 767px) {
  .section-matching-emploi .no-padd-lt, .section-matching-emploi .no-padd-rt {
    padding: 0; }
  .section-matching-emploi .float-lt, .section-matching-emploi .float-rt {
    float: none;
    text-align: center; } }

.page-emploi-actualites #block-system-main .view-actualites-emplois .view-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 10px; }

.page-emploi-actualites #block-system-main h2 a {
  color: #16354E;
  text-transform: uppercase;
  font-size: 1.3125rem;
  font-weight: 400;
  text-align: left;
  text-decoration: none; }

.node-actualites .node-date {
  text-align: left;
  width: 30%;
  float: left;
  display: block; }
  .node-actualites .node-date .mj-icon, .node-actualites .node-date .fa, .node-actualites .node-date .ft {
    width: 20px; }

.node-date-small {
  text-align: left;
  width: 100%;
  margin: 1.2em 0;
  color: #666; }

.mj-social-share {
  display: block;
  height: 30px;
  line-height: 13px;
  margin-bottom: 2em;
  border-bottom: none; }

.mj-social-share > div {
  float: right;
  padding-left: 5px; }

.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title,
.node-type-actualites #block-system-main .title {
  border-bottom: none; }

.page-emploi-actualites-actualite .node-actualites .field-items .field-item.even img,
.node-type-actualites .node-actualites .field-type-image {
  padding-right: 0px; }

#actu-img-triangle {
  position: absolute;
  bottom: 0;
  left: 5%; }

#actu-img-bg {
  width: 100%;
  height: 350px;
  position: relative;
  margin-bottom: 1.2em; }

#actu-img-bg-small {
  border: none;
  width: 30%;
  height: 120px;
  margin: 0.8em 1.2em 1.2em 0;
  float: left;
  display: block;
  position: relative;
  line-height: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.img-actu-hover {
  background: url(/sites/cms.meteojob.com/themes/meteojob/assets/images/link.png) no-repeat center center;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 120px; }

.img-actu-hover:hover {
  opacity: 1;
  -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out; }

.bg-actu-hover {
  background-color: #FFF;
  width: 100%;
  height: 100%;
  opacity: 1; }

.bg-actu-hover:hover {
  opacity: 0.4; }

.view-taxonomie-links.view-display-id-actu_url_externe .views-row {
  font-size: 1.1em;
  background: #f2f2f2;
  padding: 5px 10px;
  margin-bottom: 5px; }

.view-taxonomie-links .views-row a {
  color: #16354E; }

.view-taxonomie-links .views-row .field-content::after {
  content: " "; }

.view-taxonomie-links .views-row:last-child .field-content::after {
  content: " "; }

ul.links.inline {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 20px 0 0 0;
  text-align: right; }

.node-type-actualites .content .btn-100 {
  width: 100%;
  margin: 15px 0 0; }

.view-actualites-emplois .node-actualites,
.view-actualites-archives .node-actualites,
.view-actualites-categories-archives .node-actualites,
.view-actualites-archives-emploi .node-actualites {
  text-align: left;
  margin: 20px 0px 0px 0px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px; }

.view-actualites-emplois .views-row-first .node-actualites,
.view-actualites-archives .views-row-first .node-actualites,
.view-actualites-categories-archives .views-row-first .node-actualites,
.view-actualites-archives-emploi .views-row-first .node-actualites {
  border-top: none;
  margin-top: 0;
  padding-top: 0; }

.node-date {
  margin-top: 0.5em; }

.view-display-id-bloc_premiere_actu .views-field-field-logo {
  width: 100%;
  height: 300px;
  position: relative;
  margin: 0 0 1.2em 0;
  border: none;
  line-height: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.page-emploi-actualites #block-system-main .view-id-actualites_emplois .view-content .views-field-path {
  padding-top: 10px; }

.view-empty .views-summary li a {
  font-weight: normal; }

.view-actualites-categories-archives .more-link,
.view-actualites-archives .more-link {
  text-align: left; }

.node-actualites .content, .node-actualites .content * {
  font-family: "DM Sans", Arial, sans-serif !important;
  font-size: 1rem !important; }

@media (max-width: 480px) {
  .node-actualites .node-date {
    width: 100%;
    float: none; } }

.section-partenaires .view-partenaires.view-display-id-page > .view-content .views-row, .section-partenaires .view-partenaires.view-display-id-block_a_la_une > .view-content .views-row {
  position: relative; }

.section-partenaires .view-partenaires.view-display-id-page > .view-content .views-field-field-textpartenaire, .section-partenaires .view-partenaires.view-display-id-block_a_la_une > .view-content .views-field-field-textpartenaire {
  float: none;
  width: auto;
  margin-right: 200px; }

.section-partenaires .view-partenaires.view-display-id-page > .view-content .views-field-field-logopartenaire, .section-partenaires .view-partenaires.view-display-id-block_a_la_une > .view-content .views-field-field-logopartenaire {
  position: absolute;
  top: 0;
  right: 0; }

.section-partenaires .view-partenaires .view-empty .view {
  padding-bottom: 10px; }

.section-partenaires .view-partenaires .view-header strong {
  font-size: 18px; }

.section-partenaires .view-partenaires .view-header a {
  font-weight: bold;
  color: #16354E; }

.section-partenaires .view-partenaires .view-header p {
  width: 100%;
  margin-top: 10px; }

.section-partenaires .view-partenaires .view-content .views-field-field-logopartenaire {
  float: right; }

.section-partenaires .view-partenaires .view-content .views-view-grid .views-field-field-logopartenaire {
  margin-left: 0px !important;
  float: none; }

.section-partenaires .view-partenaires .views-row {
  overflow: hidden;
  clear: both;
  margin-bottom: 15px; }

.section-partenaires .view-partenaires .view-id-partenaires_meteojob .views-row {
  clear: initial; }

.section-partenaires .view-partenaires .views-view-grid {
  width: 100%;
  text-align: center; }

.section-partenaires .view-partenaires .views-field-field-link-1 a {
  color: #16354E; }

.section-partenaires .view-partenaires a {
  color: #16354E; }

.section-partenaires .view-partenaires .views-field-field-link-1 {
  float: left;
  width: 430px; }

.section-partenaires .view-partenaires img {
  background-color: #FFF; }

.section-partenaires .view-partenaires .view-moteur-de-recherche img {
  border: none; }

.section-partenaires .view-partenaires .views-field-field-name .field-content {
  margin-bottom: 10px; }

.section-partenaires .view-partenaires .views-field-field-name .field-content,
.section-partenaires .view-partenaires .views-field-field-name .field-content a {
  font-size: 18px;
  text-decoration: none;
  color: #181818;
  text-transform: uppercase;
  font-weight: bold; }

.section-partenaires .view-partenaires .view-id-partenaires_meteojob img {
  max-height: 67px; }

.section-partenaires .region-sidebar-first .views-exposed-form .views-submit-button {
  display: none; }

.section-partenaires .node-lien-partenaire.search-result {
  padding-bottom: 20px; }

.section-partenaires .node-lien-partenaire.search-result h3 {
  font-size: 22px; }

.section-partenaires .node-lien-partenaire.search-result .field-name-field-logopartenaire,
.section-partenaires .view-display-id-page .views-field-field-logopartenaire,
.section-partenaires .view-display-id-block_partners .views-field-field-logo-1 {
  position: relative;
  display: inline-block;
  width: 145px;
  border: 1px solid #ccc;
  text-align: center;
  height: 87px;
  line-height: 64px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: #ccc 0px 0px 3px;
  -moz-box-shadow: #ccc 0px 0px 3px;
  box-shadow: #ccc 0px 0px 3px;
  background: #FFF;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#fff));
  background: -webkit-linear-gradient(#fff, #fff);
  background: -moz-linear-gradient(#fff, #fff);
  background: -ms-linear-gradient(#fff, #fff);
  background: -o-linear-gradient(#fff, #fff);
  background: linear-gradient(#fff, #fff);
  -pie-background: linear-gradient(#fff, #fff);
  margin: 0px 0px 10px 0px;
  padding: 10px; }

.section-partenaires .node-lien-partenaire.search-result .field-name-field-logopartenaire a,
.section-partenaires .view-display-id-page .views-field-field-logopartenaire a {
  display: block; }

.section-partenaires .node-lien-partenaire.search-result .field-name-field-textpartenaire,
.section-partenaires .view-display-id-page .views-field-field-textpartenaire {
  float: left;
  width: 430px;
  text-align: justify;
  margin-bottom: 10px; }

.section-partenaires .node-lien-partenaire.search-result .field-name-field-lienpart a {
  background: url("/images/v6/rightarrow-orange.png") no-repeat 0 50%;
  padding: 0 0 0 11px;
  margin-top: 20px; }

.section-partenaires .views-field-field-lienpart {
  margin-bottom: 10px; }

.section-partenaires .view-display-id-page .views-field-field-lienpart a {
  background: url("/images/v6/rightarrow-orange.png") no-repeat 0 50%;
  padding: 0 0 0 11px;
  margin-top: 20px; }

body.node-type-portail-arbo h1#page-title {
  display: none; }

/* Questions */
.section-questions h1#page-title {
  text-align: center;
  margin-bottom: 10px; }

.section-questions #breadcrumb {
  display: none; }

.section-questions #main .top-level-container {
  max-width: 860px; }

.section-questions #contact_accordion .show {
  display: none !important; }
  .section-questions #contact_accordion .show.in {
    display: block !important; }

.section-questions #contact_accordion h2.no-btn {
  text-transform: none;
  font-size: 1em;
  line-height: 1.5em; }

.section-questions #contact_accordion .btn-link {
  text-align: left;
  font-size: 0.8em;
  padding: 25px;
  line-height: 1.2em;
  white-space: normal; }
  .section-questions #contact_accordion .btn-link:after {
    font-family: 'FontAwesome';
    content: "\f106";
    float: right;
    font-size: xx-large;
    color: #ffffff; }
  .section-questions #contact_accordion .btn-link.collapsed:after {
    content: "\f107"; }

.section-questions #contact_accordion .sub-card-header h2 {
  margin-bottom: 0; }

.section-questions #contact_accordion .sub-card-header .btn-link {
  text-align: left;
  font-size: 0.7em; }
  .section-questions #contact_accordion .sub-card-header .btn-link:after {
    font-family: 'FontAwesome';
    content: "\f106";
    float: right;
    font-size: xx-large;
    color: #16354E; }
  .section-questions #contact_accordion .sub-card-header .btn-link.collapsed:after {
    content: "\f107"; }

.section-questions #contact_accordion .sub-card-border {
  border: 1px solid #B9C2CA;
  border-top-width: 0; }

.section-questions #contact_accordion .sub-card-body {
  padding: 15px;
  margin: 0 15px 15px 15px;
  background: #F0F4F7; }

.section-questions #contact_accordion .card-header {
  background-color: #16354E; }
  .section-questions #contact_accordion .card-header h2 {
    color: #ffffff;
    margin: 20px 0 0 0; }

.section-questions #contact_accordion .card-body .card-header {
  background-color: #ffffff;
  color: #16354E; }

.section-questions #formulaire-questions h2, .section-questions #formulaire-questions .submitted {
  display: none; }

.section-questions #formulaire-questions .form-control {
  min-height: 45px; }

.section-questions #formulaire-questions input[type=checkbox], .section-questions #formulaire-questions input[type=radio] {
  margin: 0 5px 0 0; }

.section-questions #formulaire-questions .form-select {
  height: 50px; }

.section-questions #formulaire-questions .textarea.bootstrap-tagsinput,
.section-questions #formulaire-questions .textarea.form-control,
.section-questions #formulaire-questions textarea {
  min-height: 100px !important; }

.section-questions #formulaire-questions .form-group {
  text-align: center; }

.section-questions #formulaire-questions .form-item,
.section-questions #formulaire-questions .form-actions {
  text-align: left; }

.section-questions #formulaire-questions .form-actions {
  display: inline-block;
  margin: auto;
  text-align: center; }

@media (max-width: 767px) {
  .section-questions #formulaire-questions .form-actions {
    display: block; } }

.section-qui-sommes-nous {
  padding-top: 0;
  /* PHOTO GRID */
  /* COMMON */ }
  .section-qui-sommes-nous h1#page-title {
    display: none; }
  .section-qui-sommes-nous .block {
    margin-bottom: 0; }
  .section-qui-sommes-nous #main-wrapper {
    margin-bottom: 0; }
  .section-qui-sommes-nous #footer .subFooter {
    margin-top: 0; }
  .section-qui-sommes-nous #logo {
    left: 4%; }
  .section-qui-sommes-nous #content {
    margin-top: 0; }
  .section-qui-sommes-nous #main {
    padding-top: 120px; }
    @media (max-width: 992px) {
      .section-qui-sommes-nous #main {
        padding-top: 60px; } }
  .section-qui-sommes-nous #mj-main-container {
    max-width: none; }
    .section-qui-sommes-nous #mj-main-container .container-fluid, .section-qui-sommes-nous #mj-main-container .cc-page-index-seo .domains-block, .cc-page-index-seo .section-qui-sommes-nous #mj-main-container .domains-block,
    .section-qui-sommes-nous #mj-main-container .cc-page-index-seo .regions-block, .cc-page-index-seo .section-qui-sommes-nous #mj-main-container .regions-block,
    .section-qui-sommes-nous #mj-main-container .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-qui-sommes-nous #mj-main-container .entreprises-block,
    .section-qui-sommes-nous #mj-main-container .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-qui-sommes-nous #mj-main-container .domains-block,
    .section-qui-sommes-nous #mj-main-container .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-qui-sommes-nous #mj-main-container .regions-block,
    .section-qui-sommes-nous #mj-main-container .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-qui-sommes-nous #mj-main-container .entreprises-block, .section-qui-sommes-nous #mj-main-container .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-qui-sommes-nous #mj-main-container #offres-emploi-header {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align: center; }
  .section-qui-sommes-nous #mj-popin-wrapper #mj-popin {
    padding: 0; }
  .section-qui-sommes-nous .btn {
    white-space: normal; }
  .section-qui-sommes-nous #about-us-mj h2 {
    color: #16354E;
    font-size: 2.2em;
    font-weight: 600;
    line-height: initial; }
  .section-qui-sommes-nous hr.separator {
    width: 130px;
    border-color: #16354E;
    border-top-width: 2px;
    margin: 30px auto; }
  .section-qui-sommes-nous #about-us-intro, .section-qui-sommes-nous #about-us-find-us {
    background: #fff; }
  .section-qui-sommes-nous #about-us-intro p {
    color: #8B9AA7;
    font-weight: 600; }
  .section-qui-sommes-nous #about-us-values {
    background: #f0f4f7; }
    .section-qui-sommes-nous #about-us-values h3 {
      color: #16354E;
      text-transform: uppercase; }
    .section-qui-sommes-nous #about-us-values .timeline-container {
      /*display:inline-block; position: relative;*/
      top: -10px; }
    .section-qui-sommes-nous #about-us-values .timeline-container.icon-lt {
      float: left;
      left: -20px; }
    .section-qui-sommes-nous #about-us-values .timeline-container.icon-rt {
      float: right;
      right: -20px; }
    .section-qui-sommes-nous #about-us-values .timeline-icon i {
      height: 40px;
      line-height: 38px;
      width: 40px;
      font-size: 25px;
      border: 2px solid #8B9AA7;
      border-radius: 50%;
      color: #16354E;
      text-align: center;
      position: relative;
      z-index: 1;
      background: #f0f4f7; }
    .section-qui-sommes-nous #about-us-values .pos-lt {
      left: 30px; }
    .section-qui-sommes-nous #about-us-values .pos-rt {
      right: 30px; }
    .section-qui-sommes-nous #about-us-values .padd-40-lt {
      padding-left: 40px;
      border-left-color: #8B9AA7;
      border-left-style: solid;
      border-left-width: 2px; }
    .section-qui-sommes-nous #about-us-values .padd-40-rt {
      padding-right: 40px;
      border-right-color: #8B9AA7;
      border-right-style: solid;
      border-right-width: 2px; }
    .section-qui-sommes-nous #about-us-values .last {
      border: none; }
  .section-qui-sommes-nous #about-us-figures {
    background: url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/about-us/bottom-clouds.png) no-repeat center bottom;
    background-color: #1585cd; }
    .section-qui-sommes-nous #about-us-figures h2 {
      color: #fff; }
    .section-qui-sommes-nous #about-us-figures hr.separator {
      border-color: #00B5DD; }
    .section-qui-sommes-nous #about-us-figures p {
      font-size: 1.2em;
      color: #100e13; }
    .section-qui-sommes-nous #about-us-figures p.figures {
      color: #fff;
      font-weight: 300;
      font-size: 2.9em;
      margin-bottom: 0;
      line-height: 1em; }
    .section-qui-sommes-nous #about-us-figures .container-fluid, .section-qui-sommes-nous #about-us-figures .cc-page-index-seo .domains-block, .cc-page-index-seo .section-qui-sommes-nous #about-us-figures .domains-block,
    .section-qui-sommes-nous #about-us-figures .cc-page-index-seo .regions-block, .cc-page-index-seo .section-qui-sommes-nous #about-us-figures .regions-block,
    .section-qui-sommes-nous #about-us-figures .cc-page-index-seo .entreprises-block, .cc-page-index-seo .section-qui-sommes-nous #about-us-figures .entreprises-block,
    .section-qui-sommes-nous #about-us-figures .section-offres-emplois-cadres .domains-block, .section-offres-emplois-cadres .section-qui-sommes-nous #about-us-figures .domains-block,
    .section-qui-sommes-nous #about-us-figures .section-offres-emplois-cadres .regions-block, .section-offres-emplois-cadres .section-qui-sommes-nous #about-us-figures .regions-block,
    .section-qui-sommes-nous #about-us-figures .section-offres-emplois-cadres .entreprises-block, .section-offres-emplois-cadres .section-qui-sommes-nous #about-us-figures .entreprises-block, .section-qui-sommes-nous #about-us-figures .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin #offres-emploi-header, .cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin .section-qui-sommes-nous #about-us-figures #offres-emploi-header {
      padding-bottom: 90px; }
  .section-qui-sommes-nous #about-us-news {
    background: #16354E; }
    .section-qui-sommes-nous #about-us-news h2 {
      color: #fff; }
    .section-qui-sommes-nous #about-us-news hr.separator {
      border-color: #16354E; }
    .section-qui-sommes-nous #about-us-news p {
      color: #B9C2CA; }
  .section-qui-sommes-nous #about-us-join-team {
    background: url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/about-us/bg-team.jpg) no-repeat center center;
    background-size: cover; }
    .section-qui-sommes-nous #about-us-join-team h2 {
      color: #fff; }
    .section-qui-sommes-nous #about-us-join-team hr.separator {
      border-color: #fff; }
    .section-qui-sommes-nous #about-us-join-team p {
      color: #00B5DD;
      font-weight: 600; }
    .section-qui-sommes-nous #about-us-join-team .frame {
      border: 2px solid #F0F4F7;
      padding: 30px; }
      .section-qui-sommes-nous #about-us-join-team .frame p {
        color: #fff;
        font-weight: 300; }
    .section-qui-sommes-nous #about-us-join-team .btn-primary {
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9em;
      padding: 15px;
      background-color: #00B5DD;
      border-color: #00B5DD;
      white-space: normal; }
    .section-qui-sommes-nous #about-us-join-team .btn-primary:hover {
      background-color: #16354E; }
  .section-qui-sommes-nous #about-us-team-pics {
    background: #5b7182; }
    .section-qui-sommes-nous #about-us-team-pics h2 {
      color: #fff; }
    .section-qui-sommes-nous #about-us-team-pics hr.separator {
      border-color: #00B5DD; }
  .section-qui-sommes-nous #about-us-team-pics img {
    width: 100%; }
  .section-qui-sommes-nous .punchline {
    float: left;
    text-align: left;
    margin: 4% 5% 0 0; }
  .section-qui-sommes-nous #about-us-mj .punchline h1 {
    font-size: 2.6em;
    font-weight: 300;
    color: #16354E;
    text-transform: uppercase;
    text-align: left;
    padding: 20px 0;
    border-top: 1px solid #16354E;
    border-bottom: 1px solid #16354E; }
  .section-qui-sommes-nous .punchline p {
    font-size: 1.4em;
    color: #ffffff; }
  .section-qui-sommes-nous .punchline a {
    color: #ffffff; }
  .section-qui-sommes-nous .photo-grid {
    margin: 1em auto;
    max-width: 1070px;
    text-align: center; }
  .section-qui-sommes-nous .photo-grid .city-pict {
    padding: 5px; }
  .section-qui-sommes-nous .photo-grid .figure {
    height: auto;
    margin: 0;
    overflow: hidden;
    position: relative; }
  .section-qui-sommes-nous .photo-grid img {
    display: block;
    height: auto;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    max-width: 100%; }
  .section-qui-sommes-nous .photo-grid .city-pict:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1); }
  .section-qui-sommes-nous .photo-grid .city-pict:hover .figcaption {
    opacity: 1; }
  .section-qui-sommes-nous .photo-grid .figcaption p {
    display: table-cell;
    font-size: 1.8em;
    font-weight: 600;
    position: relative;
    bottom: 10px;
    width: 100%;
    height: 260px;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    vertical-align: middle;
    text-transform: uppercase;
    background: url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/icons/mapmarker.png) no-repeat center 60px;
    padding-top: 15px; }
  .section-qui-sommes-nous .photo-grid .figcaption span {
    text-transform: lowercase;
    font-weight: 600;
    font-size: 0.6em;
    color: #16354E;
    width: 100%;
    position: absolute;
    left: 0;
    /*bottom: 18px;*/ }
  .section-qui-sommes-nous .photo-grid .figcaption span b {
    font-weight: 800; }
  .section-qui-sommes-nous .photo-grid .city-pict:hover .figcaption p {
    -moz-transform: translateY(40px);
    -webkit-transform: translateY(40px);
    transform: translateY(40px); }
  .section-qui-sommes-nous .photo-grid .figcaption {
    background: rgba(21, 133, 205, 0.6);
    color: white;
    display: table;
    height: 100%;
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
    -webkit-transition-delay: 100ms;
    -moz-transition-delay: 100ms;
    transition-delay: 100ms;
    z-index: 100; }
  .section-qui-sommes-nous .float-none {
    float: none;
    margin: 0 auto; }
  .section-qui-sommes-nous .float-lt {
    float: left;
    text-align: right; }
  .section-qui-sommes-nous .float-rt {
    float: right;
    text-align: left; }
  .section-qui-sommes-nous .no-padd-lt {
    padding-left: 0; }
  .section-qui-sommes-nous .no-padd-rt {
    padding-right: 0; }
  .section-qui-sommes-nous .padd-4-px {
    padding: 0 4px; }

/*********************************************************/
/*                Who Page                              */
/*********************************************************/
.section-qui-sommes-nous .title,
.section-qui-sommes-nous #ads-banner {
  display: none !important; }

.section-qui-sommes-nous .region-content {
  margin: 0 auto; }

.section-qui-sommes-nous #main-wrapper, .section-qui-sommes-nous #content, .section-qui-sommes-nous .region-content, #about-us-mj #about-us-header, #about-us-mj #logos, #about-us-mj #main-advantages, #about-us-mj #domains, #about-us-mj #media {
  width: 100%; }

#about-us-mj {
  background: #fff;
  /*padding: 50px 0;*/ }

#about-us-mj #main-advantages {
  background: #eeeeee; }

#about-us-mj #emplois-domaines, #about-us-mj #emplois-entreprises {
  background: #f9f9f9; }

#about-us-mj .main-advantages-block, #about-us-mj .domains-block, #about-us-mj .media-block, #about-us-mj .entreprises-block, #about-us-mj .regions-block {
  width: 70%;
  margin: 0 auto; }

body.section-qui-sommes-nous.mj-role-company {
  padding-top: 0; }

.section-qui-sommes-nous.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px; }

#about-us-mj #about-us-header .punchline {
  /* width:50%; 
  padding: 190px 100px*/
  padding-top: 190px;
  padding-left: 100px; }

.section-qui-sommes-nous #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(/sites/cms.meteojob.com/themes/meteojob/assets/images/_/about-us/bg-header-about-us.jpg) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color: #335e91;
  min-height: 580px; }

@media (max-width: 992px) {
  /************ Landing page Offres emploi *************/
  .section-qui-sommes-nous #mj-popin-wrapper #mj-popin {
    /*background-position: 15% 0!important;
        padding-bottom:0;*/ }
  .section-qui-sommes-nous #mj-popin-wrapper.no-popin #mj-popin {
    min-height: auto; } }

@media (max-width: 767px) {
  .section-qui-sommes-nous #about-us-mj #about-us-header .punchline {
    width: auto;
    text-align: center;
    margin: 100px auto; }
  .section-qui-sommes-nous .punchline h1, .section-qui-sommes-nous .punchline h2 {
    font-size: 1.8em;
    text-align: center; }
  .section-qui-sommes-nous #mj-popin-wrapper #mj-popin {
    padding: 120px 0 50px 0 !important;
    background-position: 0 !important; }
  .section-qui-sommes-nous #mj-popin-wrapper.no-popin #mj-popin {
    /* background: none;
      background-color:#b3c6df;*/
    padding-top: 0;
    min-height: auto; }
  .section-qui-sommes-nous #about-us-mj #about-us-header .punchline {
    /* margin: 5% 0 0 2%;*/
    text-align: center;
    padding: 10px 25px;
    margin: 0 auto;
    float: none; }
  .section-qui-sommes-nous #about-us-mj .punchline h1 {
    text-align: center; }
  .section-qui-sommes-nous #about-us .float-lt, .section-qui-sommes-nous #about-us .float-rt {
    float: none;
    text-align: center;
    /*padding: 0;*/ }
  .section-qui-sommes-nous #about-us-values .pos-lt {
    left: initial; }
  .section-qui-sommes-nous #about-us-values .pos-rt {
    right: initial; }
  .section-qui-sommes-nous #about-us-values .padd-40-lt {
    padding-left: 10px;
    border: none; }
  .section-qui-sommes-nous #about-us-values .padd-40-rt {
    padding-right: 10px;
    border: none; }
  .section-qui-sommes-nous #about-us-values .timeline-container {
    padding-top: 40px; } }

@media (min-width: 768px) {
  .section-qui-sommes-nous .photo-grid .city-pict {
    width: auto; } }

@media (max-width: 767px) {
  .section-qui-sommes-nous .no-padd-lt, .section-qui-sommes-nous .no-padd-rt {
    padding: 0; }
  .section-qui-sommes-nous .photo-grid img {
    width: 100%;
    max-height: 260px; }
  .section-qui-sommes-nous .float-lt, .section-qui-sommes-nous .float-rt {
    float: none;
    text-align: center; } }

.d-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .d-flex .left {
    order: 1; }
  .d-flex .right {
    order: 2; }
  .d-flex .center {
    align-items: center;
    justify-content: center; }

/** Page d'accueil */
#mj-logos .top-level-container {
  overflow: hidden; }

#mj-logos .line {
  position: relative; }

#mj-logos .line-logos {
  width: 999999px;
  height: 100px;
  margin: 0 0 10px 0; }
  #mj-logos .line-logos > div,
  #mj-logos .line-logos .view,
  #mj-logos .line-logos .view .views-row {
    float: left; }

#mj-logos .logo {
  display: inline-block;
  height: 80px;
  line-height: 80px;
  margin: 0 0 5px 25px;
  text-decoration: none; }
  #mj-logos .logo img {
    width: 120px;
    height: 71px; }

#mj-main-categories .line-categories {
  position: relative; }
  #mj-main-categories .line-categories #mj-categories {
    min-height: 250px;
    margin-right: 310px; }
  #mj-main-categories .line-categories #mj-pave {
    position: absolute;
    width: 300px;
    height: 250px;
    top: 0;
    right: 0; }
  @media (max-width: 768px) {
    #mj-main-categories .line-categories #mj-categories {
      margin-right: 0;
      margin-bottom: 20px; }
    #mj-main-categories .line-categories #mj-pave {
      position: initial;
      top: initial;
      right: initial;
      margin: 0 auto; } }

#mj-main-news .title {
  border-bottom: 1px solid #16354E;
  padding-bottom: 20px; }

#mj-main-news #mj-last-news {
  padding: 15px 20px; }
  #mj-main-news #mj-last-news .news-title {
    font-size: 1.2em; }

#mj-main-news .row {
  margin-bottom: 10px; }

#mj-main-news .more-link {
  display: block;
  float: right;
  font-weight: bold; }

/* Region content bottom homepage and landing pages */
#hp_social h3, #hp_social a {
  color: #ffffff; }

#hp_social a:hover {
  color: #F0F4F7; }

#hp_social .social-left {
  text-align: left; }

#hp_social .social-right {
  text-align: right; }

#hp_social .fa, #hp_social .ft {
  font-size: 1.4em; }

/* COMMON */
.float-none {
  float: none;
  margin: 0 auto; }

.float-lt {
  float: left;
  text-align: right; }

.float-rt {
  float: right;
  text-align: left; }

.no-padd-lt {
  padding-left: 0; }

.no-padd-rt {
  padding-right: 0; }

.padd-4-px {
  padding: 0 4px; }

.btn-white {
  color: #16354E;
  background-color: #fff;
  border-color: #f2f2f2;
  font-weight: 600; }

.btn-white:hover {
  color: #16354E;
  background-color: #f2f2f2;
  border-color: #f8f8f8; }

.btn-white:focus {
  color: #16354E;
  background-color: #f8f8f8;
  border-color: #f2f2f2; }

.btn-white:active {
  color: #16354E;
  background-color: #f8f8f8;
  border-color: #f2f2f2; }

.btn-tertiary {
  color: #000000;
  background-color: #FFCC00;
  border-color: #FFCC00;
  font-weight: 600; }

.btn-tertiary:hover {
  color: #000000;
  background-color: #FFCC00;
  border-color: #FFCC00; }

.btn-tertiary:focus {
  color: #000000;
  background-color: #FFCC00;
  border-color: #FFCC00; }

.btn-tertiary:active {
  color: #000000;
  background-color: #FFCC00;
  border-color: #FFCC00; }

.btn-quaternary {
  color: #ffffff;
  background-color: #62BA88;
  border-color: #429867;
  font-weight: 600; }

.btn-quaternary:hover {
  color: #ffffff;
  background-color: #429867;
  border-color: #307950; }

.btn-quaternary:focus {
  color: #ffffff;
  background-color: #307950;
  border-color: #429867; }

.btn-quaternary:active {
  color: #ffffff;
  background-color: #307950;
  border-color: #429867; }

a.brand-quaternary {
  color: #62BA88; }
  a.brand-quaternary:hover {
    color: #429867; }
  a.brand-quaternary:focus, a.brand-quaternary:active {
    color: #307950; }

@media (max-width: 767px) {
  #hp_social .social-left {
    text-align: center; }
  #hp_social .social-right {
    text-align: center; }
  .d-flex {
    display: block;
    margin: 0 !important; } }

.media-logo {
  display: inline-block;
  margin: 10px; }

.media-logo img {
  height: auto;
  margin: 15px;
  max-width: 200px;
  max-height: 55px; }

#hp_medias .media-logo img {
  filter: grayscale(100%); }
  #hp_medias .media-logo img:hover {
    filter: none; }
