.section-partenaires {

  .view-partenaires {
    &.view-display-id-page,
    &.view-display-id-block_a_la_une {
      & > .view-content {
        .views-row {
          position: relative;
        }

        .views-field-field-textpartenaire {
          float: none;
          width: auto;
          margin-right: 200px;
        }

        .views-field-field-logopartenaire {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .view-empty .view {
      padding-bottom:10px;
    }
    .view-header {
      strong {
        font-size: 18px;
      }
      a {
        font-weight: bold;
        color: $brand-primary;
      }
      p {
        width: 100%;
        margin-top: 10px;
      }
    }
    .view-content {
      .views-field-field-logopartenaire {
        float:right;
      }
      .views-view-grid .views-field-field-logopartenaire {
        margin-left: 0px !important;
        float: none;
      }
    }
    .views-row  {
      overflow:hidden;
      clear:both;
      margin-bottom:15px;
    }
    .view-id-partenaires_meteojob .views-row  {
      clear:initial;
    }
    .views-view-grid  {
      width:100%;
      text-align:center;
    }
    .views-field-field-link-1 a {
      color:$brand-primary;
    }
    a {
      color:$brand-primary;
    }
    .views-field-field-link-1 {
      float:left;
      width:430px;
    }
    img {
      background-color:#FFF;
    }
    .view-moteur-de-recherche img {
      border:none;
    }
    .views-field-field-name .field-content  {
      margin-bottom:10px;
    }
    .views-field-field-name .field-content,
    .views-field-field-name .field-content a  {
      font-size:18px;
      text-decoration:none;
      color:#181818;
      text-transform: uppercase;
      font-weight: bold;
    }
    .view-id-partenaires_meteojob img {
      max-height:67px;
    }
  }

  .region-sidebar-first .views-exposed-form .views-submit-button {
    display:none;
  }
  .node-lien-partenaire.search-result {
    padding-bottom:20px;
  }
  .node-lien-partenaire.search-result h3  {
    font-size:22px;
  }
  .node-lien-partenaire.search-result .field-name-field-logopartenaire,
  .view-display-id-page .views-field-field-logopartenaire,
  .view-display-id-block_partners .views-field-field-logo-1 {
    position:relative;
    display:inline-block;
    width:145px;
    border:1px solid #ccc;
    text-align:center;
    height:87px;
    line-height:64px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: #ccc 0px 0px 3px;
    -moz-box-shadow: #ccc 0px 0px 3px;
    box-shadow: #ccc 0px 0px 3px;
    background: #FFF;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#fff));
    background: -webkit-linear-gradient(#fff, #fff);
    background: -moz-linear-gradient(#fff, #fff);
    background: -ms-linear-gradient(#fff, #fff);
    background: -o-linear-gradient(#fff, #fff);
    background: linear-gradient(#fff, #fff);
    -pie-background: linear-gradient(#fff, #fff);
    margin:0px 0px 10px 0px;
    padding: 10px;
  }
  .node-lien-partenaire.search-result .field-name-field-logopartenaire a,
  .view-display-id-page .views-field-field-logopartenaire a {
    display:block;
  }
  .node-lien-partenaire.search-result .field-name-field-textpartenaire,
  .view-display-id-page .views-field-field-textpartenaire   {
    float:left;
    width:430px;
    text-align:justify;
    margin-bottom:10px;
  }
  .node-lien-partenaire.search-result .field-name-field-lienpart a {
    background:url("/images/v6/rightarrow-orange.png") no-repeat 0 50%;
    padding:0 0 0 11px;
    margin-top:20px;
  }
  .views-field-field-lienpart   {
    margin-bottom:10px;
  }
  .node-lien-partenaire.search-result .field-items a  {
  }
  .view-display-id-page .views-field-field-lienpart a {
    background:url("/images/v6/rightarrow-orange.png") no-repeat 0 50%;
    padding:0 0 0 11px;
    margin-top:20px;
  }
}