._block-title {
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  color: $brand-primary;
  border-bottom: 2px solid $brand-primary;
}

.section-trouver-un-emploi,
.section-emplois-par-region,
.section-emplois-par-entreprise,
.section-conseils-emploi {
  #block-system-main {
    .row,
    .row-fluid {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.section-trouver-un-emploi {
  #page-title {
    display: none;
  }
  .view-header {
    h1 {
      @extend ._block-title;
    }
    hr {
      display: none;
    }
  }
}

.section-emplois-par-region {
  #page-title {
    display: none;
  }

  #block-system-main .view-browsecities-cities > .view-footer {
    margin-top: 40px;
  }

  .view-header {
    h1 {
      @extend ._block-title;
    }
    hr {
      display: none;
    }
  }
}

.section-emplois-par-entreprise {
  h1#page-title {
    @extend ._block-title;
  }
  .view-browsecompanies a {
    color: inherit;
  }
}

.section-conseils-emploi {
  h1.title {
    @extend ._block-title;
  }
  #page-title,
  hr {
    display: none;
  }
  .field-name-field-item-link a {
    color: inherit;
  }
}

.view-browsejobs,
.view-browsejobs-region,
.view-browsecities-cities,
.view-browsecities-departements {
  .views-field-body {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;

      & > .row > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.view-browsecities-regions {
  .views-field-body {
    .container-fluid {

      & > .row > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
