.section-emploi {
  padding-top:0;
  h1#page-title {display: none;}
  .block {margin-bottom:0;}
  #main-wrapper {margin-bottom:0;}
  #footer .subFooter {margin-top:0;}
  #logo {left:4%;}
  #content {margin-top:0;}

  /* HEADER */

  #header {
    &.navbar-fixed-bottom,
    &.navbar-fixed-top {
      position: absolute;
    }
  }

  &.company {
    #header-top-container {
      .header-links {
        font-size: inherit;
        text-transform: inherit;
      }
    }
  }

  #header-sticky-container {
    .header-recruiter-zone-unlogged {
      li {
        padding: 0 $spacing-lg;
      }

      .child-separator {
        padding: 0;
        color: $text-color-light;
      }

      img {
        max-width: 30px;
        margin-right: 10px;
        margin-top: -3px;
      }
    }
  }

  #main {
    padding-top: ($header-top-height + $header-bottom-height);
    @media (max-width: $screen-md) {
      padding-top: $header-top-height;
    }
  }

  #mj-main-container {
    max-width: none;
    .container-fluid {
      padding-top: 50px;
      padding-bottom: 50px;
      // text-align:center;
    }
  }

  #mj-popin-wrapper #mj-popin {padding:0}

  hr.separator{width:130px; border-color:$brand-primary; border-top-width:2px; margin:30px auto;}

  #recruiters-testimonials {
    background:#5b7182;
    h2 {color:#fff;}
    hr.separator {border-color:$brand-primary}
    p {color: #fff;}
    .bubble {
      margin: 5px 5px 30px 5px;
      background: $gray-light;
      min-height: 170px;
      border-radius: 15px!important;
      position: relative;
      text-align: center;
      &::after {
        left: 50%;
        top: 100%;
        border: solid transparent;
        border-top-color: $gray-light;
        border-width: 7px;
        margin-left: -7px;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
      }
      p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 30px;
      }
    }
    .picture {
      text-align: center;
      img {
        border-radius: 50%;
      }
    }
    .btn-primary {
      width: 100%;
      font-weight: 600;
      padding: 15px;
      background-color: $brand-primary;
      border-color: $brand-primary;
      white-space: normal;
    }
    .btn-primary:hover {
      background-color: $brand-primary
    }
  }
  #recruiters-products {
    background: $gray-bright;
    h2 {
      color:$text-color;
    }
    p {
      text-align: center;
      font-weight: bold;
      color: $gray;
    }
    .pricing {
      background-color: #ffffff;
      margin-bottom: 20px;
      padding: 20px;
      transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
      .figure {
        font-size: 2rem;
      }
      h3 {
        text-transform: uppercase;
        color: $brand-primary;
        font-size: 1.5rem;
        text-align: center;
        &:after {
          background: none repeat scroll 0 0 $brand-primary;
          bottom: -15px;
          content: "";
          display: block;
          height: 3px;
          position: relative;
          width: 40px;
          margin: 0 auto 35px auto;
        }
      }
      p {
        color: inherit;
      }
      .btn {
        width: 100%;
      }
    }
  }

  .title,
  #ads-banner {
    display:none!important;
  }
  .region-content {
    margin: 0 auto;
  }
  #main-wrapper,
  #content,
  .region-content,
  #recruiter-mj #logos {
    width:100%;
  }
  .mj-role-candidate.mj-role-registered #mj-user-navigation {
    position: absolute;
    top: 25px;
    left: 80px;
  }
  #recruiter-mj {
    background:#fff;
    h2 {
      font-size: 2.2em;
      font-weight: 600;
      line-height: initial;
      text-align: center;
    }
    .punchline {
      width:75%;
      text-align:center;
      padding: 10% 25px;
      margin: 5% auto 0 auto;
      float: none;
      h1 {
        font-size: 2.6em;
        font-weight: 600;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align:center;
      }
      h2 {
        font-size: 2.6em;
        color:#ffffff;
        text-transform:uppercase;
        padding: 20px 0;
      }
      p {
         font-size: 1.4em;
         color:#ffffff;
      }
      a {
          color:#ffffff;
      }
      i {
        height: 40px;
        line-height: 38px;
        width: 40px;
        font-size: prem(25px);
        border: 2px solid $brand-primary;
        border-radius: 50%;
        color: #FFFFFF;
        text-align: center;
        position: relative;
        z-index: 1;
        background: none;
      }
    }
    #logos {
      background-color: #ffffff;
      height: 100px;
      .logo {
        margin: 0px 0px 5px 25px;
        background: none;
      }
    }
  }
  /* LOGOS BLOCK */
  #recruiter-mj {
    #logos {
      background-color:#ffffff;
      height:100px;
      // float:left;
    }
    #hpSpe {
      #hpLogos {
        position: absolute;
        width: 999999px;
        .view, .view .views-row {
          float: left;
        }
      }
      #hpLogos > div {
        float: left;
      }
      .line-logos {
        height: 90px;
        margin: 10px 0;
        overflow: hidden;
      }
      .line {
        position: relative;
      }
    }
    #randomLogoBlock {
      float: left;
    }
    #randomLogoBlock, #block-views-logos-all-small-block {
      height: 80px;
      overflow: hidden;
    }
    .logo {
      margin: 0 0 5px 25px;
      background: none;
      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        &:hover {
          -webkit-filter: none;
          filter: none;
        }
      }
    }
  }
  /* CONTACT FORM */
  .region-content-bottom {
    background: $gray-bright;
    .mj-block {
      background-color: transparent;
    }
  }
  #contact-form {
    h2 {
      color: $text-color;
      font-size: 2.2em;
      font-weight: 600;
      line-height: initial;
      text-align: center;
    }
    .fa, .ft {
      color: $brand-primary;
    }
    p {
      a {
        color: $text-color;
      }
    }
  }
  #recruter-en-ligne-form {
    h2,
    .submitted {
      display:none;
    }
  }
  .hbspt-form {
    .hs-error-msg,
    .hs-error-msgs {
      color: $brand-info;
      padding: 0;
      list-style-type: none;
    }
    .hs-form-field {
      margin-bottom: 15px;
    }
    .hs-input {
      display: block;
      width: 100%;
      height: 56px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: $gray-light;
      background-image: none;
      box-shadow : none;
      background-color: #ffffff;
      border: 1px solid #ccc;
    }
    textarea.hs-input {
      height: 115px;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-light;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $gray-light;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $gray-light;
    }
    input[type=submit] {
      float: right;
      padding: 15px 50px;
      min-width: 160px;
      min-height: 56px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 1.4em;
      width: 100%;
      color: #ffffff;
      border-radius: 30px;
      background-color: $brand-primary-alt;
      border-color: darken($brand-primary-alt, 5%);
      &:hover {
        background-color: darken($brand-primary-alt, 5%);
        border-color: darken($brand-primary-alt, 15%);
      }
      &:active:focus {
        background-color: darken($brand-primary-alt, 15%);
        border-color: darken($brand-primary-alt, 20%);
      }
    }
    .submitted-message {
      padding-bottom: 20px;
    }
  }

  .webform-client-form {
    .submitted {
      display: none;
    }
    .form-text {
      height: 40px;
    }
    .form-text, .form-select, .form-textarea {
      display: block;
      width: 100%;
      height: 56px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: $gray-light;
      // background-color: #ffffff;
      background-image: none;
      border-radius: 2px;
      box-shadow : none;
      // border: none;
    }
    .form-textarea {
      height: auto;
    }
    .webform-component-markup p {
      font-size: 14px;
      color: $gray-light;
    }
    .form-checkbox {
      float: left;
      width: 5%;
      margin-bottom: 5px;
    }
    .option {
      width: 95%;
      float: right;
      font-size: 14px;
      color: $gray-light;
      text-align: left;
    }
    .btn {
      float: right;
      padding: 15px 50px;
      min-width: 160px;
      min-height: 56px;
      margin-top: 60px;
      font-weight: bold;
      font-size: 1.4em;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-light;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $gray-light;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $gray-light;
    }
  }
  /**
 * CAPTCHA
*/
  .captcha .fieldset-description,
  .captcha .form-item-captcha-response .description,
  .captcha legend {
    display: none;
  }
  .captcha .fieldset-wrapper img, .captcha #edit-captcha-response {
    float:left;
  }
  .form-item-captcha-response label {
    float:right;
    margin-top: 5px;
  }
  .form-wrapper {
    display: block;
    float: left;
    width: 100%;
    margin-bottom:10px;
  }
  .reload-captcha-wrapper {
    text-align:left;
  }
  .reload-captcha {
    float:left;
    padding-top: 10px;
  }
}

body.section-emploi.mj-role-company{
  padding-top:0;
}

@media (max-width: 992px){

  .section-emploi {
    #mj-popin-wrapper.no-popin #mj-popin {
      min-height:auto
    }
    #search-form {
      .col-md-3.form-group {padding:0;}
    }
  }
}

@media (max-width: 767px) {

  .section-emploi {
    #recruiter-mj {
      .btn-primary {
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
      }
      .punchline {
        width:90%;
        text-align: center;
        margin: 100px auto;
        padding:10px 25px;
        float: none;
        h1,
        h2 {
          font-size: 1.8em;
          text-align: center;
          text-shadow: 2px 2px 10px black;
        }
        p {
          text-shadow: 2px 2px 10px black;
        }
      }
      .float-lt,
      .float-rt {
        float:none;
        text-align:center;
      }
    }
    #mj-popin-wrapper #mj-popin {
      padding: 120px 0 50px 0!important;
      background-position: 0!important;}

    #mj-popin-wrapper.no-popin #mj-popin {
      padding-top:0;
      min-height: auto;
    }
    #recruiters-testimonials {
      .bubble {
        min-height: auto;
        p {
          position: initial;
          transform: none;
        }
      }
    }
    .webform-client-form {
      .btn {
        float: none;
        width: 100%;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {

  .section-emploi {
    .no-padd-lt, .no-padd-rt {padding:0}
    .float-lt, .float-rt {float:none; text-align:center;}
  }
}
