.section-deposer-son-cv {
  padding-top:0;
  h1#page-title {display: none;}
 .block {margin-bottom:0;}
  #main-wrapper {margin-bottom:0;}
  #footer .subFooter {margin-top:0;}
  #logo {left:4%;}
  #content {margin-top:0;}

  #main {
    padding-top: ($header-top-height + $header-bottom-height);
    @media (max-width: $screen-md) {
      padding-top: $header-top-height;
    }
  }

  #mj-main-container {
    max-width: none;  
    .container-fluid {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align:center;
    }
  }
  
  #mj-popin-wrapper #mj-popin {padding:0}
  
  #cv-upload-mj h2 {color:$text-color; font-size: 2.2em; font-weight: 600; line-height: initial;}
  
  hr.separator{width:130px; border-color:$brand-primary; border-top-width:2px; margin:30px auto;}

  #cv-upload-logos {
    .container-fluid {padding:10px}
    p {text-align: left}
  }
  .line-logos {height: 80px; margin: 0 0 10px 0; overflow: hidden;}
  .line {position: relative;}
  #mj-logos {position: absolute; width: 999999px;}
  #mj-logos .view, #mj-logos .view .views-row, #mj-logos>div {float: left;}

  #cv-upload-timeline, #cv-upload-find-us {
    background:#fff;
  }

  #cv-upload-timeline {
    h3 {color:$brand-primary; text-transform:uppercase;}
    p {color:$gray-light;}
    .timeline-container {top: -10px;}
    .timeline-container.icon-lt {float: left; left:-20px;}
    .timeline-container.icon-rt {float: right; right:-20px;}
    .timeline-icon i {height: 40px; line-height: 38px; width: 40px; font-size: prem(25px); border: 2px solid $gray-light;
                      border-radius: 50%; color: $brand-primary;text-align: center; position: relative; z-index: 1;
                      background:#fff;}
    .pos-lt{left:30px}
    .pos-rt{right:30px;}
    .padd-40-lt {padding-left:40px; border-left-color: $gray-light; border-left-style: solid; border-left-width: 2px;}
    .padd-40-rt {padding-right:40px; border-right-color: $gray-light; border-right-style: solid; border-right-width: 2px;}
    .last {border:none;}
  }
  #cv-upload-offers {
    background-color:$brand-primary;
    h2 {
      span {font-size:1.4em}
      color:#fff;
      margin-bottom:0;
    }
    .timeline-icon i {height: 40px; line-height: 38px; width: 40px; font-size: prem(25px); border: 2px solid #fff;
                      border-radius: 50%; color: #fff;text-align: center; position: relative; z-index: 1;
                      background:$brand-primary}
  }
  #cv-upload-search {
    .container-fluid {padding-top: 15px; padding-bottom: 15px;}
  }
  #cv-upload-how-to {
    background:url(#{$mj-images-path}/_/cv-upload/bottom-clouds.png) no-repeat center bottom;
    background-color:$text-color;
    h2, p {color:#fff;}
    b {font-weight: 700}
    .col-sm-1 {width: 6.5%;}
    hr.separator {border-color:$brand-primary}
  }

  .punchline {
    width:50%;
    float:right;
    text-align:center;
    margin: 4% 5% 0 0;
  }
  
  #cv-upload-mj .punchline h1 {
    font-size: 2.6em;
    font-weight:300;
    color:$text-color;
    text-transform:uppercase;
    text-align:left;
    padding: 20px 0;
    border-top: 1px solid $text-color;
    border-bottom: 1px solid $text-color;
  }
  .punchline p {
    font-size: 1.4em;
    color:#ffffff;
  }
  .punchline a {
    color:#ffffff;
  }

  #search-form {
    background-color: $text-color;
    .search-title {display:block; font-weight:bold; color:#fff; text-align:center; line-height:34px;}
    .row{margin:0}
  }
   
  
}
/*********************************************************/
/*                CV Upload                              */
/*********************************************************/
.section-deposer-son-cv .title,
.section-deposer-son-cv #ads-banner {
  display:none!important;
}
.section-deposer-son-cv .region-content {
  margin: 0 auto;
}

.section-deposer-son-cv #main-wrapper, .section-deposer-son-cv #content, .section-deposer-son-cv .region-content, #cv-upload-mj #cv-upload-header, #cv-upload-mj #logos, #cv-upload-mj #main-advantages, #cv-upload-mj #domains, #cv-upload-mj #media {
  width:100%;
}
#cv-upload-mj {
  background:#fff;
  /*padding: 50px 0;*/
}
#cv-upload-mj #main-advantages {
  background:#eeeeee;
}
#cv-upload-mj #emplois-domaines, #cv-upload-mj #emplois-entreprises {
  background:#f9f9f9;
}
#cv-upload-mj .main-advantages-block, #cv-upload-mj .domains-block, #cv-upload-mj .media-block, #cv-upload-mj .entreprises-block, #cv-upload-mj .regions-block {
  width:70%;
  margin:0 auto;
}
body.section-deposer-son-cv.mj-role-company{
  padding-top:0;
}
.section-deposer-son-cv.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px;
}
#cv-upload-mj #cv-upload-header {
  .punchline {padding-top: 100px; padding-left: 100px;}
  h2 {color: #fff;}
  hr.separator {border-color:$brand-primary-alt}
  .btn-primary {width:100%; font-weight: 600; padding: 15px; background-color:$brand-primary-alt; border-color:$brand-primary-alt; white-space: normal;}
  .btn-primary:hover {background-color:$brand-primary}
  }
  .section-deposer-son-cv #mj-popin-wrapper.no-popin #mj-popin {
    background: transparent url(#{$mj-images-path}/_/cv-upload/bg-header-cv-upload.png) no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-color:#80a2b1;
    min-height: 580px;
  }

  @media (max-width: 992px){

    /************ Landing page Offres emploi *************/
    .section-deposer-son-cv {
      #mj-popin-wrapper #mj-popin {
      }
      #mj-popin-wrapper.no-popin #mj-popin {
        min-height:auto
      }
      #search-form {
        .col-md-3.form-group {padding:0;}
      }
    }
  }

  @media (max-width: 767px) {

    .section-deposer-son-cv {

      #cv-upload-mj #cv-upload-header {
        .punchline {width:auto; text-align:center; margin: 100px auto; }
        .btn-primary {box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);}
      }
      .punchline h1, .punchline h2 {
        font-size: 1.8em;
        text-align: center;
        text-shadow: 2px 2px 10px black;
      }
      .punchline p {
        text-shadow: 2px 2px 10px black;
      }
      #mj-popin-wrapper #mj-popin {
        padding: 120px 0 50px 0!important;
        background-position: 0!important;}

      #mj-popin-wrapper.no-popin #mj-popin {
        padding-top:0;
        min-height: auto;
      }
      #cv-upload-mj #cv-upload-header .punchline {
        text-align: center;
        padding:10px 25px;
        margin: 0 auto;
        float: none;
      }
      #cv-upload-mj .punchline h1 {
        text-align:center;
      }
      #cv-upload .float-lt,  #cv-upload .float-rt {float:none; text-align:center; /*padding: 0;*/}
      #cv-upload-timeline {
        .pos-lt{left:initial}
        .pos-rt{right:initial}
        .padd-40-lt {padding-left:10px; border:none}
        .padd-40-rt {padding-right:10px; border:none}
        .timeline-container {padding-top:40px;}
      }
    }
  }

  @media (max-width: $screen-xs-max) {

    .section-deposer-son-cv {
      .no-padd-lt, .no-padd-rt {padding:0}
      .float-lt, .float-rt {float:none; text-align:center;}
    }

  }
