.section-fiches-metiers {
    
  a.link-ninja.active {
    text-decoration:underline;
  }
  
  .mj-column-left .region-sidebar-first .mj-block {
    li a:active, li a:hover {text-decoration:underline;}  
    } 
  
  .last-offers {
    h2 {
      padding: 0 0 10px;
      border-bottom: 1px solid $brand-primary;
    }
  }
  
  .region-content {
    text-align:justify;
    
    img {
      padding-bottom:5px;
    }
  }
   
  .region-content-bottom .btn {
    float:right;
  }
  
  #mj-offer-list {
  
    padding-bottom:20px;
  
    .row {
      padding: 5px 0;
      border-bottom: 2px dotted $gray-lighter;
      margin:0;
    }
    .col-sm-5 > a {
      text-transform:uppercase;
      font-weight:bold;
      color: $text-color;
    }
    img {
      max-height: 30px;
    }
    .row [class*=col] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 30px;
      padding-left:0;
    }
  
  }
    
  a[data-toggle="collapse"] {
    color: $brand-primary;
  }
  
}