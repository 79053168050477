.section-qui-sommes-nous {
  padding-top:0;
  h1#page-title {display: none;}
 .block {margin-bottom:0;}
  #main-wrapper {margin-bottom:0;}
  #footer .subFooter {margin-top:0;}
  #logo {left:4%;}
  #content {margin-top:0;}

  #main {
    padding-top: ($header-top-height + $header-bottom-height);
    @media (max-width: $screen-md) {
      padding-top: $header-top-height;
    }
  }

  #mj-main-container {
    max-width: none;  
    .container-fluid {
      padding-top: 50px;
      padding-bottom: 50px;
      text-align:center;
    }
  }
  
  #mj-popin-wrapper #mj-popin {padding:0}

  .btn {white-space: normal;}
  
  #about-us-mj h2 {color:$text-color; font-size: 2.2em; font-weight: 600; line-height: initial;}
  
  hr.separator{width:130px; border-color:$brand-primary; border-top-width:2px; margin:30px auto;}
  
  #about-us-intro, #about-us-find-us {
    background:#fff
  }
  #about-us-intro {
    p {color:$gray-light; font-weight:600;}
  }
  #about-us-values {
    background:#f0f4f7;
    h3 {color:$brand-primary; text-transform:uppercase;}
    .timeline-container {/*display:inline-block; position: relative;*/ top: -10px;}
    .timeline-container.icon-lt {float: left; left:-20px;}
    .timeline-container.icon-rt {float: right; right:-20px;}
    .timeline-icon i {height: 40px; line-height: 38px; width: 40px; font-size: 25px; border: 2px solid $gray-light; border-radius: 50%; color: $brand-primary;text-align: center; position: relative; z-index: 1; background:#f0f4f7}
    .pos-lt{left:30px}
    .pos-rt{right:30px;}
    .padd-40-lt {padding-left:40px; border-left-color: $gray-light; border-left-style: solid; border-left-width: 2px;}
    .padd-40-rt {padding-right:40px; border-right-color: $gray-light; border-right-style: solid; border-right-width: 2px;}
    .last {border:none;}
  }
  #about-us-figures {
    background:url(#{$mj-images-path}/_/about-us/bottom-clouds.png) no-repeat center bottom;
    background-color:#1585cd;
    h2 {color:#fff;}
    hr.separator {border-color:$brand-primary-alt}
    p {font-size:1.2em; color:#100e13;}
    p.figures {color:#fff; font-weight:300; font-size:2.9em; margin-bottom:0; line-height: 1em;}
    .container-fluid {padding-bottom:90px;}
  }
  #about-us-news {
    background:$text-color;
    h2 {color:#fff;}
    hr.separator {border-color:$brand-primary}
    p {color:$gray-lighter}
  }
  #about-us-join-team {
    background:url(#{$mj-images-path}/_/about-us/bg-team.jpg) no-repeat center center;
    background-size:cover;
    h2 {color:#fff;}
    hr.separator {border-color:#fff}
    p {color:$brand-primary-alt; font-weight:600;}
    .frame {
      border: 2px solid $gray-bright;
      padding: 30px;
        p {color:#fff; font-weight:300}
    }
    .btn-primary {width:100%; text-transform: uppercase; font-weight: 600; font-size: 0.9em; padding: 15px; background-color:$brand-primary-alt; border-color:$brand-primary-alt; white-space: normal;}
    .btn-primary:hover {background-color:$brand-primary}
  }
  #about-us-team-pics {
    background:#5b7182;
    h2 {color:#fff;}
    hr.separator {border-color:$brand-primary-alt}
  }
  
  #about-us-team-pics img {width: 100%}
  
  .punchline {
    float:left;
    text-align:left;
    margin: 4% 5% 0 0;
  }
  
    #about-us-mj .punchline h1 {
      font-size: 2.6em;
      font-weight:300;
      color:$text-color;
      text-transform:uppercase;
      text-align:left;
      padding: 20px 0;
      border-top: 1px solid $text-color;
      border-bottom: 1px solid $text-color;
    }
    .punchline p {
      font-size: 1.4em;
      color:#ffffff;
    }
    .punchline a {
      color:#ffffff;
    }
    
    /* PHOTO GRID */
    .photo-grid {
      margin: 1em auto;
      max-width: 1070px;
      text-align: center;
    }
    
    .photo-grid .city-pict {
      padding: 5px;
    }
    
    .photo-grid .figure {
      height: auto;
      margin: 0;
      overflow: hidden;
      position: relative;
    }
    
    .photo-grid img {
      display: block;
      height: auto;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      transition: all 300ms;
      max-width: 100%;
    }
    
    .photo-grid .city-pict:hover img {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      transform: scale(1.1);
    }
    
    .photo-grid .city-pict:hover .figcaption {
      opacity: 1;
    }
    
    .photo-grid .figcaption p {
      display: table-cell;
      font-size: 1.8em;
      font-weight: 600;
      position: relative;
      bottom:10px;
      width: 100%;
      height: 260px;
      -webkit-transition: all 300ms ease-out;
      -moz-transition: all 300ms ease-out;
      transition: all 300ms ease-out;
      vertical-align: middle;
      text-transform: uppercase;
      background: url(#{$mj-images-path}/_/icons/mapmarker.png) no-repeat center 60px;
      padding-top:15px;
    }
    
    .photo-grid .figcaption span {
      text-transform: lowercase;
      font-weight: 600;
      font-size: 0.6em;
      color:$text-color;
      width: 100%;
      position: absolute;
      left: 0;
      /*bottom: 18px;*/
    }
    
    .photo-grid .figcaption span b {
      font-weight: 800;
    }
    
    .photo-grid .city-pict:hover .figcaption p {
      -moz-transform: translateY(40px);
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    
    .photo-grid .figcaption {
      background: rgba(21,133,205,0.6);
      color: white;
      display: table;
      height: 100%;
      width: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      transition: all 300ms;
      -webkit-transition-delay: 100ms;
      -moz-transition-delay: 100ms;
      transition-delay: 100ms;
      z-index: 100;
    }
  
  
  /* COMMON */
  .float-none {float:none; margin:0 auto;}
  .float-lt {float:left; text-align:right;}
  .float-rt {float:right; text-align:left;}
  .no-padd-lt {padding-left:0}
  .no-padd-rt {padding-right:0}
  .padd-4-px {padding: 0 4px}
   
  
}
/*********************************************************/
/*                Who Page                              */
/*********************************************************/
.section-qui-sommes-nous .title,
.section-qui-sommes-nous #ads-banner {
  display:none!important;
}
.section-qui-sommes-nous .region-content {
  margin: 0 auto;
}

.section-qui-sommes-nous #main-wrapper, .section-qui-sommes-nous #content, .section-qui-sommes-nous .region-content, #about-us-mj #about-us-header, #about-us-mj #logos, #about-us-mj #main-advantages, #about-us-mj #domains, #about-us-mj #media {
  width:100%;
}
#about-us-mj {
  background:#fff;
  /*padding: 50px 0;*/
}
#about-us-mj #main-advantages {
  background:#eeeeee;
}
#about-us-mj #emplois-domaines, #about-us-mj #emplois-entreprises {
  background:#f9f9f9;
}
#about-us-mj .main-advantages-block, #about-us-mj .domains-block, #about-us-mj .media-block, #about-us-mj .entreprises-block, #about-us-mj .regions-block {
  width:70%;
  margin:0 auto;
}
body.section-qui-sommes-nous.mj-role-company{
  padding-top:0;
}
.section-qui-sommes-nous.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px;
}
#about-us-mj #about-us-header .punchline {
  /* width:50%; 
  padding: 190px 100px*/
  padding-top: 190px;
  padding-left: 100px;
}
.section-qui-sommes-nous #mj-popin-wrapper.no-popin #mj-popin {
  background: transparent url(#{$mj-images-path}/_/about-us/bg-header-about-us.jpg) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-color:#335e91;
  min-height: 580px;
}

@media (max-width: 992px){
 
  /************ Landing page Offres emploi *************/
  .section-qui-sommes-nous {
       #mj-popin-wrapper #mj-popin {
        /*background-position: 15% 0!important;
        padding-bottom:0;*/
      }
      #mj-popin-wrapper.no-popin #mj-popin {
        min-height:auto
      }
      
    }
  }

@media (max-width: 767px) {

  .section-qui-sommes-nous {
  
      #about-us-mj #about-us-header .punchline {
      width:auto;
      text-align:center;
      margin: 100px auto;
      }
      .punchline h1, .punchline h2 {
      font-size: 1.8em;
      text-align: center;
      }
      #mj-popin-wrapper #mj-popin {
      padding: 120px 0 50px 0!important;
      background-position: 0!important;}
        
      #mj-popin-wrapper.no-popin #mj-popin {
      /* background: none;
      background-color:#b3c6df;*/
      padding-top:0;
      min-height: auto;
      }
      #about-us-mj #about-us-header .punchline {
      /* margin: 5% 0 0 2%;*/
      text-align: center;
      padding:10px 25px;
      margin: 0 auto;
      float: none;
      }
      #about-us-mj .punchline h1 {
        text-align:center;
      }
      #about-us .float-lt,  #about-us .float-rt {float:none; text-align:center; /*padding: 0;*/}
      #about-us-values {
        .pos-lt{left:initial}
        .pos-rt{right:initial}
        .padd-40-lt {padding-left:10px; border:none}
        .padd-40-rt {padding-right:10px; border:none}
        .timeline-container {padding-top:40px;}
      }
      
  }
}

@media (min-width: $screen-sm-min) {
    
    .section-qui-sommes-nous {
      .photo-grid .city-pict {width: auto;}
       
    }
    
}

@media (max-width: $screen-xs-max) {
    
    .section-qui-sommes-nous {
      .no-padd-lt, .no-padd-rt {padding:0}
      .photo-grid img {width:100%; max-height:260px;}
      .float-lt, .float-rt {float:none; text-align:center;}
    }
    
}
