.cc-page-index-seo,
.section-offres-emplois-cadres {
  padding-top:0;
  h1#page-title {
    display: none;
  }

  .domains-block,
  .regions-block,
  .entreprises-block {
    @extend .container-fluid;
    a {
      color: inherit;
    }
    .row-fluid {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .browsecompanies-index {
    text-transform: uppercase;
  }
  .block,
  #main-wrapper {
    margin-bottom:0;
  }
  #footer .subFooter {
    margin-top:0;
  }
  #logo {
    left:4%;
  }
  #content  {
    margin-top:0;
  }
  .punchline {
    float:right;
    text-align:left;
    margin: 4% 5% 0 0;
  }
  .media-block {
    text-align:center;
    h2 {
      text-align:left;
    }
    a {
      display:block;
    }
    a:hover {
      text-decoration:none;
    }
  }

  #offres-emploi-mj {
    text-decoration: none;
    background:#fff;
    #emplois-entreprises {
      background:#f9f9f9;
    }
    .domains-block,
    .media-block,
    .entreprises-block,
    .regions-block {
      width:70%;
      margin:0 auto;
    }
    .punchline {
      h1,
      .h1 {
        font-size: 3.4em;
        font-weight:600;
        color:#ffffff;
        text-transform:uppercase;
        text-align:left;
      }
      p {
         font-size: 1.4em;
         color:#ffffff;
      }
      a {
        color:#ffffff;
      }
      .big-btn-transparent a,
      .big-btn-transparent-white a {
        padding: 15px 25px;
        font-weight: 600;
        font-size:1.6em;
        border-radius: 30px;
        text-transform:uppercase;
        color: #ffffff;
        margin: 23px 0px;
        display: inline-block;
        border: none;
      }
      .big-btn-transparent a:hover,
      .big-btn-transparent-white a:hover {
         border: none;
         color: #ffffff;
         text-decoration:none;
      }
      .big-btn-transparent a:active,
      .big-btn-transparent-white a:active {
        border: none;
        color: #ffffff;
      }
      .big-btn-transparent-white a {
        color: #2d2c33;
        background-color:#ffffff;
        border: 2px solid #ffffff;
      }
      .big-btn-transparent-white a:hover {
        border: 2px solid #ffffff;
        background:none;
      }
      .big-btn-transparent-white a:active {
        border: 2px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    #mj-search-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,0.2);
      filter: progid:DXImageTransform.Microsoft.gradient;
    }

    #offres-emploi-header .punchline {
      width:50%;
      padding:80px 25px;
    }
    #offres-emploi-header .punchline .big-btn-transparent a {
      padding: 15px 25px;
      font-weight: 600;
      font-size:1.6em;
      border-radius: 30px;
      text-transform:uppercase;
      color: #ffffff;
      margin: 23px 0px;
      display: inline-block;
      border: none;
    }
    #offres-emploi-header .punchline .big-btn-transparent a:hover  {
      border: none;
      color: #ffffff;
      text-decoration:none;
    }
    #offres-emploi-header .punchline .big-btn-transparent a:active {
      border: none;
      color: #ffffff;
      text-decoration: none;
      background-color: #287CB4;
    }
    #mj-search-bar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,0.2);
      filter: progid:DXImageTransform.Microsoft.gradient;
    }
    #offres-emploi-header .punchline p {
      font-size: 1.6em;
      color:#2d2c33;
    }
    h2 {
      padding-bottom: 10px;
      font-weight: normal;
    }
    #emplois-regions,
    #emplois-entreprises,
    .cc-seo-section-container
     {
      padding: 70px 0;
    }
    #media {
      padding: 30px 0;
    }

  }
  #main-wrapper,
  #content,
  .region-content,
  #offres-emploi-mj #offres-emploi-header,
  #offres-emploi-mj #logos,
  #offres-emploi-mj #media {
    width:100%;
  }

  #search-form {
    .search-title {
      display:block;
      font-weight:bold;
      color:#fff;
      text-align:center;
      line-height:34px;
    }
  }
}

body.mj-header-fixed.mj-role-candidate.mj-role-registered.cc-page-index-seo #offres-emploi-mj #mj-search-bar {
  position: relative;
  max-width:100%;
}
body.cc-page-index-seo.mj-role-company{
  padding-top:0;
}
body.mj-header-fixed.mj-role-candidate.mj-role-registered.cc-page-index-seo #mj-search-bar .mj-container {
  max-width:800px;
}
.cc-page-index-seo.mj-role-candidate.mj-role-registered #mj-user-navigation {
  position: absolute;
  top: 25px;
  left: 80px;
}

.cc-page-index-seo #mj-popin-wrapper.no-popin #mj-popin {
  background: #2BADF4 url(#{$mj-images-path}/bg-hero-2021-09.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  #offres-emploi-header {
    @extend .container-fluid;
    max-width: 90%;
    margin: 0 auto;

    .punchline {
      float: left;
    }
  }

  @media (min-width: 1440px) {
    background-size: contain !important;
    background-position: right 0px !important;
  }
}

.section-offres-emplois-cadres {
  #mj-popin-wrapper.no-popin #mj-popin {
    background: transparent url(#{$mj-images-path}/_/header-meteojob-recrutement.jpg) no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-color:#2d2c33;
    min-height: 370px;
    padding: 0 0 1% 0;
  }
  #offres-emploi-mj {
    #offres-emploi-header {
      .punchline {
        width:50%;
        padding:10% 25px;
        p {
          font-size: 1.4em;
          color:#fff;
        }
      }
    }
  }
}


@media (max-width: 992px){
  /************ Landing page Offres emploi *************/
  #offres-emploi-mj .domains-block, #offres-emploi-mj .entreprises-block, #offres-emploi-mj .regions-block {
  width: 90%;
  }

  /************ Landing page Offres emplois Cadres *************/
  .section-offres-emplois-cadres #mj-popin-wrapper #mj-popin {
  background-position: 30% 0 !important;
  }
}

@media (max-width: 767px) {

  .cc-page-index-seo,
  .cc-page-index-seo-cadres {
    #offres-emploi-mj {
      #offres-emploi-header {
        .punchline {
          width:100%;
          text-align:center;
          margin: 15% 0 0 2%;
          h1, h2 {
            font-size: 1.8em;
            text-align:center;
          }
          p {
            font-size: 1.4em;
          }
          .big-btn-transparent,
          .big-btn-transparent-white {
            a {
              padding: 15px 20px;
              font-size:1.5em;
              text-align: center;
            }
          }
        }
      }
    }
  }

  /************ Landing Page Offres emploi *************/
  .cc-page-index-seo {
    #mj-popin-wrapper #mj-popin {
      padding-bottom:0;
    }
    #mj-popin-wrapper.no-popin #mj-popin {
      background: none;
      background-color:#2BADF4;
      padding-top:80px;
    }
    #offres-emploi-mj {
      #offres-emploi-header {
        .punchline {
          margin: 5% 0 0 2%;
          text-align: center;
          padding:10px 25px;
          h1 {
            font-size: 2.6em;
          }
        }
      }
      #emplois-regions,
      #emplois-entreprises,
      .cc-seo-section-container {
        padding:30px 0;
      }
    }
  }

  /************ Landing Page Offres emplois cadres *************/
  .section-offres-emplois-cadres {
    #mj-popin-wrapper.no-popin #mj-popin {
      background: none;
      background-color:#2d2c33;
      padding-top:10%;
    }
    #offres-emploi-mj {
      #offres-emploi-header .punchline {
        width:100%;
        text-align:center;
        margin: 5% 0 0 2%;
      }
      #offres-emploi-header .punchline h1 {
        text-align: center;
      }
      #emplois-regions,
      #emplois-entreprises {
        padding:30px 0;
      }
    }
  }
}

// from portal sass _offer.scss file
$offer-title-font-size: $font-size-h3;
$offer-title-mobile-font-size: $font-size-h4;
$offer-title-line-height: 1.5;
$new-tag-bg: $brand-primary !default;

@mixin offer_mobile() {
  padding: $spacing-base;

  .logo {
    width: 90px;
    float: left;
  }

  .header-data {
    margin-left: 100px;
    margin-right: 0;

    .title {
      white-space: normal;
      font-size: $offer-title-mobile-font-size;
    }
  }
}

.mj-offers-list {
  padding-left: 0;
  margin: 0;
  list-style: none;

  li:not(.offer-active):hover .mj-offer {
    background-color: $gray-bright;
    .title {
      color: $text-primary;
    }
  }

  li:not(.offer-active):hover + li .mj-offer {
    box-shadow: none;
  }

  .offer-active .block-link {
    display: none;
  }

  .h3 {
    font-size: $font-size-base;
    display:inline-block;
    font-weight: 400;
  }

  .preview {
    word-break: break-all;
  }
}

.mj-offer {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }

  background-color: $block-bg;
  // border: $block-border-width solid $block-border-color;
  padding: $spacing-lg;

  // box-shadow: 0 2px 0 0 white,
  // 0 -2px 0 0 white,
  // 4px 0 5px -4px rgba(0, 0, 0, 0.2),
  // -4px 0 5px -4px rgba(0, 0, 0, 0.2);
  margin: 0;
  border-radius: 0;
  transition: background-color .15s ease;
  position: relative;
  overflow: visible;
  border-bottom: 1px solid $gray-lighter;

  .mj-offers-list &:not(:last-child) {
    border: 0 none;
  }

  .mj-title {
    margin-bottom: $spacing-xs;
  }
  .mj-title-small {
    margin: (-$spacing-base) 0 $spacing-base;
  }

  &.logged {
    overflow: visible;
  }

  &.on-top {
    .title {
      color: $text-primary;
    }
  }

  &.viewed {
    header, .preview-wrapper, .details {
      position: relative;
      transition: opacity 150ms ease;
      opacity: 0.6;
    }

    &:hover {
      header, .preview-wrapper, .details {
        opacity: 1;
      }
    }

    .new-tag {
      display: none;
    }
  }

  a {
    position: relative;
    z-index: 1;

    &.block-link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .header-data {
    margin-right: 150px;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: $offer-title-line-height;
      transition: color .15s ease;
      text-transform: uppercase;
      margin-top: (-$spacing-xs);
      margin-bottom: $spacing-xs;
      padding: 0;
      font-size: $offer-title-font-size;
    }

    .info {
      margin-bottom: $spacing-xs;

      li {
        display: inline;
        margin-right: 2px;

        span {
          margin:0 $spacing-xs;
        }
      }

      .dropdown-menu li {
        display: list-item;
      }

      .collapse.in {
        display: inline;
      }
    }
    .tags {

      .published-date {
        font-size: $font-size-small;
        background-color: $gray-bright;
        display: inline-block;
        padding: 2px 4px;
      }

      .label-primary {
        padding: 2px 4px;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        line-height: inherit;
        border-radius: 0;
      }
    }
  }

  .logo {
    $height: 70px;
    float: right;
    width: 140px;
    height: $height;
    text-align: center;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      line-height: $height;
      padding: 0 10px;
      margin: 0;
      font-weight: bold;
      background-color: $gray-lighter;
      color: $gray-base;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    a {
      display: inline-block;
      width: 100%;
      font-weight: 600;

      &:hover, &focus {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  .new-tag {
    $border-size: 55px;
    border-bottom: $border-size solid transparent;
    border-right: $border-size solid $new-tag-bg;
    width: 0;
    height: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.9);
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 8px;
      left: 23px;
    }
  }

  // For mobile
  @media (max-width: $screen-sm) {
    @include offer_mobile();
  }

  .footer-right {
    z-index: 1;
    position: absolute;
    right: $spacing-md;
    bottom: $spacing-md;
    line-height: $input-height-base;
  }

  .preview-wrapper {
    position: relative;
    margin: $spacing-md 0 $spacing-sm;
  }
  .loader {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  .details {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
  }

  .details-content {
    margin-bottom: $spacing-base;
  }

  .details-footer {
    font-size: $font-size-small;

    .fa, .ft {
      margin-left: 3px;
    }

    .fa-caret-up {
      display: none;
    }

    a:focus:not(:hover) {
      text-decoration: none;
    }
  }
}

.pagination-more-results {
  display: block;
  color: $btn-info-light-color !important;
  background: $btn-info-light-bg;
  line-height: $line-height-computed-px - 2px;
  font-weight: 600;
  text-align: center;
  border-radius: $block-border-radius;
  border: $block-border-width solid $block-border-color;
  // margin-bottom: $spacing-lg;
  padding: $spacing-lg;
  box-shadow: $shadow;

  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }

  .fa, .ft {
    margin-left: $spacing-sm;
    font-size: $font-size-large;
    vertical-align: middle;
  }

  &:hover {
    color: $btn-primary-light-hover;
    background-color: darken($btn-info-light-bg, 10%);
    border-color: darken($btn-info-light-bg, 12%);
    text-decoration: none;
  }
}
