.page-emploi-actualites #block-system-main .view-actualites-emplois .view-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.page-emploi-actualites #block-system-main h2 a {
  color: $text-color;
  text-transform: uppercase;
  font-size: prem(21px);
  font-weight: 400;
  text-align: left;
  text-decoration:none;
}
.node-actualites .node-date {
  text-align: left;
  width: 30%;
  float: left;
  display: block;

  .mj-icon, .fa, .ft {
    width: 20px;
  }
}
.node-date-small {
  text-align: left;
  width: 100%;
  margin: 1.2em 0;
  color: #666;
}
.mj-social-share {
  display: block;
  height: 30px;
  line-height: 13px;
  margin-bottom: 2em;
  border-bottom: none;
}
.mj-social-share > div {
  float: right;
  padding-left: 5px;
}
.node-type-guide-emploi-recruteur .title,
.node-type-carriere .title,
.node-type-actualites #block-system-main .title {
  border-bottom: none;
}
.page-emploi-actualites-actualite .node-actualites .field-items .field-item.even img,
.node-type-actualites .node-actualites .field-type-image {
  padding-right: 0px;
}
#actu-img-triangle {
  position: absolute;
  bottom: 0;
  left: 5%;
}
#actu-img-bg {
  width:100%;
  height:350px;
  position:relative;
  margin-bottom: 1.2em;
}
#actu-img-bg-small {
  border:none;
  width: 30%;
  height: 120px;
  margin: 0.8em 1.2em 1.2em 0;
  float: left;
  display: block;
  position:relative;
  line-height:0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.img-actu-hover {
  background: url(#{$mj-images-path}/link.png) no-repeat center center;
  opacity: 0;
  position: absolute;
  width:100%;
  height:120px;
}
.img-actu-hover:hover {
  opacity:1;
  -ms-filter: "prodig:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity = 70);
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.bg-actu-hover {
  background-color:#FFF;
  width:100%;
  height:100%;
  opacity:1;
}
.bg-actu-hover:hover {
  opacity:0.4;
}
.view-taxonomie-links.view-display-id-actu_url_externe .views-row {
  font-size: 1.1em;
  background: #f2f2f2;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.view-taxonomie-links .views-row a {
  color: $text-color;
}
.view-taxonomie-links .views-row .field-content::after {
  content: " ";
}
.view-taxonomie-links .views-row:last-child .field-content::after {
  content: " ";
}
ul.links.inline {
  position: relative;
  overflow: hidden; // to hide overflow of .element-invisible
  display: block;
  margin: 20px 0 0 0;
  text-align: right;
}
.node-type-actualites .content .btn-100 {
  width: 100%;
  margin: 15px 0 0;
}
.view-actualites-emplois .node-actualites,
.view-actualites-archives .node-actualites,
.view-actualites-categories-archives .node-actualites,
.view-actualites-archives-emploi .node-actualites {
  text-align:left;
  margin: 20px 0px 0px 0px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
}
.view-actualites-emplois .views-row-first .node-actualites,
.view-actualites-archives .views-row-first .node-actualites,
.view-actualites-categories-archives .views-row-first .node-actualites,
.view-actualites-archives-emploi .views-row-first .node-actualites {
  border-top: none;
  margin-top:0;
  padding-top: 0;
}
.node-date {
  margin-top: 0.5em;
}
.view-display-id-bloc_premiere_actu .views-field-field-logo {
  width: 100%;
  height: 300px;
  position: relative;
  margin: 0 0 1.2em 0;
  border:none;
  line-height:0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.page-emploi-actualites #block-system-main .view-id-actualites_emplois .view-content .views-field-path {
  padding-top: 10px;
}
.view-empty .views-summary li a {
  font-weight: normal;
}
.view-actualites-categories-archives .more-link,
.view-actualites-archives .more-link {
  text-align: left;
}

.node-actualites {
  .content {
    &, * {
      font-family: $font-family-base !important;
      font-size: $font-size-base !important;
    }
  }
}

@media (max-width: 480px){
  .node-actualites .node-date {
    width: 100%;
    float: none;
  }
}
