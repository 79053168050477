/* Questions */
.section-questions {
  h1#page-title {
    text-align: center;
    margin-bottom: 10px;
  }
  #breadcrumb {
    display:none;
  }
  #main {
    .top-level-container {
      max-width: 860px;
    }
  }
  #contact_accordion {
    .show {
      display: none!important;
      &.in {
        display: block!important;
      }
    }
    h2.no-btn {
      text-transform: none;
      font-size: 1em;
      line-height: 1.5em;
    }
    .btn-link {
      text-align: left;
      font-size: 0.8em;
      padding: 25px;
      line-height: 1.2em;
      white-space: normal;
      &:after {
        font-family: 'FontAwesome';
        content: "\f106";
        float: right;
        font-size: xx-large;
        color: #ffffff;
      }
      &.collapsed:after {
        content: "\f107";
      }
    }
    .sub-card-header {
      h2 {
        margin-bottom: 0;
      }
      .btn-link {
        text-align: left;
        font-size: 0.7em;
        &:after {
          font-family: 'FontAwesome';
          content: "\f106";
          float: right;
          font-size: xx-large;
          color: $brand-primary;
        }
        &.collapsed:after {
          content: "\f107";
        }
      }
    }
    .sub-card-border {
      border: 1px solid $gray-lighter;
      border-top-width: 0;
    }
    .sub-card-body {
      padding: 15px;
      margin: 0 15px 15px 15px;
      background: $gray-bright;
    }
    .card-header {
      background-color: $brand-primary;
      h2 {
        color: #ffffff;
        margin: 20px 0 0 0;
      }
    }
    .card-body {
      .card-header {
        background-color: #ffffff;
        color: $brand-primary;
      }
    }
  }
  #formulaire-questions {
    h2, .submitted {
      display: none;
    }
    .form-control {
      min-height: 45px;
    }
    input[type=checkbox], input[type=radio] {
      margin: 0 5px 0 0;
    }
    .form-select {
      height: 50px;
    }
    .textarea.bootstrap-tagsinput,
    .textarea.form-control,
    textarea {
      min-height: 100px!important;
    }
    .form-group {
      text-align: center;
    }
    .form-item,
    .form-actions {
      text-align: left;
    }
    .form-actions {
      display: inline-block;
      margin: auto;
      text-align: center;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .section-questions {
    #formulaire-questions {
      .form-actions {
        display: block;
      }
    }
  }
}